/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}
address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 700;
}
dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1rem;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}
pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}
pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}
figure {
  margin: 0 0 1rem;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
button {
  border-radius: 0;
}
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
  cursor: pointer;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit;
}
h1,
.h1 {
  font-size: 2.5rem;
}
h2,
.h2 {
  font-size: 2rem;
}
h3,
.h3 {
  font-size: 1.75rem;
}
h4,
.h4 {
  font-size: 1.5rem;
}
h5,
.h5 {
  font-size: 1.25rem;
}
h6,
.h6 {
  font-size: 1rem;
}
.lead {
  font-size: 1.25rem;
  font-weight: 300;
}
.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
small,
.small {
  font-size: 80%;
  font-weight: 400;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}
.list-unstyled {
  padding-left: 0;
  list-style: none;
}
.list-inline {
  padding-left: 0;
  list-style: none;
}
.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}
.initialism {
  font-size: 90%;
  text-transform: uppercase;
}
.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "\2014 \00A0";
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}
.figure {
  display: inline-block;
}
.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}
.figure-caption {
  font-size: 90%;
  color: #6c757d;
}
code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word;
}
a > code {
  color: inherit;
}
kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}
pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}
.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}
.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}
.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}
.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}
.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}
.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}
.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}
.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}
.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}
.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}
.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}
.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
.order-first {
  -ms-flex-order: -1;
  order: -1;
}
.order-last {
  -ms-flex-order: 13;
  order: 13;
}
.order-0 {
  -ms-flex-order: 0;
  order: 0;
}
.order-1 {
  -ms-flex-order: 1;
  order: 1;
}
.order-2 {
  -ms-flex-order: 2;
  order: 2;
}
.order-3 {
  -ms-flex-order: 3;
  order: 3;
}
.order-4 {
  -ms-flex-order: 4;
  order: 4;
}
.order-5 {
  -ms-flex-order: 5;
  order: 5;
}
.order-6 {
  -ms-flex-order: 6;
  order: 6;
}
.order-7 {
  -ms-flex-order: 7;
  order: 7;
}
.order-8 {
  -ms-flex-order: 8;
  order: 8;
}
.order-9 {
  -ms-flex-order: 9;
  order: 9;
}
.order-10 {
  -ms-flex-order: 10;
  order: 10;
}
.order-11 {
  -ms-flex-order: 11;
  order: 11;
}
.order-12 {
  -ms-flex-order: 12;
  order: 12;
}
.offset-1 {
  margin-left: 8.333333%;
}
.offset-2 {
  margin-left: 16.666667%;
}
.offset-3 {
  margin-left: 25%;
}
.offset-4 {
  margin-left: 33.333333%;
}
.offset-5 {
  margin-left: 41.666667%;
}
.offset-6 {
  margin-left: 50%;
}
.offset-7 {
  margin-left: 58.333333%;
}
.offset-8 {
  margin-left: 66.666667%;
}
.offset-9 {
  margin-left: 75%;
}
.offset-10 {
  margin-left: 83.333333%;
}
.offset-11 {
  margin-left: 91.666667%;
}
@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}
.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}
.table .table {
  background-color: #fff;
}
.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #dee2e6;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}
.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff;
}
.table-hover .table-primary:hover {
  background-color: #9fcdff;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #9fcdff;
}
.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db;
}
.table-hover .table-secondary:hover {
  background-color: #c8cbcf;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #c8cbcf;
}
.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}
.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}
.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}
.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}
.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}
.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca;
}
.table-hover .table-dark:hover {
  background-color: #b9bbbe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b9bbbe;
}
.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}
.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.table-dark {
  color: #fff;
  background-color: #212529;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #32383e;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075);
}
@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.table-responsive > .table-bordered {
  border: 0;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}
.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}
.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}
.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}
.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}
.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
select.form-control[size],
select.form-control[multiple] {
  height: auto;
}
textarea.form-control {
  height: auto;
}
.form-group {
  margin-bottom: 1rem;
}
.form-text {
  display: block;
  margin-top: 0.25rem;
}
.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}
.form-check-label {
  margin-bottom: 0;
}
.form-check-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}
.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}
.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745;
}
.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip,
.form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip,
.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip,
.form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  background-color: #71dd8a;
}
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip,
.custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid ~ .custom-file-label::after,
.custom-file-input.is-valid ~ .custom-file-label::after {
  border-color: inherit;
}
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip,
.custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip,
.form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip,
.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip,
.form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  background-color: #efa2a9;
}
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip,
.custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid ~ .custom-file-label::after,
.custom-file-input.is-invalid ~ .custom-file-label::after {
  border-color: inherit;
}
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip,
.custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.form-inline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -ms-flex-align: center;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}
.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover,
.btn:focus {
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-secondary {
  color: #6c757d;
  background-color: transparent;
  background-image: none;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent;
}
.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}
.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}
.btn-link:disabled,
.btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}
.btn-lg,
.btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.fade {
  transition: opacity 0.15s linear;
}
@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}
.collapse:not(.show) {
  display: none;
}
.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}
.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu-right {
  right: 0;
  left: auto;
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}
.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}
.dropdown-menu[x-placement^="top"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}
.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #6c757d;
  background-color: transparent;
}
.dropdown-menu.show {
  display: block;
}
.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}
.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}
.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
  margin-left: -1px;
}
.btn-toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}
.btn-group > .btn:first-child {
  margin-left: 0;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}
.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}
.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.btn-group-vertical {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: center;
  justify-content: center;
}
.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group-prepend,
.input-group-append {
  display: -ms-flexbox;
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}
.input-group-prepend {
  margin-right: -1px;
}
.input-group-append {
  margin-left: -1px;
}
.input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}
.input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  height: calc(2.875rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}
.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem;
}
.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #007bff;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-control-input:active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
}
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}
.custom-control-label {
  position: relative;
  margin-bottom: 0;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #dee2e6;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5);
}
.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  opacity: 0;
}
.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%;
}
.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%;
}
.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0;
}
.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #80bdff;
}
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: 1px solid #ced4da;
  border-radius: 0 0.25rem 0.25rem 0;
}
.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.custom-range:focus {
  outline: none;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #b3d7ff;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}
.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}
.nav-fill .nav-item {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
.navbar {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar > .container,
.navbar > .container-fluid {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}
.navbar-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  -ms-flex-direction: row;
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.navbar-expand .navbar-collapse {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}
.card-title {
  margin-bottom: 0.75rem;
}
.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}
.card-text:last-child {
  margin-bottom: 0;
}
.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}
.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}
.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}
.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px);
}
.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card-deck {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}
.card-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
  }
  .card-group > .card {
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-top,
  .card-group > .card:first-child .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:first-child .card-img-bottom,
  .card-group > .card:first-child .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-top,
  .card-group > .card:last-child .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:last-child .card-img-bottom,
  .card-group > .card:last-child .card-footer {
    border-bottom-left-radius: 0;
  }
  .card-group > .card:only-child {
    border-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-top,
  .card-group > .card:only-child .card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .card-group > .card:only-child .card-img-bottom,
  .card-group > .card:only-child .card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
    border-radius: 0;
  }
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}
.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0;
}
.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0;
}
.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}
.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}
.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}
.btn .badge {
  position: relative;
  top: -1px;
}
.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}
.badge-primary {
  color: #fff;
  background-color: #007bff;
}
.badge-primary[href]:hover,
.badge-primary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0062cc;
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d;
}
.badge-secondary[href]:hover,
.badge-secondary[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #545b62;
}
.badge-success {
  color: #fff;
  background-color: #28a745;
}
.badge-success[href]:hover,
.badge-success[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1e7e34;
}
.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #117a8b;
}
.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
.badge-warning[href]:hover,
.badge-warning[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #d39e00;
}
.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
.badge-danger[href]:hover,
.badge-danger[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #bd2130;
}
.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
.badge-light[href]:hover,
.badge-light[href]:focus {
  color: #212529;
  text-decoration: none;
  background-color: #dae0e5;
}
.badge-dark {
  color: #fff;
  background-color: #343a40;
}
.badge-dark[href]:hover,
.badge-dark[href]:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1d2124;
}
.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}
.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.alert-heading {
  color: inherit;
}
.alert-link {
  font-weight: 700;
}
.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.75rem 1.25rem;
  color: inherit;
}
.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}
.alert-primary hr {
  border-top-color: #9fcdff;
}
.alert-primary .alert-link {
  color: #002752;
}
.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
.alert-secondary hr {
  border-top-color: #c8cbcf;
}
.alert-secondary .alert-link {
  color: #202326;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}
.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}
.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}
.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca;
}
.alert-dark hr {
  border-top-color: #b9bbbe;
}
.alert-dark .alert-link {
  color: #040505;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}
.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}
.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite;
}
.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.media-body {
  -ms-flex: 1;
  flex: 1;
}
.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.list-group-item:hover,
.list-group-item:focus {
  z-index: 1;
  text-decoration: none;
}
.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}
.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
}
.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}
.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}
.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}
.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}
.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}
.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}
.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}
.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}
.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0;
}
.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  transform: translate(0, -25%);
}
@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}
.modal-dialog-centered {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2));
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (0.5rem * 2));
  content: "";
}
.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}
.modal-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}
.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
}
.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
}
.modal-footer > :not(:first-child) {
  margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
  margin-right: 0.25rem;
}
.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2));
  }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2));
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-tooltip-top,
.bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}
.bs-tooltip-right,
.bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}
.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}
.bs-tooltip-left,
.bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}
.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}
.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}
.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}
.bs-popover-top .arrow,
.bs-popover-auto[x-placement^="top"] .arrow {
  bottom: calc((0.5rem + 1px) * -1);
}
.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  border-width: 0.5rem 0.5rem 0;
}
.bs-popover-top .arrow::before,
.bs-popover-auto[x-placement^="top"] .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 1px;
  border-top-color: #fff;
}
.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}
.bs-popover-right .arrow,
.bs-popover-auto[x-placement^="right"] .arrow {
  left: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  border-width: 0.5rem 0.5rem 0.5rem 0;
}
.bs-popover-right .arrow::before,
.bs-popover-auto[x-placement^="right"] .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 1px;
  border-right-color: #fff;
}
.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}
.bs-popover-bottom .arrow,
.bs-popover-auto[x-placement^="bottom"] .arrow {
  top: calc((0.5rem + 1px) * -1);
}
.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  border-width: 0 0.5rem 0.5rem 0.5rem;
}
.bs-popover-bottom .arrow::before,
.bs-popover-auto[x-placement^="bottom"] .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 1px;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}
.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}
.bs-popover-left .arrow,
.bs-popover-auto[x-placement^="left"] .arrow {
  right: calc((0.5rem + 1px) * -1);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  border-width: 0.5rem 0 0.5rem 0.5rem;
}
.bs-popover-left .arrow::before,
.bs-popover-auto[x-placement^="left"] .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 1px;
  border-left-color: #fff;
}
.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}
.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}
.carousel {
  position: relative;
}
.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-item {
  position: relative;
  display: none;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
  transition: -webkit-transform 0.6s ease;
  transition: transform 0.6s ease;
  transition: transform 0.6s ease, -webkit-transform 0.6s ease;
}
@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    transition: none;
  }
}
.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0;
}
.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.carousel-item-next,
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-next,
  .active.carousel-item-right {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}
.carousel-item-prev,
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-item-prev,
  .active.carousel-item-left {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}
.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: 0.6s;
  transition-property: opacity;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0;
}
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}
@supports ((-webkit-transform-style: preserve-3d) or (transform-style: preserve-3d)) {
  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}
.carousel-control-prev {
  left: 0;
}
.carousel-control-next {
  right: 0;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  position: relative;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.5);
}
.carousel-indicators li::before {
  position: absolute;
  top: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators li::after {
  position: absolute;
  bottom: -10px;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 10px;
  content: "";
}
.carousel-indicators .active {
  background-color: #fff;
}
.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}
.align-baseline {
  vertical-align: baseline !important;
}
.align-top {
  vertical-align: top !important;
}
.align-middle {
  vertical-align: middle !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.align-text-bottom {
  vertical-align: text-bottom !important;
}
.align-text-top {
  vertical-align: text-top !important;
}
.bg-primary {
  background-color: #007bff !important;
}
a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important;
}
.bg-secondary {
  background-color: #6c757d !important;
}
a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important;
}
.bg-success {
  background-color: #28a745 !important;
}
a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}
.bg-info {
  background-color: #17a2b8 !important;
}
a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}
.bg-warning {
  background-color: #ffc107 !important;
}
a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}
.bg-danger {
  background-color: #dc3545 !important;
}
a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}
.bg-light {
  background-color: #f8f9fa !important;
}
a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}
.bg-dark {
  background-color: #343a40 !important;
}
a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.border {
  border: 1px solid #dee2e6 !important;
}
.border-top {
  border-top: 1px solid #dee2e6 !important;
}
.border-right {
  border-right: 1px solid #dee2e6 !important;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-left {
  border-left: 1px solid #dee2e6 !important;
}
.border-0 {
  border: 0 !important;
}
.border-top-0 {
  border-top: 0 !important;
}
.border-right-0 {
  border-right: 0 !important;
}
.border-bottom-0 {
  border-bottom: 0 !important;
}
.border-left-0 {
  border-left: 0 !important;
}
.border-primary {
  border-color: #007bff !important;
}
.border-secondary {
  border-color: #6c757d !important;
}
.border-success {
  border-color: #28a745 !important;
}
.border-info {
  border-color: #17a2b8 !important;
}
.border-warning {
  border-color: #ffc107 !important;
}
.border-danger {
  border-color: #dc3545 !important;
}
.border-light {
  border-color: #f8f9fa !important;
}
.border-dark {
  border-color: #343a40 !important;
}
.border-white {
  border-color: #fff !important;
}
.rounded {
  border-radius: 0.25rem !important;
}
.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}
.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.rounded-circle {
  border-radius: 50% !important;
}
.rounded-0 {
  border-radius: 0 !important;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.d-none {
  display: none !important;
}
.d-inline {
  display: inline !important;
}
.d-inline-block {
  display: inline-block !important;
}
.d-block {
  display: block !important;
}
.d-table {
  display: table !important;
}
.d-table-row {
  display: table-row !important;
}
.d-table-cell {
  display: table-cell !important;
}
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.embed-responsive-21by9::before {
  padding-top: 42.857143%;
}
.embed-responsive-16by9::before {
  padding-top: 56.25%;
}
.embed-responsive-4by3::before {
  padding-top: 75%;
}
.embed-responsive-1by1::before {
  padding-top: 100%;
}
.flex-row {
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}
.flex-column {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.flex-row-reverse {
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}
.flex-column-reverse {
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}
.flex-wrap {
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}
.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}
.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}
.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}
.flex-grow-0 {
  -ms-flex-positive: 0 !important;
  flex-grow: 0 !important;
}
.flex-grow-1 {
  -ms-flex-positive: 1 !important;
  flex-grow: 1 !important;
}
.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}
.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
  flex-shrink: 1 !important;
}
.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}
.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}
.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important;
}
.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}
.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}
.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}
.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}
.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important;
}
.align-items-baseline {
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}
.align-items-stretch {
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}
.align-content-start {
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}
.align-content-end {
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}
.align-content-center {
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}
.align-content-between {
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}
.align-content-around {
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}
.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}
.align-self-auto {
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}
.align-self-start {
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}
.align-self-end {
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}
.align-self-center {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.align-self-baseline {
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}
.align-self-stretch {
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}
@media (min-width: 576px) {
  .flex-sm-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-sm-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-sm-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-md-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-md-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-md-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-lg-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-lg-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  .flex-xl-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .align-items-xl-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
.float-none {
  float: none !important;
}
@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}
.position-static {
  position: static !important;
}
.position-relative {
  position: relative !important;
}
.position-absolute {
  position: absolute !important;
}
.position-fixed {
  position: fixed !important;
}
.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}
.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.shadow-none {
  box-shadow: none !important;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}
.text-justify {
  text-align: justify !important;
}
.text-nowrap {
  white-space: nowrap !important;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}
.text-uppercase {
  text-transform: uppercase !important;
}
.text-capitalize {
  text-transform: capitalize !important;
}
.font-weight-light {
  font-weight: 300 !important;
}
.font-weight-normal {
  font-weight: 400 !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.font-italic {
  font-style: italic !important;
}
.text-white {
  color: #fff !important;
}
.text-primary {
  color: #007bff !important;
}
a.text-primary:hover,
a.text-primary:focus {
  color: #0062cc !important;
}
.text-secondary {
  color: #6c757d !important;
}
a.text-secondary:hover,
a.text-secondary:focus {
  color: #545b62 !important;
}
.text-success {
  color: #28a745 !important;
}
a.text-success:hover,
a.text-success:focus {
  color: #1e7e34 !important;
}
.text-info {
  color: #17a2b8 !important;
}
a.text-info:hover,
a.text-info:focus {
  color: #117a8b !important;
}
.text-warning {
  color: #ffc107 !important;
}
a.text-warning:hover,
a.text-warning:focus {
  color: #d39e00 !important;
}
.text-danger {
  color: #dc3545 !important;
}
a.text-danger:hover,
a.text-danger:focus {
  color: #bd2130 !important;
}
.text-light {
  color: #f8f9fa !important;
}
a.text-light:hover,
a.text-light:focus {
  color: #dae0e5 !important;
}
.text-dark {
  color: #343a40 !important;
}
a.text-dark:hover,
a.text-dark:focus {
  color: #1d2124 !important;
}
.text-body {
  color: #212529 !important;
}
.text-muted {
  color: #6c757d !important;
}
.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}
.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.visible {
  visibility: visible !important;
}
.invisible {
  visibility: hidden !important;
}
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/*# sourceMappingURL=bootstrap.css.map */
/*///////////////////////////////////////////////*/
/*                 MASTER COMPILE                */
/*///////////////////////////////////////////////*/
/*///////////////////////////////////////////////*/
/*                      MIXINS                   */
/*///////////////////////////////////////////////*/
/*///////////////////////////////////////////////*/
/*                      BASE                     */
/*///////////////////////////////////////////////*/
body {
  font-family: "Avenir", "Helvetica";
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}
a,
a:not([href]):not([tabindex]) {
  color: #152848;
  text-decoration: none;
}
a:hover,
a:not([href]):not([tabindex]):hover,
a:focus,
a:not([href]):not([tabindex]):focus,
a:active,
a:not([href]):not([tabindex]):active {
  color: #12223d;
  text-decoration: none;
}
h1 {
  font-family: "Avenir", "Helvetica";
}
h2 {
  font-family: "Avenir", "Helvetica";
}
h3 {
  font-family: "Avenir", "Helvetica";
}
h4 {
  font-family: "Avenir", "Helvetica";
}
h5 {
  font-family: "Avenir", "Helvetica";
}
h6 {
  font-family: "Avenir", "Helvetica";
}
p {
  font-family: "Avenir", "Helvetica";
}
/*///////////////////////////////////////////////*/
/*                     ATOMS                     */
/*///////////////////////////////////////////////*/
/*///////////////////////////////////////////////*/
/*                   COMPONENTS                  */
/*///////////////////////////////////////////////*/
.btn {
  font-family: "Avenir", "Helvetica";
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  border-width: 2px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.btn.btn-primary {
  background: #3a82ff !important;
  border-color: #3a82ff !important;
  color: #FFFFFF;
}
.btn.btn-primary.btn-outline {
  color: #3a82ff;
}
.btn.btn-primary:hover,
.btn.btn-primary:focus,
.btn.btn-primary:active,
.btn.btn-primary.active {
  background: #6da2ff !important;
  border-color: #6da2ff !important;
  color: #FFFFFF;
}
.btn.btn-primary-dark {
  background: #2474ff !important;
  border-color: #2474ff !important;
  color: #FFFFFF;
}
.btn.btn-primary-dark.btn-outline {
  color: #2474ff;
}
.btn.btn-primary-dark:hover,
.btn.btn-primary-dark:focus,
.btn.btn-primary-dark:active,
.btn.btn-primary-dark.active {
  background: #5794ff !important;
  border-color: #5794ff !important;
  color: #FFFFFF;
}
.btn.btn-primary-darker {
  background: #0b64ff !important;
  border-color: #0b64ff !important;
  color: #FFFFFF;
}
.btn.btn-primary-darker.btn-outline {
  color: #0b64ff;
}
.btn.btn-primary-darker:hover,
.btn.btn-primary-darker:focus,
.btn.btn-primary-darker:active,
.btn.btn-primary-darker.active {
  background: #347eff !important;
  border-color: #347eff !important;
  color: #FFFFFF;
}
.btn.btn-primary-darkest {
  background: #005dfe !important;
  border-color: #005dfe !important;
  color: #FFFFFF;
}
.btn.btn-primary-darkest.btn-outline {
  color: #005dfe;
}
.btn.btn-primary-darkest:hover,
.btn.btn-primary-darkest:focus,
.btn.btn-primary-darkest:active,
.btn.btn-primary-darkest.active {
  background: #1d70ff !important;
  border-color: #2776ff !important;
  color: #FFFFFF;
}
.btn.btn-secondary {
  background: #172B4D !important;
  border-color: #172B4D !important;
  color: #FFFFFF;
}
.btn.btn-secondary.btn-outline {
  color: #172B4D;
}
.btn.btn-secondary:hover,
.btn.btn-secondary:focus,
.btn.btn-secondary:active,
.btn.btn-secondary.active {
  background: #234174 !important;
  border-color: #234174 !important;
  color: #FFFFFF;
}
.btn.btn-secondary-dark {
  background: #152848 !important;
  border-color: #152848 !important;
  color: #FFFFFF;
}
.btn.btn-secondary-dark.btn-outline {
  color: #152848;
}
.btn.btn-secondary-dark:hover,
.btn.btn-secondary-dark:focus,
.btn.btn-secondary-dark:active,
.btn.btn-secondary-dark.active {
  background: #213e6f !important;
  border-color: #213e6f !important;
  color: #FFFFFF;
}
.btn.btn-secondary-darker {
  background: #142541 !important;
  border-color: #142541 !important;
  color: #FFFFFF;
}
.btn.btn-secondary-darker.btn-outline {
  color: #142541;
}
.btn.btn-secondary-darker:hover,
.btn.btn-secondary-darker:focus,
.btn.btn-secondary-darker:active,
.btn.btn-secondary-darker.active {
  background: #1d3661 !important;
  border-color: #1d3661 !important;
  color: #FFFFFF;
}
.btn.btn-secondary-darkest {
  background: #13233e !important;
  border-color: #13233e !important;
  color: #FFFFFF;
}
.btn.btn-secondary-darkest.btn-outline {
  color: #13233e;
}
.btn.btn-secondary-darkest:hover,
.btn.btn-secondary-darkest:focus,
.btn.btn-secondary-darkest:active,
.btn.btn-secondary-darkest.active {
  background: #1a3056 !important;
  border-color: #1c345e !important;
  color: #FFFFFF;
}
.btn.btn-tertiary {
  background: #6554C0 !important;
  border-color: #6554C0 !important;
  color: #FFFFFF;
}
.btn.btn-tertiary.btn-outline {
  color: #6554C0;
}
.btn.btn-tertiary:hover,
.btn.btn-tertiary:focus,
.btn.btn-tertiary:active,
.btn.btn-tertiary.active {
  background: #8779ce !important;
  border-color: #8779ce !important;
  color: #FFFFFF;
}
.btn.btn-tertiary-dark {
  background: #5846bb !important;
  border-color: #5846bb !important;
  color: #FFFFFF;
}
.btn.btn-tertiary-dark.btn-outline {
  color: #5846bb;
}
.btn.btn-tertiary-dark:hover,
.btn.btn-tertiary-dark:focus,
.btn.btn-tertiary-dark:active,
.btn.btn-tertiary-dark.active {
  background: #7a6bc9 !important;
  border-color: #7a6bc9 !important;
  color: #FFFFFF;
}
.btn.btn-tertiary-darker {
  background: #503fab !important;
  border-color: #503fab !important;
  color: #FFFFFF;
}
.btn.btn-tertiary-darker.btn-outline {
  color: #503fab;
}
.btn.btn-tertiary-darker:hover,
.btn.btn-tertiary-darker:focus,
.btn.btn-tertiary-darker:active,
.btn.btn-tertiary-darker.active {
  background: #6554c0 !important;
  border-color: #6554c0 !important;
  color: #FFFFFF;
}
.btn.btn-tertiary-darkest {
  background: #4c3ca3 !important;
  border-color: #4c3ca3 !important;
  color: #FFFFFF;
}
.btn.btn-tertiary-darkest.btn-outline {
  color: #4c3ca3;
}
.btn.btn-tertiary-darkest:hover,
.btn.btn-tertiary-darkest:focus,
.btn.btn-tertiary-darkest:active,
.btn.btn-tertiary-darkest.active {
  background: #5744ba !important;
  border-color: #5d4bbd !important;
  color: #FFFFFF;
}
.btn.btn-default {
  background: #f0f3f5 !important;
  border-color: #dde3ea !important;
  color: #000000;
}
.btn.btn-default.btn-outline {
  color: #dde3ea;
}
.btn.btn-default:hover,
.btn.btn-default:focus,
.btn.btn-default:active,
.btn.btn-default.active {
  background: #172B4D !important;
  border-color: #172B4D !important;
  color: #FFFFFF;
}
.btn.btn-dark {
  background: #222c36 !important;
  border-color: #222c36 !important;
  color: #FFFFFF;
}
.btn.btn-dark.btn-outline {
  color: #222c36;
}
.btn.btn-dark:hover,
.btn.btn-dark:focus,
.btn.btn-dark:active,
.btn.btn-dark.active {
  background: #495f74 !important;
  border-color: #495f74 !important;
  color: #FFFFFF;
}
.btn.btn-white {
  background: #FFFFFF !important;
  border-color: #dde3ea !important;
  color: #0b1526;
}
.btn.btn-white.btn-outline {
  color: #dde3ea;
}
.btn.btn-white:hover,
.btn.btn-white:focus,
.btn.btn-white:active,
.btn.btn-white.active {
  background: #172B4D !important;
  border-color: #172B4D !important;
  color: #FFFFFF;
}
.btn.btn-subtle {
  background: none !important;
  border-color: none !important;
  color: #152848;
}
.btn.btn-subtle.btn-outline {
  color: none;
}
.btn.btn-subtle:hover,
.btn.btn-subtle:focus,
.btn.btn-subtle:active,
.btn.btn-subtle.active {
  background: none !important;
  border-color: none !important;
  color: #000000;
}
.btn.btn-error {
  background: #FF5630 !important;
  border-color: #FF5630 !important;
  color: #FFFFFF;
}
.btn.btn-error.btn-outline {
  color: #FF5630;
}
.btn.btn-error:hover,
.btn.btn-error:focus,
.btn.btn-error:active,
.btn.btn-error.active {
  background: #ff8063 !important;
  border-color: #ff8063 !important;
  color: #FFFFFF;
}
.btn.btn-outline {
  background: none !important;
}
/*///////////////////////////////////////////////*/
/*                     LAYOUT                    */
/*///////////////////////////////////////////////*/
.flex-panel {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.flex-panel-body {
  box-sizing: border-box;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.flex-panel-body > div {
  box-shadow: inset 0 0 1px 0 blue;
}
@media (min-width: 1400px) {
  .flex-panel-xl-1 {
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
    overflow: auto;
  }
  .flex-panel-xl-2 {
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
    overflow: auto;
  }
  .flex-panel-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    overflow: auto;
  }
  .flex-panel-xl-4 {
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    overflow: auto;
  }
  .flex-panel-xl-5 {
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
    overflow: auto;
  }
  .flex-panel-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    overflow: auto;
  }
  .flex-panel-xl-7 {
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
    overflow: auto;
  }
  .flex-panel-xl-8 {
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    overflow: auto;
  }
  .flex-panel-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
    overflow: auto;
  }
  .flex-panel-xl-10 {
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
    overflow: auto;
  }
  .flex-panel-xl-11 {
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
    overflow: auto;
  }
  .flex-panel-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    overflow: auto;
  }
}
@media (min-width: 1200px) {
  .flex-panel-lg-1 {
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
    overflow: auto;
  }
  .flex-panel-lg-2 {
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
    overflow: auto;
  }
  .flex-panel-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    overflow: auto;
  }
  .flex-panel-lg-4 {
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    overflow: auto;
  }
  .flex-panel-lg-5 {
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
    overflow: auto;
  }
  .flex-panel-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    overflow: auto;
  }
  .flex-panel-lg-7 {
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
    overflow: auto;
  }
  .flex-panel-lg-8 {
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    overflow: auto;
  }
  .flex-panel-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
    overflow: auto;
  }
  .flex-panel-lg-10 {
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
    overflow: auto;
  }
  .flex-panel-lg-11 {
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
    overflow: auto;
  }
  .flex-panel-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    overflow: auto;
  }
}
@media (min-width: 992px) {
  .flex-panel-md-1 {
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
    overflow: auto;
  }
  .flex-panel-md-2 {
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
    overflow: auto;
  }
  .flex-panel-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    overflow: auto;
  }
  .flex-panel-md-4 {
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    overflow: auto;
  }
  .flex-panel-md-5 {
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
    overflow: auto;
  }
  .flex-panel-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    overflow: auto;
  }
  .flex-panel-md-7 {
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
    overflow: auto;
  }
  .flex-panel-md-8 {
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    overflow: auto;
  }
  .flex-panel-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
    overflow: auto;
  }
  .flex-panel-md-10 {
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
    overflow: auto;
  }
  .flex-panel-md-11 {
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
    overflow: auto;
  }
  .flex-panel-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    overflow: auto;
  }
}
@media (min-width: 768px) {
  .flex-panel-sm-1 {
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
    overflow: auto;
  }
  .flex-panel-sm-2 {
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
    overflow: auto;
  }
  .flex-panel-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    overflow: auto;
  }
  .flex-panel-sm-4 {
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    overflow: auto;
  }
  .flex-panel-sm-5 {
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
    overflow: auto;
  }
  .flex-panel-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    overflow: auto;
  }
  .flex-panel-sm-7 {
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
    overflow: auto;
  }
  .flex-panel-sm-8 {
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    overflow: auto;
  }
  .flex-panel-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
    overflow: auto;
  }
  .flex-panel-sm-10 {
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
    overflow: auto;
  }
  .flex-panel-sm-11 {
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
    overflow: auto;
  }
  .flex-panel-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    overflow: auto;
  }
}
@media (min-width: 576px) {
  .flex-panel-xs-1 {
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
    overflow: auto;
  }
  .flex-panel-xs-2 {
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
    overflow: auto;
  }
  .flex-panel-xs-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    overflow: auto;
  }
  .flex-panel-xs-4 {
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
    overflow: auto;
  }
  .flex-panel-xs-5 {
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
    overflow: auto;
  }
  .flex-panel-xs-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    overflow: auto;
  }
  .flex-panel-xs-7 {
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
    overflow: auto;
  }
  .flex-panel-xs-8 {
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
    overflow: auto;
  }
  .flex-panel-xs-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
    overflow: auto;
  }
  .flex-panel-xs-10 {
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
    overflow: auto;
  }
  .flex-panel-xs-11 {
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
    overflow: auto;
  }
  .flex-panel-xs-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    overflow: auto;
  }
}
/*///////////////////////////////////////////////*/
/*                     PLUGIN                    */
/*///////////////////////////////////////////////*/
/*///////////////////////////////////////////////*/
/*                    UTILITIES                  */
/*///////////////////////////////////////////////*/
/**********  DISPLAY UTILITY  **********/
.u-dis-none {
  display: none !important;
}
@media (min-width: 1400px) {
  .u-dis-xl-none {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .u-dis-lg-none {
    display: none !important;
  }
}
@media (min-width: 992px) {
  .u-dis-md-none {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .u-dis-sm-none {
    display: none !important;
  }
}
@media (min-width: 576px) {
  .u-dis-xs-none {
    display: none !important;
  }
}
.u-dis-inline {
  display: inline !important;
}
@media (min-width: 1400px) {
  .u-dis-xl-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .u-dis-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) {
  .u-dis-md-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) {
  .u-dis-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 576px) {
  .u-dis-xs-inline {
    display: inline !important;
  }
}
.u-dis-inline-block {
  display: inline-block !important;
}
@media (min-width: 1400px) {
  .u-dis-xl-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .u-dis-lg-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) {
  .u-dis-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) {
  .u-dis-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 576px) {
  .u-dis-xs-inline-block {
    display: inline-block !important;
  }
}
.u-dis-block {
  display: block !important;
}
@media (min-width: 1400px) {
  .u-dis-xl-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .u-dis-lg-block {
    display: block !important;
  }
}
@media (min-width: 992px) {
  .u-dis-md-block {
    display: block !important;
  }
}
@media (min-width: 768px) {
  .u-dis-sm-block {
    display: block !important;
  }
}
@media (min-width: 576px) {
  .u-dis-xs-block {
    display: block !important;
  }
}
.u-dis-flex {
  display: flex !important;
}
@media (min-width: 1400px) {
  .u-dis-xl-flex {
    display: flex !important;
  }
}
@media (min-width: 1200px) {
  .u-dis-lg-flex {
    display: flex !important;
  }
}
@media (min-width: 992px) {
  .u-dis-md-flex {
    display: flex !important;
  }
}
@media (min-width: 768px) {
  .u-dis-sm-flex {
    display: flex !important;
  }
}
@media (min-width: 576px) {
  .u-dis-xs-flex {
    display: flex !important;
  }
}
.u-dis-inline-flex {
  display: inline-flex !important;
}
@media (min-width: 1400px) {
  .u-dis-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .u-dis-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .u-dis-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .u-dis-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 576px) {
  .u-dis-xs-inline-flex {
    display: inline-flex !important;
  }
}
/**********  POSITION UTILITY  **********/
.u-pos-relative {
  position: relative !important;
}
@media (min-width: 1400px) {
  .u-pos-xl-relative {
    position: relative !important;
  }
}
@media (min-width: 1200px) {
  .u-pos-lg-relative {
    position: relative !important;
  }
}
@media (min-width: 992px) {
  .u-pos-md-relative {
    position: relative !important;
  }
}
@media (min-width: 768px) {
  .u-pos-sm-relative {
    position: relative !important;
  }
}
@media (min-width: 576px) {
  .u-pos-xs-relative {
    position: relative !important;
  }
}
.u-pos-absolute {
  position: absolute !important;
}
@media (min-width: 1400px) {
  .u-pos-xl-absolute {
    position: absolute !important;
  }
}
@media (min-width: 1200px) {
  .u-pos-lg-absolute {
    position: absolute !important;
  }
}
@media (min-width: 992px) {
  .u-pos-md-absolute {
    position: absolute !important;
  }
}
@media (min-width: 768px) {
  .u-pos-sm-absolute {
    position: absolute !important;
  }
}
@media (min-width: 576px) {
  .u-pos-xs-absolute {
    position: absolute !important;
  }
}
.u-pos-fixed {
  position: fixed !important;
}
@media (min-width: 1400px) {
  .u-pos-xl-fixed {
    position: fixed !important;
  }
}
@media (min-width: 1200px) {
  .u-pos-lg-fixed {
    position: fixed !important;
  }
}
@media (min-width: 992px) {
  .u-pos-md-fixed {
    position: fixed !important;
  }
}
@media (min-width: 768px) {
  .u-pos-sm-fixed {
    position: fixed !important;
  }
}
@media (min-width: 576px) {
  .u-pos-xs-fixed {
    position: fixed !important;
  }
}
.u-pos-static {
  position: static !important;
}
@media (min-width: 1400px) {
  .u-pos-xl-static {
    position: static !important;
  }
}
@media (min-width: 1200px) {
  .u-pos-lg-static {
    position: static !important;
  }
}
@media (min-width: 992px) {
  .u-pos-md-static {
    position: static !important;
  }
}
@media (min-width: 768px) {
  .u-pos-sm-static {
    position: static !important;
  }
}
@media (min-width: 576px) {
  .u-pos-xs-static {
    position: static !important;
  }
}
.u-pos-align-top {
  vertical-align: top !important;
}
.u-pos-align-middle {
  vertical-align: middle !important;
}
.u-pos-align-bottom {
  vertical-align: bottom !important;
}
/**********  COLOR UTILITY  **********/
.u-color-primary {
  color: #3a82ff !important;
}
.u-color-hover-primary {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-primary:hover {
  color: #3a82ff !important;
}
.u-color-primary-dark {
  color: #2474ff !important;
}
.u-color-hover-primary-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-primary-dark:hover {
  color: #2474ff !important;
}
.u-color-primary-darker {
  color: #0b64ff !important;
}
.u-color-hover-primary-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-primary-darker:hover {
  color: #0b64ff !important;
}
.u-color-primary-darkest {
  color: #005dfe !important;
}
.u-color-hover-primary-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-primary-darkest:hover {
  color: #005dfe !important;
}
.u-color-secondary {
  color: #172B4D !important;
}
.u-color-hover-secondary {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-secondary:hover {
  color: #172B4D !important;
}
.u-color-secondary-dark {
  color: #152848 !important;
}
.u-color-hover-secondary-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-secondary-dark:hover {
  color: #152848 !important;
}
.u-color-secondary-darker {
  color: #142541 !important;
}
.u-color-hover-secondary-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-secondary-darker:hover {
  color: #142541 !important;
}
.u-color-secondary-darkest {
  color: #13233e !important;
}
.u-color-hover-secondary-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-secondary-darkest:hover {
  color: #13233e !important;
}
.u-color-tertiary {
  color: #6554C0 !important;
}
.u-color-hover-tertiary {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-tertiary:hover {
  color: #6554C0 !important;
}
.u-color-tertiary-dark {
  color: #5846bb !important;
}
.u-color-hover-tertiary-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-tertiary-dark:hover {
  color: #5846bb !important;
}
.u-color-tertiary-darker {
  color: #503fab !important;
}
.u-color-hover-tertiary-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-tertiary-darker:hover {
  color: #503fab !important;
}
.u-color-tertiary-darkest {
  color: #4c3ca3 !important;
}
.u-color-hover-tertiary-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-tertiary-darkest:hover {
  color: #4c3ca3 !important;
}
.u-color-quat {
  color: #00B8D9 !important;
}
.u-color-hover-quat {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-quat:hover {
  color: #00B8D9 !important;
}
.u-color-quat-dark {
  color: #00abca !important;
}
.u-color-hover-quat-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-quat-dark:hover {
  color: #00abca !important;
}
.u-color-quat-darker {
  color: #009cb8 !important;
}
.u-color-hover-quat-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-quat-darker:hover {
  color: #009cb8 !important;
}
.u-color-quat-darkest {
  color: #0095b0 !important;
}
.u-color-hover-quat-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-quat-darkest:hover {
  color: #0095b0 !important;
}
.u-color-quin {
  color: #36B37E !important;
}
.u-color-hover-quin {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-quin:hover {
  color: #36B37E !important;
}
.u-color-quin-dark {
  color: #32a675 !important;
}
.u-color-hover-quin-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-quin-dark:hover {
  color: #32a675 !important;
}
.u-color-quin-darker {
  color: #2e986b !important;
}
.u-color-hover-quin-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-quin-darker:hover {
  color: #2e986b !important;
}
.u-color-quin-darkest {
  color: #2c9166 !important;
}
.u-color-hover-quin-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-quin-darkest:hover {
  color: #2c9166 !important;
}
.u-color-sen {
  color: #36B37E !important;
}
.u-color-hover-sen {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-sen:hover {
  color: #36B37E !important;
}
.u-color-sen-dark {
  color: #ff451b !important;
}
.u-color-hover-sen-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-sen-dark:hover {
  color: #ff451b !important;
}
.u-color-sen-darker {
  color: #ff3103 !important;
}
.u-color-hover-sen-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-sen-darker:hover {
  color: #ff3103 !important;
}
.u-color-sen-darkest {
  color: #f52d00 !important;
}
.u-color-hover-sen-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-sen-darkest:hover {
  color: #f52d00 !important;
}
.u-color-white {
  color: #FFFFFF !important;
}
.u-color-hover-white {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-white:hover {
  color: #FFFFFF !important;
}
.u-color-black {
  color: #000000 !important;
}
.u-color-hover-black {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-black:hover {
  color: #000000 !important;
}
.u-color-gray-lightest {
  color: #f0f3f5 !important;
}
.u-color-hover-gray-lightest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-gray-lightest:hover {
  color: #f0f3f5 !important;
}
.u-color-gray-lighter {
  color: #dde3ea !important;
}
.u-color-hover-gray-lighter {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-gray-lighter:hover {
  color: #dde3ea !important;
}
.u-color-gray-light {
  color: #98acbe !important;
}
.u-color-hover-gray-light {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-gray-light:hover {
  color: #98acbe !important;
}
.u-color-gray {
  color: #536c84 !important;
}
.u-color-hover-gray {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-gray:hover {
  color: #536c84 !important;
}
.u-color-gray-dark {
  color: #222c36 !important;
}
.u-color-hover-gray-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-gray-dark:hover {
  color: #222c36 !important;
}
.u-color-gray-darker {
  color: #141a20 !important;
}
.u-color-hover-gray-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-gray-darker:hover {
  color: #141a20 !important;
}
.u-color-gray-darkest {
  color: #000000 !important;
}
.u-color-hover-gray-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-gray-darkest:hover {
  color: #000000 !important;
}
.u-color-error {
  color: #FF5630 !important;
}
.u-color-hover-error {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-error:hover {
  color: #FF5630 !important;
}
.u-color-warning {
  color: #FDB813 !important;
}
.u-color-hover-warning {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-warning:hover {
  color: #FDB813 !important;
}
.u-color-confirm {
  color: #36B37E !important;
}
.u-color-hover-confirm {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-confirm:hover {
  color: #36B37E !important;
}
.u-color-success {
  color: #20A6FC !important;
}
.u-color-hover-success {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-success:hover {
  color: #20A6FC !important;
}
.u-color-border {
  color: #dde3ea !important;
}
.u-color-hover-border {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-border:hover {
  color: #dde3ea !important;
}
.u-color-link {
  color: #152848 !important;
}
.u-color-hover-link {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-color-hover-link:hover {
  color: #152848 !important;
}
/**********  BACKGROUND UTILITY  **********/
.u-bg-primary {
  background: #3a82ff !important;
}
.u-bg-hover-primary {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-primary:hover {
  background: #3a82ff !important;
}
.u-bg-primary-dark {
  background: #2474ff !important;
}
.u-bg-hover-primary-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-primary-dark:hover {
  background: #2474ff !important;
}
.u-bg-primary-darker {
  background: #0b64ff !important;
}
.u-bg-hover-primary-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-primary-darker:hover {
  background: #0b64ff !important;
}
.u-bg-primary-darkest {
  background: #005dfe !important;
}
.u-bg-hover-primary-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-primary-darkest:hover {
  background: #005dfe !important;
}
.u-bg-secondary {
  background: #172B4D !important;
}
.u-bg-hover-secondary {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-secondary:hover {
  background: #172B4D !important;
}
.u-bg-secondary-dark {
  background: #152848 !important;
}
.u-bg-hover-secondary-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-secondary-dark:hover {
  background: #152848 !important;
}
.u-bg-secondary-darker {
  background: #142541 !important;
}
.u-bg-hover-secondary-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-secondary-darker:hover {
  background: #142541 !important;
}
.u-bg-secondary-darkest {
  background: #13233e !important;
}
.u-bg-hover-secondary-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-secondary-darkest:hover {
  background: #13233e !important;
}
.u-bg-tertiary {
  background: #6554C0 !important;
}
.u-bg-hover-tertiary {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-tertiary:hover {
  background: #6554C0 !important;
}
.u-bg-tertiary-dark {
  background: #5846bb !important;
}
.u-bg-hover-tertiary-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-tertiary-dark:hover {
  background: #5846bb !important;
}
.u-bg-tertiary-darker {
  background: #503fab !important;
}
.u-bg-hover-tertiary-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-tertiary-darker:hover {
  background: #503fab !important;
}
.u-bg-tertiary-darkest {
  background: #4c3ca3 !important;
}
.u-bg-hover-tertiary-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-tertiary-darkest:hover {
  background: #4c3ca3 !important;
}
.u-bg-quat {
  background: #00B8D9 !important;
}
.u-bg-hover-quat {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-quat:hover {
  background: #00B8D9 !important;
}
.u-bg-quat-dark {
  background: #00abca !important;
}
.u-bg-hover-quat-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-quat-dark:hover {
  background: #00abca !important;
}
.u-bg-quat-darker {
  background: #009cb8 !important;
}
.u-bg-hover-quat-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-quat-darker:hover {
  background: #009cb8 !important;
}
.u-bg-quat-darkest {
  background: #0095b0 !important;
}
.u-bg-hover-quat-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-quat-darkest:hover {
  background: #0095b0 !important;
}
.u-bg-quin {
  background: #36B37E !important;
}
.u-bg-hover-quin {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-quin:hover {
  background: #36B37E !important;
}
.u-bg-quin-dark {
  background: #32a675 !important;
}
.u-bg-hover-quin-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-quin-dark:hover {
  background: #32a675 !important;
}
.u-bg-quin-darker {
  background: #2e986b !important;
}
.u-bg-hover-quin-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-quin-darker:hover {
  background: #2e986b !important;
}
.u-bg-quin-darkest {
  background: #2c9166 !important;
}
.u-bg-hover-quin-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-quin-darkest:hover {
  background: #2c9166 !important;
}
.u-bg-sen {
  background: #36B37E !important;
}
.u-bg-hover-sen {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-sen:hover {
  background: #36B37E !important;
}
.u-bg-sen-dark {
  background: #ff451b !important;
}
.u-bg-hover-sen-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-sen-dark:hover {
  background: #ff451b !important;
}
.u-bg-sen-darker {
  background: #ff3103 !important;
}
.u-bg-hover-sen-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-sen-darker:hover {
  background: #ff3103 !important;
}
.u-bg-sen-darkest {
  background: #f52d00 !important;
}
.u-bg-hover-sen-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-sen-darkest:hover {
  background: #f52d00 !important;
}
.u-bg-white {
  background: #FFFFFF !important;
}
.u-bg-hover-white {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-white:hover {
  background: #FFFFFF !important;
}
.u-bg-black {
  background: #000000 !important;
}
.u-bg-hover-black {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-black:hover {
  background: #000000 !important;
}
.u-bg-gray-lightest {
  background: #f0f3f5 !important;
}
.u-bg-hover-gray-lightest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-gray-lightest:hover {
  background: #f0f3f5 !important;
}
.u-bg-gray-lighter {
  background: #dde3ea !important;
}
.u-bg-hover-gray-lighter {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-gray-lighter:hover {
  background: #dde3ea !important;
}
.u-bg-gray-light {
  background: #98acbe !important;
}
.u-bg-hover-gray-light {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-gray-light:hover {
  background: #98acbe !important;
}
.u-bg-gray {
  background: #536c84 !important;
}
.u-bg-hover-gray {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-gray:hover {
  background: #536c84 !important;
}
.u-bg-gray-dark {
  background: #222c36 !important;
}
.u-bg-hover-gray-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-gray-dark:hover {
  background: #222c36 !important;
}
.u-bg-gray-darker {
  background: #141a20 !important;
}
.u-bg-hover-gray-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-gray-darker:hover {
  background: #141a20 !important;
}
.u-bg-gray-darkest {
  background: #000000 !important;
}
.u-bg-hover-gray-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-gray-darkest:hover {
  background: #000000 !important;
}
.u-bg-error {
  background: #FF5630 !important;
}
.u-bg-hover-error {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-error:hover {
  background: #FF5630 !important;
}
.u-bg-warning {
  background: #FDB813 !important;
}
.u-bg-hover-warning {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-warning:hover {
  background: #FDB813 !important;
}
.u-bg-confirm {
  background: #36B37E !important;
}
.u-bg-hover-confirm {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-confirm:hover {
  background: #36B37E !important;
}
.u-bg-success {
  background: #20A6FC !important;
}
.u-bg-hover-success {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-success:hover {
  background: #20A6FC !important;
}
.u-bg-border {
  background: #dde3ea !important;
}
.u-bg-hover-border {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-border:hover {
  background: #dde3ea !important;
}
.u-bg-link {
  background: #152848 !important;
}
.u-bg-hover-link {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-bg-hover-link:hover {
  background: #152848 !important;
}
/**********  TEXT UTILITY  **********/
.u-text-left {
  text-align: left !important;
}
@media (min-width: 1400px) {
  .u-text-xl-left {
    text-align: left !important;
  }
}
@media (min-width: 1200px) {
  .u-text-lg-left {
    text-align: left !important;
  }
}
@media (min-width: 992px) {
  .u-text-md-left {
    text-align: left !important;
  }
}
@media (min-width: 768px) {
  .u-text-sm-left {
    text-align: left !important;
  }
}
@media (min-width: 576px) {
  .u-text-xs-left {
    text-align: left !important;
  }
}
.u-text-center {
  text-align: center !important;
}
@media (min-width: 1400px) {
  .u-text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .u-text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .u-text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .u-text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 576px) {
  .u-text-xs-center {
    text-align: center !important;
  }
}
.u-text-right {
  text-align: right !important;
}
@media (min-width: 1400px) {
  .u-text-xl-right {
    text-align: right !important;
  }
}
@media (min-width: 1200px) {
  .u-text-lg-right {
    text-align: right !important;
  }
}
@media (min-width: 992px) {
  .u-text-md-right {
    text-align: right !important;
  }
}
@media (min-width: 768px) {
  .u-text-sm-right {
    text-align: right !important;
  }
}
@media (min-width: 576px) {
  .u-text-xs-right {
    text-align: right !important;
  }
}
.u-text-justify {
  text-align: justify !important;
}
@media (min-width: 1400px) {
  .u-text-xl-justify {
    text-align: justify !important;
  }
}
@media (min-width: 1200px) {
  .u-text-lg-justify {
    text-align: justify !important;
  }
}
@media (min-width: 992px) {
  .u-text-md-justify {
    text-align: justify !important;
  }
}
@media (min-width: 768px) {
  .u-text-sm-justify {
    text-align: justify !important;
  }
}
@media (min-width: 576px) {
  .u-text-xs-justify {
    text-align: justify !important;
  }
}
.u-text-fine {
  font-weight: 100;
}
.u-text-light {
  font-weight: 300;
}
.u-text-normal {
  font-weight: 400;
}
.u-text-medium {
  font-weight: 500;
}
.u-text-bold {
  font-weight: 700;
}
.u-text-underline {
  text-decoration: underline !important;
}
.u-text-uppercase {
  text-transform: uppercase !important;
}
.u-text-lowercase {
  text-transform: lowercase !important;
}
.u-text-capitalize {
  text-transform: capitalize !important;
}
.u-text-italic {
  font-style: italic !important;
}
/**********  GRADIENT UTILITY  **********/
.u-grad-primary-primary,
.u-grad-a-primary-primary {
  background: -webkit-linear-gradient(315deg, #3a82ff, #3a82ff) !important;
  background: -moz-linear-gradient(315deg, #3a82ff, #3a82ff) !important;
  background: -ms-linear-gradient(315deg, #3a82ff, #3a82ff) !important;
  background: -o-linear-gradient(315deg, #3a82ff, #3a82ff) !important;
}
.u-grad-primary-secondary,
.u-grad-a-primary-secondary {
  background: -webkit-linear-gradient(315deg, #3a82ff, #172B4D) !important;
  background: -moz-linear-gradient(315deg, #3a82ff, #172B4D) !important;
  background: -ms-linear-gradient(315deg, #3a82ff, #172B4D) !important;
  background: -o-linear-gradient(315deg, #3a82ff, #172B4D) !important;
}
.u-grad-primary-tertiary,
.u-grad-a-primary-tertiary {
  background: -webkit-linear-gradient(315deg, #3a82ff, #6554C0) !important;
  background: -moz-linear-gradient(315deg, #3a82ff, #6554C0) !important;
  background: -ms-linear-gradient(315deg, #3a82ff, #6554C0) !important;
  background: -o-linear-gradient(315deg, #3a82ff, #6554C0) !important;
}
.u-grad-primary-quat,
.u-grad-a-primary-quat {
  background: -webkit-linear-gradient(315deg, #3a82ff, #00B8D9) !important;
  background: -moz-linear-gradient(315deg, #3a82ff, #00B8D9) !important;
  background: -ms-linear-gradient(315deg, #3a82ff, #00B8D9) !important;
  background: -o-linear-gradient(315deg, #3a82ff, #00B8D9) !important;
}
.u-grad-primary-quin,
.u-grad-a-primary-quin {
  background: -webkit-linear-gradient(315deg, #3a82ff, #36B37E) !important;
  background: -moz-linear-gradient(315deg, #3a82ff, #36B37E) !important;
  background: -ms-linear-gradient(315deg, #3a82ff, #36B37E) !important;
  background: -o-linear-gradient(315deg, #3a82ff, #36B37E) !important;
}
.u-grad-primary-sen,
.u-grad-a-primary-sen {
  background: -webkit-linear-gradient(315deg, #3a82ff, #FF5630) !important;
  background: -moz-linear-gradient(315deg, #3a82ff, #FF5630) !important;
  background: -ms-linear-gradient(315deg, #3a82ff, #FF5630) !important;
  background: -o-linear-gradient(315deg, #3a82ff, #FF5630) !important;
}
.u-grad-v-primary-primary {
  background: #3a82ff !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#3a82ff), to(#3a82ff)) !important;
  background: -webkit-linear-gradient(top, #3a82ff, #3a82ff) !important;
  background: -moz-linear-gradient(top, #3a82ff, #3a82ff) !important;
  background: -ms-linear-gradient(top, #3a82ff, #3a82ff) !important;
  background: -o-linear-gradient(top, #3a82ff, #3a82ff) !important;
}
.u-grad-v-primary-secondary {
  background: #3a82ff !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#3a82ff), to(#172B4D)) !important;
  background: -webkit-linear-gradient(top, #3a82ff, #172B4D) !important;
  background: -moz-linear-gradient(top, #3a82ff, #172B4D) !important;
  background: -ms-linear-gradient(top, #3a82ff, #172B4D) !important;
  background: -o-linear-gradient(top, #3a82ff, #172B4D) !important;
}
.u-grad-v-primary-tertiary {
  background: #3a82ff !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#3a82ff), to(#6554C0)) !important;
  background: -webkit-linear-gradient(top, #3a82ff, #6554C0) !important;
  background: -moz-linear-gradient(top, #3a82ff, #6554C0) !important;
  background: -ms-linear-gradient(top, #3a82ff, #6554C0) !important;
  background: -o-linear-gradient(top, #3a82ff, #6554C0) !important;
}
.u-grad-v-primary-quat {
  background: #3a82ff !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#3a82ff), to(#00B8D9)) !important;
  background: -webkit-linear-gradient(top, #3a82ff, #00B8D9) !important;
  background: -moz-linear-gradient(top, #3a82ff, #00B8D9) !important;
  background: -ms-linear-gradient(top, #3a82ff, #00B8D9) !important;
  background: -o-linear-gradient(top, #3a82ff, #00B8D9) !important;
}
.u-grad-v-primary-quin {
  background: #3a82ff !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#3a82ff), to(#36B37E)) !important;
  background: -webkit-linear-gradient(top, #3a82ff, #36B37E) !important;
  background: -moz-linear-gradient(top, #3a82ff, #36B37E) !important;
  background: -ms-linear-gradient(top, #3a82ff, #36B37E) !important;
  background: -o-linear-gradient(top, #3a82ff, #36B37E) !important;
}
.u-grad-v-primary-sen {
  background: #3a82ff !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#3a82ff), to(#FF5630)) !important;
  background: -webkit-linear-gradient(top, #3a82ff, #FF5630) !important;
  background: -moz-linear-gradient(top, #3a82ff, #FF5630) !important;
  background: -ms-linear-gradient(top, #3a82ff, #FF5630) !important;
  background: -o-linear-gradient(top, #3a82ff, #FF5630) !important;
}
.u-grad-h-primary-primary {
  background: #3a82ff !important;
  background: -webkit-gradient(linear, left top, right top, from(#3a82ff), to(#3a82ff)) !important;
  background: -webkit-linear-gradient(left, #3a82ff, #3a82ff) !important;
  background: -moz-linear-gradient(left, #3a82ff, #3a82ff) !important;
  background: -ms-linear-gradient(left, #3a82ff, #3a82ff) !important;
  background: -o-linear-gradient(left, #3a82ff, #3a82ff) !important;
}
.u-grad-h-primary-secondary {
  background: #3a82ff !important;
  background: -webkit-gradient(linear, left top, right top, from(#3a82ff), to(#172B4D)) !important;
  background: -webkit-linear-gradient(left, #3a82ff, #172B4D) !important;
  background: -moz-linear-gradient(left, #3a82ff, #172B4D) !important;
  background: -ms-linear-gradient(left, #3a82ff, #172B4D) !important;
  background: -o-linear-gradient(left, #3a82ff, #172B4D) !important;
}
.u-grad-h-primary-tertiary {
  background: #3a82ff !important;
  background: -webkit-gradient(linear, left top, right top, from(#3a82ff), to(#6554C0)) !important;
  background: -webkit-linear-gradient(left, #3a82ff, #6554C0) !important;
  background: -moz-linear-gradient(left, #3a82ff, #6554C0) !important;
  background: -ms-linear-gradient(left, #3a82ff, #6554C0) !important;
  background: -o-linear-gradient(left, #3a82ff, #6554C0) !important;
}
.u-grad-h-primary-quat {
  background: #3a82ff !important;
  background: -webkit-gradient(linear, left top, right top, from(#3a82ff), to(#00B8D9)) !important;
  background: -webkit-linear-gradient(left, #3a82ff, #00B8D9) !important;
  background: -moz-linear-gradient(left, #3a82ff, #00B8D9) !important;
  background: -ms-linear-gradient(left, #3a82ff, #00B8D9) !important;
  background: -o-linear-gradient(left, #3a82ff, #00B8D9) !important;
}
.u-grad-h-primary-quin {
  background: #3a82ff !important;
  background: -webkit-gradient(linear, left top, right top, from(#3a82ff), to(#36B37E)) !important;
  background: -webkit-linear-gradient(left, #3a82ff, #36B37E) !important;
  background: -moz-linear-gradient(left, #3a82ff, #36B37E) !important;
  background: -ms-linear-gradient(left, #3a82ff, #36B37E) !important;
  background: -o-linear-gradient(left, #3a82ff, #36B37E) !important;
}
.u-grad-h-primary-sen {
  background: #3a82ff !important;
  background: -webkit-gradient(linear, left top, right top, from(#3a82ff), to(#FF5630)) !important;
  background: -webkit-linear-gradient(left, #3a82ff, #FF5630) !important;
  background: -moz-linear-gradient(left, #3a82ff, #FF5630) !important;
  background: -ms-linear-gradient(left, #3a82ff, #FF5630) !important;
  background: -o-linear-gradient(left, #3a82ff, #FF5630) !important;
}
.u-grad-secondary-primary,
.u-grad-a-secondary-primary {
  background: -webkit-linear-gradient(315deg, #172B4D, #3a82ff) !important;
  background: -moz-linear-gradient(315deg, #172B4D, #3a82ff) !important;
  background: -ms-linear-gradient(315deg, #172B4D, #3a82ff) !important;
  background: -o-linear-gradient(315deg, #172B4D, #3a82ff) !important;
}
.u-grad-secondary-secondary,
.u-grad-a-secondary-secondary {
  background: -webkit-linear-gradient(315deg, #172B4D, #172B4D) !important;
  background: -moz-linear-gradient(315deg, #172B4D, #172B4D) !important;
  background: -ms-linear-gradient(315deg, #172B4D, #172B4D) !important;
  background: -o-linear-gradient(315deg, #172B4D, #172B4D) !important;
}
.u-grad-secondary-tertiary,
.u-grad-a-secondary-tertiary {
  background: -webkit-linear-gradient(315deg, #172B4D, #6554C0) !important;
  background: -moz-linear-gradient(315deg, #172B4D, #6554C0) !important;
  background: -ms-linear-gradient(315deg, #172B4D, #6554C0) !important;
  background: -o-linear-gradient(315deg, #172B4D, #6554C0) !important;
}
.u-grad-secondary-quat,
.u-grad-a-secondary-quat {
  background: -webkit-linear-gradient(315deg, #172B4D, #00B8D9) !important;
  background: -moz-linear-gradient(315deg, #172B4D, #00B8D9) !important;
  background: -ms-linear-gradient(315deg, #172B4D, #00B8D9) !important;
  background: -o-linear-gradient(315deg, #172B4D, #00B8D9) !important;
}
.u-grad-secondary-quin,
.u-grad-a-secondary-quin {
  background: -webkit-linear-gradient(315deg, #172B4D, #36B37E) !important;
  background: -moz-linear-gradient(315deg, #172B4D, #36B37E) !important;
  background: -ms-linear-gradient(315deg, #172B4D, #36B37E) !important;
  background: -o-linear-gradient(315deg, #172B4D, #36B37E) !important;
}
.u-grad-secondary-sen,
.u-grad-a-secondary-sen {
  background: -webkit-linear-gradient(315deg, #172B4D, #FF5630) !important;
  background: -moz-linear-gradient(315deg, #172B4D, #FF5630) !important;
  background: -ms-linear-gradient(315deg, #172B4D, #FF5630) !important;
  background: -o-linear-gradient(315deg, #172B4D, #FF5630) !important;
}
.u-grad-v-secondary-primary {
  background: #172B4D !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#172B4D), to(#3a82ff)) !important;
  background: -webkit-linear-gradient(top, #172B4D, #3a82ff) !important;
  background: -moz-linear-gradient(top, #172B4D, #3a82ff) !important;
  background: -ms-linear-gradient(top, #172B4D, #3a82ff) !important;
  background: -o-linear-gradient(top, #172B4D, #3a82ff) !important;
}
.u-grad-v-secondary-secondary {
  background: #172B4D !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#172B4D), to(#172B4D)) !important;
  background: -webkit-linear-gradient(top, #172B4D, #172B4D) !important;
  background: -moz-linear-gradient(top, #172B4D, #172B4D) !important;
  background: -ms-linear-gradient(top, #172B4D, #172B4D) !important;
  background: -o-linear-gradient(top, #172B4D, #172B4D) !important;
}
.u-grad-v-secondary-tertiary {
  background: #172B4D !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#172B4D), to(#6554C0)) !important;
  background: -webkit-linear-gradient(top, #172B4D, #6554C0) !important;
  background: -moz-linear-gradient(top, #172B4D, #6554C0) !important;
  background: -ms-linear-gradient(top, #172B4D, #6554C0) !important;
  background: -o-linear-gradient(top, #172B4D, #6554C0) !important;
}
.u-grad-v-secondary-quat {
  background: #172B4D !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#172B4D), to(#00B8D9)) !important;
  background: -webkit-linear-gradient(top, #172B4D, #00B8D9) !important;
  background: -moz-linear-gradient(top, #172B4D, #00B8D9) !important;
  background: -ms-linear-gradient(top, #172B4D, #00B8D9) !important;
  background: -o-linear-gradient(top, #172B4D, #00B8D9) !important;
}
.u-grad-v-secondary-quin {
  background: #172B4D !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#172B4D), to(#36B37E)) !important;
  background: -webkit-linear-gradient(top, #172B4D, #36B37E) !important;
  background: -moz-linear-gradient(top, #172B4D, #36B37E) !important;
  background: -ms-linear-gradient(top, #172B4D, #36B37E) !important;
  background: -o-linear-gradient(top, #172B4D, #36B37E) !important;
}
.u-grad-v-secondary-sen {
  background: #172B4D !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#172B4D), to(#FF5630)) !important;
  background: -webkit-linear-gradient(top, #172B4D, #FF5630) !important;
  background: -moz-linear-gradient(top, #172B4D, #FF5630) !important;
  background: -ms-linear-gradient(top, #172B4D, #FF5630) !important;
  background: -o-linear-gradient(top, #172B4D, #FF5630) !important;
}
.u-grad-h-secondary-primary {
  background: #172B4D !important;
  background: -webkit-gradient(linear, left top, right top, from(#172B4D), to(#3a82ff)) !important;
  background: -webkit-linear-gradient(left, #172B4D, #3a82ff) !important;
  background: -moz-linear-gradient(left, #172B4D, #3a82ff) !important;
  background: -ms-linear-gradient(left, #172B4D, #3a82ff) !important;
  background: -o-linear-gradient(left, #172B4D, #3a82ff) !important;
}
.u-grad-h-secondary-secondary {
  background: #172B4D !important;
  background: -webkit-gradient(linear, left top, right top, from(#172B4D), to(#172B4D)) !important;
  background: -webkit-linear-gradient(left, #172B4D, #172B4D) !important;
  background: -moz-linear-gradient(left, #172B4D, #172B4D) !important;
  background: -ms-linear-gradient(left, #172B4D, #172B4D) !important;
  background: -o-linear-gradient(left, #172B4D, #172B4D) !important;
}
.u-grad-h-secondary-tertiary {
  background: #172B4D !important;
  background: -webkit-gradient(linear, left top, right top, from(#172B4D), to(#6554C0)) !important;
  background: -webkit-linear-gradient(left, #172B4D, #6554C0) !important;
  background: -moz-linear-gradient(left, #172B4D, #6554C0) !important;
  background: -ms-linear-gradient(left, #172B4D, #6554C0) !important;
  background: -o-linear-gradient(left, #172B4D, #6554C0) !important;
}
.u-grad-h-secondary-quat {
  background: #172B4D !important;
  background: -webkit-gradient(linear, left top, right top, from(#172B4D), to(#00B8D9)) !important;
  background: -webkit-linear-gradient(left, #172B4D, #00B8D9) !important;
  background: -moz-linear-gradient(left, #172B4D, #00B8D9) !important;
  background: -ms-linear-gradient(left, #172B4D, #00B8D9) !important;
  background: -o-linear-gradient(left, #172B4D, #00B8D9) !important;
}
.u-grad-h-secondary-quin {
  background: #172B4D !important;
  background: -webkit-gradient(linear, left top, right top, from(#172B4D), to(#36B37E)) !important;
  background: -webkit-linear-gradient(left, #172B4D, #36B37E) !important;
  background: -moz-linear-gradient(left, #172B4D, #36B37E) !important;
  background: -ms-linear-gradient(left, #172B4D, #36B37E) !important;
  background: -o-linear-gradient(left, #172B4D, #36B37E) !important;
}
.u-grad-h-secondary-sen {
  background: #172B4D !important;
  background: -webkit-gradient(linear, left top, right top, from(#172B4D), to(#FF5630)) !important;
  background: -webkit-linear-gradient(left, #172B4D, #FF5630) !important;
  background: -moz-linear-gradient(left, #172B4D, #FF5630) !important;
  background: -ms-linear-gradient(left, #172B4D, #FF5630) !important;
  background: -o-linear-gradient(left, #172B4D, #FF5630) !important;
}
.u-grad-tertiary-primary,
.u-grad-a-tertiary-primary {
  background: -webkit-linear-gradient(315deg, #6554C0, #3a82ff) !important;
  background: -moz-linear-gradient(315deg, #6554C0, #3a82ff) !important;
  background: -ms-linear-gradient(315deg, #6554C0, #3a82ff) !important;
  background: -o-linear-gradient(315deg, #6554C0, #3a82ff) !important;
}
.u-grad-tertiary-secondary,
.u-grad-a-tertiary-secondary {
  background: -webkit-linear-gradient(315deg, #6554C0, #172B4D) !important;
  background: -moz-linear-gradient(315deg, #6554C0, #172B4D) !important;
  background: -ms-linear-gradient(315deg, #6554C0, #172B4D) !important;
  background: -o-linear-gradient(315deg, #6554C0, #172B4D) !important;
}
.u-grad-tertiary-tertiary,
.u-grad-a-tertiary-tertiary {
  background: -webkit-linear-gradient(315deg, #6554C0, #6554C0) !important;
  background: -moz-linear-gradient(315deg, #6554C0, #6554C0) !important;
  background: -ms-linear-gradient(315deg, #6554C0, #6554C0) !important;
  background: -o-linear-gradient(315deg, #6554C0, #6554C0) !important;
}
.u-grad-tertiary-quat,
.u-grad-a-tertiary-quat {
  background: -webkit-linear-gradient(315deg, #6554C0, #00B8D9) !important;
  background: -moz-linear-gradient(315deg, #6554C0, #00B8D9) !important;
  background: -ms-linear-gradient(315deg, #6554C0, #00B8D9) !important;
  background: -o-linear-gradient(315deg, #6554C0, #00B8D9) !important;
}
.u-grad-tertiary-quin,
.u-grad-a-tertiary-quin {
  background: -webkit-linear-gradient(315deg, #6554C0, #36B37E) !important;
  background: -moz-linear-gradient(315deg, #6554C0, #36B37E) !important;
  background: -ms-linear-gradient(315deg, #6554C0, #36B37E) !important;
  background: -o-linear-gradient(315deg, #6554C0, #36B37E) !important;
}
.u-grad-tertiary-sen,
.u-grad-a-tertiary-sen {
  background: -webkit-linear-gradient(315deg, #6554C0, #FF5630) !important;
  background: -moz-linear-gradient(315deg, #6554C0, #FF5630) !important;
  background: -ms-linear-gradient(315deg, #6554C0, #FF5630) !important;
  background: -o-linear-gradient(315deg, #6554C0, #FF5630) !important;
}
.u-grad-v-tertiary-primary {
  background: #6554C0 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#6554C0), to(#3a82ff)) !important;
  background: -webkit-linear-gradient(top, #6554C0, #3a82ff) !important;
  background: -moz-linear-gradient(top, #6554C0, #3a82ff) !important;
  background: -ms-linear-gradient(top, #6554C0, #3a82ff) !important;
  background: -o-linear-gradient(top, #6554C0, #3a82ff) !important;
}
.u-grad-v-tertiary-secondary {
  background: #6554C0 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#6554C0), to(#172B4D)) !important;
  background: -webkit-linear-gradient(top, #6554C0, #172B4D) !important;
  background: -moz-linear-gradient(top, #6554C0, #172B4D) !important;
  background: -ms-linear-gradient(top, #6554C0, #172B4D) !important;
  background: -o-linear-gradient(top, #6554C0, #172B4D) !important;
}
.u-grad-v-tertiary-tertiary {
  background: #6554C0 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#6554C0), to(#6554C0)) !important;
  background: -webkit-linear-gradient(top, #6554C0, #6554C0) !important;
  background: -moz-linear-gradient(top, #6554C0, #6554C0) !important;
  background: -ms-linear-gradient(top, #6554C0, #6554C0) !important;
  background: -o-linear-gradient(top, #6554C0, #6554C0) !important;
}
.u-grad-v-tertiary-quat {
  background: #6554C0 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#6554C0), to(#00B8D9)) !important;
  background: -webkit-linear-gradient(top, #6554C0, #00B8D9) !important;
  background: -moz-linear-gradient(top, #6554C0, #00B8D9) !important;
  background: -ms-linear-gradient(top, #6554C0, #00B8D9) !important;
  background: -o-linear-gradient(top, #6554C0, #00B8D9) !important;
}
.u-grad-v-tertiary-quin {
  background: #6554C0 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#6554C0), to(#36B37E)) !important;
  background: -webkit-linear-gradient(top, #6554C0, #36B37E) !important;
  background: -moz-linear-gradient(top, #6554C0, #36B37E) !important;
  background: -ms-linear-gradient(top, #6554C0, #36B37E) !important;
  background: -o-linear-gradient(top, #6554C0, #36B37E) !important;
}
.u-grad-v-tertiary-sen {
  background: #6554C0 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#6554C0), to(#FF5630)) !important;
  background: -webkit-linear-gradient(top, #6554C0, #FF5630) !important;
  background: -moz-linear-gradient(top, #6554C0, #FF5630) !important;
  background: -ms-linear-gradient(top, #6554C0, #FF5630) !important;
  background: -o-linear-gradient(top, #6554C0, #FF5630) !important;
}
.u-grad-h-tertiary-primary {
  background: #6554C0 !important;
  background: -webkit-gradient(linear, left top, right top, from(#6554C0), to(#3a82ff)) !important;
  background: -webkit-linear-gradient(left, #6554C0, #3a82ff) !important;
  background: -moz-linear-gradient(left, #6554C0, #3a82ff) !important;
  background: -ms-linear-gradient(left, #6554C0, #3a82ff) !important;
  background: -o-linear-gradient(left, #6554C0, #3a82ff) !important;
}
.u-grad-h-tertiary-secondary {
  background: #6554C0 !important;
  background: -webkit-gradient(linear, left top, right top, from(#6554C0), to(#172B4D)) !important;
  background: -webkit-linear-gradient(left, #6554C0, #172B4D) !important;
  background: -moz-linear-gradient(left, #6554C0, #172B4D) !important;
  background: -ms-linear-gradient(left, #6554C0, #172B4D) !important;
  background: -o-linear-gradient(left, #6554C0, #172B4D) !important;
}
.u-grad-h-tertiary-tertiary {
  background: #6554C0 !important;
  background: -webkit-gradient(linear, left top, right top, from(#6554C0), to(#6554C0)) !important;
  background: -webkit-linear-gradient(left, #6554C0, #6554C0) !important;
  background: -moz-linear-gradient(left, #6554C0, #6554C0) !important;
  background: -ms-linear-gradient(left, #6554C0, #6554C0) !important;
  background: -o-linear-gradient(left, #6554C0, #6554C0) !important;
}
.u-grad-h-tertiary-quat {
  background: #6554C0 !important;
  background: -webkit-gradient(linear, left top, right top, from(#6554C0), to(#00B8D9)) !important;
  background: -webkit-linear-gradient(left, #6554C0, #00B8D9) !important;
  background: -moz-linear-gradient(left, #6554C0, #00B8D9) !important;
  background: -ms-linear-gradient(left, #6554C0, #00B8D9) !important;
  background: -o-linear-gradient(left, #6554C0, #00B8D9) !important;
}
.u-grad-h-tertiary-quin {
  background: #6554C0 !important;
  background: -webkit-gradient(linear, left top, right top, from(#6554C0), to(#36B37E)) !important;
  background: -webkit-linear-gradient(left, #6554C0, #36B37E) !important;
  background: -moz-linear-gradient(left, #6554C0, #36B37E) !important;
  background: -ms-linear-gradient(left, #6554C0, #36B37E) !important;
  background: -o-linear-gradient(left, #6554C0, #36B37E) !important;
}
.u-grad-h-tertiary-sen {
  background: #6554C0 !important;
  background: -webkit-gradient(linear, left top, right top, from(#6554C0), to(#FF5630)) !important;
  background: -webkit-linear-gradient(left, #6554C0, #FF5630) !important;
  background: -moz-linear-gradient(left, #6554C0, #FF5630) !important;
  background: -ms-linear-gradient(left, #6554C0, #FF5630) !important;
  background: -o-linear-gradient(left, #6554C0, #FF5630) !important;
}
.u-grad-quat-primary,
.u-grad-a-quat-primary {
  background: -webkit-linear-gradient(315deg, #00B8D9, #3a82ff) !important;
  background: -moz-linear-gradient(315deg, #00B8D9, #3a82ff) !important;
  background: -ms-linear-gradient(315deg, #00B8D9, #3a82ff) !important;
  background: -o-linear-gradient(315deg, #00B8D9, #3a82ff) !important;
}
.u-grad-quat-secondary,
.u-grad-a-quat-secondary {
  background: -webkit-linear-gradient(315deg, #00B8D9, #172B4D) !important;
  background: -moz-linear-gradient(315deg, #00B8D9, #172B4D) !important;
  background: -ms-linear-gradient(315deg, #00B8D9, #172B4D) !important;
  background: -o-linear-gradient(315deg, #00B8D9, #172B4D) !important;
}
.u-grad-quat-tertiary,
.u-grad-a-quat-tertiary {
  background: -webkit-linear-gradient(315deg, #00B8D9, #6554C0) !important;
  background: -moz-linear-gradient(315deg, #00B8D9, #6554C0) !important;
  background: -ms-linear-gradient(315deg, #00B8D9, #6554C0) !important;
  background: -o-linear-gradient(315deg, #00B8D9, #6554C0) !important;
}
.u-grad-quat-quat,
.u-grad-a-quat-quat {
  background: -webkit-linear-gradient(315deg, #00B8D9, #00B8D9) !important;
  background: -moz-linear-gradient(315deg, #00B8D9, #00B8D9) !important;
  background: -ms-linear-gradient(315deg, #00B8D9, #00B8D9) !important;
  background: -o-linear-gradient(315deg, #00B8D9, #00B8D9) !important;
}
.u-grad-quat-quin,
.u-grad-a-quat-quin {
  background: -webkit-linear-gradient(315deg, #00B8D9, #36B37E) !important;
  background: -moz-linear-gradient(315deg, #00B8D9, #36B37E) !important;
  background: -ms-linear-gradient(315deg, #00B8D9, #36B37E) !important;
  background: -o-linear-gradient(315deg, #00B8D9, #36B37E) !important;
}
.u-grad-quat-sen,
.u-grad-a-quat-sen {
  background: -webkit-linear-gradient(315deg, #00B8D9, #FF5630) !important;
  background: -moz-linear-gradient(315deg, #00B8D9, #FF5630) !important;
  background: -ms-linear-gradient(315deg, #00B8D9, #FF5630) !important;
  background: -o-linear-gradient(315deg, #00B8D9, #FF5630) !important;
}
.u-grad-v-quat-primary {
  background: #00B8D9 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#00B8D9), to(#3a82ff)) !important;
  background: -webkit-linear-gradient(top, #00B8D9, #3a82ff) !important;
  background: -moz-linear-gradient(top, #00B8D9, #3a82ff) !important;
  background: -ms-linear-gradient(top, #00B8D9, #3a82ff) !important;
  background: -o-linear-gradient(top, #00B8D9, #3a82ff) !important;
}
.u-grad-v-quat-secondary {
  background: #00B8D9 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#00B8D9), to(#172B4D)) !important;
  background: -webkit-linear-gradient(top, #00B8D9, #172B4D) !important;
  background: -moz-linear-gradient(top, #00B8D9, #172B4D) !important;
  background: -ms-linear-gradient(top, #00B8D9, #172B4D) !important;
  background: -o-linear-gradient(top, #00B8D9, #172B4D) !important;
}
.u-grad-v-quat-tertiary {
  background: #00B8D9 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#00B8D9), to(#6554C0)) !important;
  background: -webkit-linear-gradient(top, #00B8D9, #6554C0) !important;
  background: -moz-linear-gradient(top, #00B8D9, #6554C0) !important;
  background: -ms-linear-gradient(top, #00B8D9, #6554C0) !important;
  background: -o-linear-gradient(top, #00B8D9, #6554C0) !important;
}
.u-grad-v-quat-quat {
  background: #00B8D9 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#00B8D9), to(#00B8D9)) !important;
  background: -webkit-linear-gradient(top, #00B8D9, #00B8D9) !important;
  background: -moz-linear-gradient(top, #00B8D9, #00B8D9) !important;
  background: -ms-linear-gradient(top, #00B8D9, #00B8D9) !important;
  background: -o-linear-gradient(top, #00B8D9, #00B8D9) !important;
}
.u-grad-v-quat-quin {
  background: #00B8D9 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#00B8D9), to(#36B37E)) !important;
  background: -webkit-linear-gradient(top, #00B8D9, #36B37E) !important;
  background: -moz-linear-gradient(top, #00B8D9, #36B37E) !important;
  background: -ms-linear-gradient(top, #00B8D9, #36B37E) !important;
  background: -o-linear-gradient(top, #00B8D9, #36B37E) !important;
}
.u-grad-v-quat-sen {
  background: #00B8D9 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#00B8D9), to(#FF5630)) !important;
  background: -webkit-linear-gradient(top, #00B8D9, #FF5630) !important;
  background: -moz-linear-gradient(top, #00B8D9, #FF5630) !important;
  background: -ms-linear-gradient(top, #00B8D9, #FF5630) !important;
  background: -o-linear-gradient(top, #00B8D9, #FF5630) !important;
}
.u-grad-h-quat-primary {
  background: #00B8D9 !important;
  background: -webkit-gradient(linear, left top, right top, from(#00B8D9), to(#3a82ff)) !important;
  background: -webkit-linear-gradient(left, #00B8D9, #3a82ff) !important;
  background: -moz-linear-gradient(left, #00B8D9, #3a82ff) !important;
  background: -ms-linear-gradient(left, #00B8D9, #3a82ff) !important;
  background: -o-linear-gradient(left, #00B8D9, #3a82ff) !important;
}
.u-grad-h-quat-secondary {
  background: #00B8D9 !important;
  background: -webkit-gradient(linear, left top, right top, from(#00B8D9), to(#172B4D)) !important;
  background: -webkit-linear-gradient(left, #00B8D9, #172B4D) !important;
  background: -moz-linear-gradient(left, #00B8D9, #172B4D) !important;
  background: -ms-linear-gradient(left, #00B8D9, #172B4D) !important;
  background: -o-linear-gradient(left, #00B8D9, #172B4D) !important;
}
.u-grad-h-quat-tertiary {
  background: #00B8D9 !important;
  background: -webkit-gradient(linear, left top, right top, from(#00B8D9), to(#6554C0)) !important;
  background: -webkit-linear-gradient(left, #00B8D9, #6554C0) !important;
  background: -moz-linear-gradient(left, #00B8D9, #6554C0) !important;
  background: -ms-linear-gradient(left, #00B8D9, #6554C0) !important;
  background: -o-linear-gradient(left, #00B8D9, #6554C0) !important;
}
.u-grad-h-quat-quat {
  background: #00B8D9 !important;
  background: -webkit-gradient(linear, left top, right top, from(#00B8D9), to(#00B8D9)) !important;
  background: -webkit-linear-gradient(left, #00B8D9, #00B8D9) !important;
  background: -moz-linear-gradient(left, #00B8D9, #00B8D9) !important;
  background: -ms-linear-gradient(left, #00B8D9, #00B8D9) !important;
  background: -o-linear-gradient(left, #00B8D9, #00B8D9) !important;
}
.u-grad-h-quat-quin {
  background: #00B8D9 !important;
  background: -webkit-gradient(linear, left top, right top, from(#00B8D9), to(#36B37E)) !important;
  background: -webkit-linear-gradient(left, #00B8D9, #36B37E) !important;
  background: -moz-linear-gradient(left, #00B8D9, #36B37E) !important;
  background: -ms-linear-gradient(left, #00B8D9, #36B37E) !important;
  background: -o-linear-gradient(left, #00B8D9, #36B37E) !important;
}
.u-grad-h-quat-sen {
  background: #00B8D9 !important;
  background: -webkit-gradient(linear, left top, right top, from(#00B8D9), to(#FF5630)) !important;
  background: -webkit-linear-gradient(left, #00B8D9, #FF5630) !important;
  background: -moz-linear-gradient(left, #00B8D9, #FF5630) !important;
  background: -ms-linear-gradient(left, #00B8D9, #FF5630) !important;
  background: -o-linear-gradient(left, #00B8D9, #FF5630) !important;
}
.u-grad-quin-primary,
.u-grad-a-quin-primary {
  background: -webkit-linear-gradient(315deg, #36B37E, #3a82ff) !important;
  background: -moz-linear-gradient(315deg, #36B37E, #3a82ff) !important;
  background: -ms-linear-gradient(315deg, #36B37E, #3a82ff) !important;
  background: -o-linear-gradient(315deg, #36B37E, #3a82ff) !important;
}
.u-grad-quin-secondary,
.u-grad-a-quin-secondary {
  background: -webkit-linear-gradient(315deg, #36B37E, #172B4D) !important;
  background: -moz-linear-gradient(315deg, #36B37E, #172B4D) !important;
  background: -ms-linear-gradient(315deg, #36B37E, #172B4D) !important;
  background: -o-linear-gradient(315deg, #36B37E, #172B4D) !important;
}
.u-grad-quin-tertiary,
.u-grad-a-quin-tertiary {
  background: -webkit-linear-gradient(315deg, #36B37E, #6554C0) !important;
  background: -moz-linear-gradient(315deg, #36B37E, #6554C0) !important;
  background: -ms-linear-gradient(315deg, #36B37E, #6554C0) !important;
  background: -o-linear-gradient(315deg, #36B37E, #6554C0) !important;
}
.u-grad-quin-quat,
.u-grad-a-quin-quat {
  background: -webkit-linear-gradient(315deg, #36B37E, #00B8D9) !important;
  background: -moz-linear-gradient(315deg, #36B37E, #00B8D9) !important;
  background: -ms-linear-gradient(315deg, #36B37E, #00B8D9) !important;
  background: -o-linear-gradient(315deg, #36B37E, #00B8D9) !important;
}
.u-grad-quin-quin,
.u-grad-a-quin-quin {
  background: -webkit-linear-gradient(315deg, #36B37E, #36B37E) !important;
  background: -moz-linear-gradient(315deg, #36B37E, #36B37E) !important;
  background: -ms-linear-gradient(315deg, #36B37E, #36B37E) !important;
  background: -o-linear-gradient(315deg, #36B37E, #36B37E) !important;
}
.u-grad-quin-sen,
.u-grad-a-quin-sen {
  background: -webkit-linear-gradient(315deg, #36B37E, #FF5630) !important;
  background: -moz-linear-gradient(315deg, #36B37E, #FF5630) !important;
  background: -ms-linear-gradient(315deg, #36B37E, #FF5630) !important;
  background: -o-linear-gradient(315deg, #36B37E, #FF5630) !important;
}
.u-grad-v-quin-primary {
  background: #36B37E !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#36B37E), to(#3a82ff)) !important;
  background: -webkit-linear-gradient(top, #36B37E, #3a82ff) !important;
  background: -moz-linear-gradient(top, #36B37E, #3a82ff) !important;
  background: -ms-linear-gradient(top, #36B37E, #3a82ff) !important;
  background: -o-linear-gradient(top, #36B37E, #3a82ff) !important;
}
.u-grad-v-quin-secondary {
  background: #36B37E !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#36B37E), to(#172B4D)) !important;
  background: -webkit-linear-gradient(top, #36B37E, #172B4D) !important;
  background: -moz-linear-gradient(top, #36B37E, #172B4D) !important;
  background: -ms-linear-gradient(top, #36B37E, #172B4D) !important;
  background: -o-linear-gradient(top, #36B37E, #172B4D) !important;
}
.u-grad-v-quin-tertiary {
  background: #36B37E !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#36B37E), to(#6554C0)) !important;
  background: -webkit-linear-gradient(top, #36B37E, #6554C0) !important;
  background: -moz-linear-gradient(top, #36B37E, #6554C0) !important;
  background: -ms-linear-gradient(top, #36B37E, #6554C0) !important;
  background: -o-linear-gradient(top, #36B37E, #6554C0) !important;
}
.u-grad-v-quin-quat {
  background: #36B37E !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#36B37E), to(#00B8D9)) !important;
  background: -webkit-linear-gradient(top, #36B37E, #00B8D9) !important;
  background: -moz-linear-gradient(top, #36B37E, #00B8D9) !important;
  background: -ms-linear-gradient(top, #36B37E, #00B8D9) !important;
  background: -o-linear-gradient(top, #36B37E, #00B8D9) !important;
}
.u-grad-v-quin-quin {
  background: #36B37E !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#36B37E), to(#36B37E)) !important;
  background: -webkit-linear-gradient(top, #36B37E, #36B37E) !important;
  background: -moz-linear-gradient(top, #36B37E, #36B37E) !important;
  background: -ms-linear-gradient(top, #36B37E, #36B37E) !important;
  background: -o-linear-gradient(top, #36B37E, #36B37E) !important;
}
.u-grad-v-quin-sen {
  background: #36B37E !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#36B37E), to(#FF5630)) !important;
  background: -webkit-linear-gradient(top, #36B37E, #FF5630) !important;
  background: -moz-linear-gradient(top, #36B37E, #FF5630) !important;
  background: -ms-linear-gradient(top, #36B37E, #FF5630) !important;
  background: -o-linear-gradient(top, #36B37E, #FF5630) !important;
}
.u-grad-h-quin-primary {
  background: #36B37E !important;
  background: -webkit-gradient(linear, left top, right top, from(#36B37E), to(#3a82ff)) !important;
  background: -webkit-linear-gradient(left, #36B37E, #3a82ff) !important;
  background: -moz-linear-gradient(left, #36B37E, #3a82ff) !important;
  background: -ms-linear-gradient(left, #36B37E, #3a82ff) !important;
  background: -o-linear-gradient(left, #36B37E, #3a82ff) !important;
}
.u-grad-h-quin-secondary {
  background: #36B37E !important;
  background: -webkit-gradient(linear, left top, right top, from(#36B37E), to(#172B4D)) !important;
  background: -webkit-linear-gradient(left, #36B37E, #172B4D) !important;
  background: -moz-linear-gradient(left, #36B37E, #172B4D) !important;
  background: -ms-linear-gradient(left, #36B37E, #172B4D) !important;
  background: -o-linear-gradient(left, #36B37E, #172B4D) !important;
}
.u-grad-h-quin-tertiary {
  background: #36B37E !important;
  background: -webkit-gradient(linear, left top, right top, from(#36B37E), to(#6554C0)) !important;
  background: -webkit-linear-gradient(left, #36B37E, #6554C0) !important;
  background: -moz-linear-gradient(left, #36B37E, #6554C0) !important;
  background: -ms-linear-gradient(left, #36B37E, #6554C0) !important;
  background: -o-linear-gradient(left, #36B37E, #6554C0) !important;
}
.u-grad-h-quin-quat {
  background: #36B37E !important;
  background: -webkit-gradient(linear, left top, right top, from(#36B37E), to(#00B8D9)) !important;
  background: -webkit-linear-gradient(left, #36B37E, #00B8D9) !important;
  background: -moz-linear-gradient(left, #36B37E, #00B8D9) !important;
  background: -ms-linear-gradient(left, #36B37E, #00B8D9) !important;
  background: -o-linear-gradient(left, #36B37E, #00B8D9) !important;
}
.u-grad-h-quin-quin {
  background: #36B37E !important;
  background: -webkit-gradient(linear, left top, right top, from(#36B37E), to(#36B37E)) !important;
  background: -webkit-linear-gradient(left, #36B37E, #36B37E) !important;
  background: -moz-linear-gradient(left, #36B37E, #36B37E) !important;
  background: -ms-linear-gradient(left, #36B37E, #36B37E) !important;
  background: -o-linear-gradient(left, #36B37E, #36B37E) !important;
}
.u-grad-h-quin-sen {
  background: #36B37E !important;
  background: -webkit-gradient(linear, left top, right top, from(#36B37E), to(#FF5630)) !important;
  background: -webkit-linear-gradient(left, #36B37E, #FF5630) !important;
  background: -moz-linear-gradient(left, #36B37E, #FF5630) !important;
  background: -ms-linear-gradient(left, #36B37E, #FF5630) !important;
  background: -o-linear-gradient(left, #36B37E, #FF5630) !important;
}
.u-grad-sen-primary,
.u-grad-a-sen-primary {
  background: -webkit-linear-gradient(315deg, #FF5630, #3a82ff) !important;
  background: -moz-linear-gradient(315deg, #FF5630, #3a82ff) !important;
  background: -ms-linear-gradient(315deg, #FF5630, #3a82ff) !important;
  background: -o-linear-gradient(315deg, #FF5630, #3a82ff) !important;
}
.u-grad-sen-secondary,
.u-grad-a-sen-secondary {
  background: -webkit-linear-gradient(315deg, #FF5630, #172B4D) !important;
  background: -moz-linear-gradient(315deg, #FF5630, #172B4D) !important;
  background: -ms-linear-gradient(315deg, #FF5630, #172B4D) !important;
  background: -o-linear-gradient(315deg, #FF5630, #172B4D) !important;
}
.u-grad-sen-tertiary,
.u-grad-a-sen-tertiary {
  background: -webkit-linear-gradient(315deg, #FF5630, #6554C0) !important;
  background: -moz-linear-gradient(315deg, #FF5630, #6554C0) !important;
  background: -ms-linear-gradient(315deg, #FF5630, #6554C0) !important;
  background: -o-linear-gradient(315deg, #FF5630, #6554C0) !important;
}
.u-grad-sen-quat,
.u-grad-a-sen-quat {
  background: -webkit-linear-gradient(315deg, #FF5630, #00B8D9) !important;
  background: -moz-linear-gradient(315deg, #FF5630, #00B8D9) !important;
  background: -ms-linear-gradient(315deg, #FF5630, #00B8D9) !important;
  background: -o-linear-gradient(315deg, #FF5630, #00B8D9) !important;
}
.u-grad-sen-quin,
.u-grad-a-sen-quin {
  background: -webkit-linear-gradient(315deg, #FF5630, #36B37E) !important;
  background: -moz-linear-gradient(315deg, #FF5630, #36B37E) !important;
  background: -ms-linear-gradient(315deg, #FF5630, #36B37E) !important;
  background: -o-linear-gradient(315deg, #FF5630, #36B37E) !important;
}
.u-grad-sen-sen,
.u-grad-a-sen-sen {
  background: -webkit-linear-gradient(315deg, #FF5630, #FF5630) !important;
  background: -moz-linear-gradient(315deg, #FF5630, #FF5630) !important;
  background: -ms-linear-gradient(315deg, #FF5630, #FF5630) !important;
  background: -o-linear-gradient(315deg, #FF5630, #FF5630) !important;
}
.u-grad-v-sen-primary {
  background: #FF5630 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#FF5630), to(#3a82ff)) !important;
  background: -webkit-linear-gradient(top, #FF5630, #3a82ff) !important;
  background: -moz-linear-gradient(top, #FF5630, #3a82ff) !important;
  background: -ms-linear-gradient(top, #FF5630, #3a82ff) !important;
  background: -o-linear-gradient(top, #FF5630, #3a82ff) !important;
}
.u-grad-v-sen-secondary {
  background: #FF5630 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#FF5630), to(#172B4D)) !important;
  background: -webkit-linear-gradient(top, #FF5630, #172B4D) !important;
  background: -moz-linear-gradient(top, #FF5630, #172B4D) !important;
  background: -ms-linear-gradient(top, #FF5630, #172B4D) !important;
  background: -o-linear-gradient(top, #FF5630, #172B4D) !important;
}
.u-grad-v-sen-tertiary {
  background: #FF5630 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#FF5630), to(#6554C0)) !important;
  background: -webkit-linear-gradient(top, #FF5630, #6554C0) !important;
  background: -moz-linear-gradient(top, #FF5630, #6554C0) !important;
  background: -ms-linear-gradient(top, #FF5630, #6554C0) !important;
  background: -o-linear-gradient(top, #FF5630, #6554C0) !important;
}
.u-grad-v-sen-quat {
  background: #FF5630 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#FF5630), to(#00B8D9)) !important;
  background: -webkit-linear-gradient(top, #FF5630, #00B8D9) !important;
  background: -moz-linear-gradient(top, #FF5630, #00B8D9) !important;
  background: -ms-linear-gradient(top, #FF5630, #00B8D9) !important;
  background: -o-linear-gradient(top, #FF5630, #00B8D9) !important;
}
.u-grad-v-sen-quin {
  background: #FF5630 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#FF5630), to(#36B37E)) !important;
  background: -webkit-linear-gradient(top, #FF5630, #36B37E) !important;
  background: -moz-linear-gradient(top, #FF5630, #36B37E) !important;
  background: -ms-linear-gradient(top, #FF5630, #36B37E) !important;
  background: -o-linear-gradient(top, #FF5630, #36B37E) !important;
}
.u-grad-v-sen-sen {
  background: #FF5630 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#FF5630), to(#FF5630)) !important;
  background: -webkit-linear-gradient(top, #FF5630, #FF5630) !important;
  background: -moz-linear-gradient(top, #FF5630, #FF5630) !important;
  background: -ms-linear-gradient(top, #FF5630, #FF5630) !important;
  background: -o-linear-gradient(top, #FF5630, #FF5630) !important;
}
.u-grad-h-sen-primary {
  background: #FF5630 !important;
  background: -webkit-gradient(linear, left top, right top, from(#FF5630), to(#3a82ff)) !important;
  background: -webkit-linear-gradient(left, #FF5630, #3a82ff) !important;
  background: -moz-linear-gradient(left, #FF5630, #3a82ff) !important;
  background: -ms-linear-gradient(left, #FF5630, #3a82ff) !important;
  background: -o-linear-gradient(left, #FF5630, #3a82ff) !important;
}
.u-grad-h-sen-secondary {
  background: #FF5630 !important;
  background: -webkit-gradient(linear, left top, right top, from(#FF5630), to(#172B4D)) !important;
  background: -webkit-linear-gradient(left, #FF5630, #172B4D) !important;
  background: -moz-linear-gradient(left, #FF5630, #172B4D) !important;
  background: -ms-linear-gradient(left, #FF5630, #172B4D) !important;
  background: -o-linear-gradient(left, #FF5630, #172B4D) !important;
}
.u-grad-h-sen-tertiary {
  background: #FF5630 !important;
  background: -webkit-gradient(linear, left top, right top, from(#FF5630), to(#6554C0)) !important;
  background: -webkit-linear-gradient(left, #FF5630, #6554C0) !important;
  background: -moz-linear-gradient(left, #FF5630, #6554C0) !important;
  background: -ms-linear-gradient(left, #FF5630, #6554C0) !important;
  background: -o-linear-gradient(left, #FF5630, #6554C0) !important;
}
.u-grad-h-sen-quat {
  background: #FF5630 !important;
  background: -webkit-gradient(linear, left top, right top, from(#FF5630), to(#00B8D9)) !important;
  background: -webkit-linear-gradient(left, #FF5630, #00B8D9) !important;
  background: -moz-linear-gradient(left, #FF5630, #00B8D9) !important;
  background: -ms-linear-gradient(left, #FF5630, #00B8D9) !important;
  background: -o-linear-gradient(left, #FF5630, #00B8D9) !important;
}
.u-grad-h-sen-quin {
  background: #FF5630 !important;
  background: -webkit-gradient(linear, left top, right top, from(#FF5630), to(#36B37E)) !important;
  background: -webkit-linear-gradient(left, #FF5630, #36B37E) !important;
  background: -moz-linear-gradient(left, #FF5630, #36B37E) !important;
  background: -ms-linear-gradient(left, #FF5630, #36B37E) !important;
  background: -o-linear-gradient(left, #FF5630, #36B37E) !important;
}
.u-grad-h-sen-sen {
  background: #FF5630 !important;
  background: -webkit-gradient(linear, left top, right top, from(#FF5630), to(#FF5630)) !important;
  background: -webkit-linear-gradient(left, #FF5630, #FF5630) !important;
  background: -moz-linear-gradient(left, #FF5630, #FF5630) !important;
  background: -ms-linear-gradient(left, #FF5630, #FF5630) !important;
  background: -o-linear-gradient(left, #FF5630, #FF5630) !important;
}
/**********  WIDTH UTILITY  **********/
.u-width-1 {
  width: 20px !important;
  max-width: 20px !important;
  min-width: 20px !important;
}
.u-width-2 {
  width: 40px !important;
  max-width: 40px !important;
  min-width: 40px !important;
}
.u-width-3 {
  width: 60px !important;
  max-width: 60px !important;
  min-width: 60px !important;
}
.u-width-4 {
  width: 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
}
.u-width-5 {
  width: 100px !important;
  max-width: 100px !important;
  min-width: 100px !important;
}
.u-width-6 {
  width: 120px !important;
  max-width: 120px !important;
  min-width: 120px !important;
}
.u-width-7 {
  width: 140px !important;
  max-width: 140px !important;
  min-width: 140px !important;
}
.u-width-8 {
  width: 160px !important;
  max-width: 160px !important;
  min-width: 160px !important;
}
.u-width-9 {
  width: 180px !important;
  max-width: 180px !important;
  min-width: 180px !important;
}
.u-width-10 {
  width: 200px !important;
  max-width: 200px !important;
  min-width: 200px !important;
}
.u-width-p-1 {
  width: 8.33333333% !important;
  max-width: 8.33333333% !important;
  min-width: 8.33333333% !important;
}
.u-width-p-2 {
  width: 16.66666667% !important;
  max-width: 16.66666667% !important;
  min-width: 16.66666667% !important;
}
.u-width-p-3 {
  width: 25% !important;
  max-width: 25% !important;
  min-width: 25% !important;
}
.u-width-p-4 {
  width: 33.33333333% !important;
  max-width: 33.33333333% !important;
  min-width: 33.33333333% !important;
}
.u-width-p-5 {
  width: 41.66666667% !important;
  max-width: 41.66666667% !important;
  min-width: 41.66666667% !important;
}
.u-width-p-6 {
  width: 50% !important;
  max-width: 50% !important;
  min-width: 50% !important;
}
.u-width-p-7 {
  width: 58.33333333% !important;
  max-width: 58.33333333% !important;
  min-width: 58.33333333% !important;
}
.u-width-p-8 {
  width: 66.66666667% !important;
  max-width: 66.66666667% !important;
  min-width: 66.66666667% !important;
}
.u-width-p-9 {
  width: 75% !important;
  max-width: 75% !important;
  min-width: 75% !important;
}
.u-width-p-10 {
  width: 83.33333333% !important;
  max-width: 83.33333333% !important;
  min-width: 83.33333333% !important;
}
.u-width-p-11 {
  width: 91.66666667% !important;
  max-width: 91.66666667% !important;
  min-width: 91.66666667% !important;
}
.u-width-p-12 {
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
}
/**********  HEIGHT UTILITY  **********/
.u-height-1 {
  height: 20px !important;
  max-height: 20px !important;
  min-height: 20px !important;
}
.u-height-2 {
  height: 40px !important;
  max-height: 40px !important;
  min-height: 40px !important;
}
.u-height-3 {
  height: 60px !important;
  max-height: 60px !important;
  min-height: 60px !important;
}
.u-height-4 {
  height: 80px !important;
  max-height: 80px !important;
  min-height: 80px !important;
}
.u-height-5 {
  height: 100px !important;
  max-height: 100px !important;
  min-height: 100px !important;
}
.u-height-6 {
  height: 120px !important;
  max-height: 120px !important;
  min-height: 120px !important;
}
.u-height-7 {
  height: 140px !important;
  max-height: 140px !important;
  min-height: 140px !important;
}
.u-height-8 {
  height: 160px !important;
  max-height: 160px !important;
  min-height: 160px !important;
}
.u-height-9 {
  height: 180px !important;
  max-height: 180px !important;
  min-height: 180px !important;
}
.u-height-10 {
  height: 200px !important;
  max-height: 200px !important;
  min-height: 200px !important;
}
.u-height-p-1 {
  height: 10% !important;
  max-height: 10% !important;
  min-height: 10% !important;
}
.u-height-p-2 {
  height: 20% !important;
  max-height: 20% !important;
  min-height: 20% !important;
}
.u-height-p-3 {
  height: 30% !important;
  max-height: 30% !important;
  min-height: 30% !important;
}
.u-height-p-4 {
  height: 40% !important;
  max-height: 40% !important;
  min-height: 40% !important;
}
.u-height-p-5 {
  height: 50% !important;
  max-height: 50% !important;
  min-height: 50% !important;
}
.u-height-p-6 {
  height: 60% !important;
  max-height: 60% !important;
  min-height: 60% !important;
}
.u-height-p-7 {
  height: 70% !important;
  max-height: 70% !important;
  min-height: 70% !important;
}
.u-height-p-8 {
  height: 80% !important;
  max-height: 80% !important;
  min-height: 80% !important;
}
.u-height-p-9 {
  height: 90% !important;
  max-height: 90% !important;
  min-height: 90% !important;
}
.u-height-p-10 {
  height: 100% !important;
  max-height: 100% !important;
  min-height: 100% !important;
}
/**********  OPACITY UTILITY  **********/
.u-opacity-0 {
  -webkit-opacity: 0 !important;
  -moz-opacity: 0 !important;
  -o-opacity: 0 !important;
  opacity: 0 !important;
}
.u-opacity-1 {
  -webkit-opacity: 0.1 !important;
  -moz-opacity: 0.1 !important;
  -o-opacity: 0.1 !important;
  opacity: 0.1 !important;
}
.u-opacity-2 {
  -webkit-opacity: 0.2 !important;
  -moz-opacity: 0.2 !important;
  -o-opacity: 0.2 !important;
  opacity: 0.2 !important;
}
.u-opacity-3 {
  -webkit-opacity: 0.3 !important;
  -moz-opacity: 0.3 !important;
  -o-opacity: 0.3 !important;
  opacity: 0.3 !important;
}
.u-opacity-4 {
  -webkit-opacity: 0.4 !important;
  -moz-opacity: 0.4 !important;
  -o-opacity: 0.4 !important;
  opacity: 0.4 !important;
}
.u-opacity-5 {
  -webkit-opacity: 0.5 !important;
  -moz-opacity: 0.5 !important;
  -o-opacity: 0.5 !important;
  opacity: 0.5 !important;
}
.u-opacity-6 {
  -webkit-opacity: 0.6 !important;
  -moz-opacity: 0.6 !important;
  -o-opacity: 0.6 !important;
  opacity: 0.6 !important;
}
.u-opacity-7 {
  -webkit-opacity: 0.7 !important;
  -moz-opacity: 0.7 !important;
  -o-opacity: 0.7 !important;
  opacity: 0.7 !important;
}
.u-opacity-8 {
  -webkit-opacity: 0.8 !important;
  -moz-opacity: 0.8 !important;
  -o-opacity: 0.8 !important;
  opacity: 0.8 !important;
}
.u-opacity-9 {
  -webkit-opacity: 0.9 !important;
  -moz-opacity: 0.9 !important;
  -o-opacity: 0.9 !important;
  opacity: 0.9 !important;
}
.u-opacity-10 {
  -webkit-opacity: 1 !important;
  -moz-opacity: 1 !important;
  -o-opacity: 1 !important;
  opacity: 1 !important;
}
/**********  Z-INDEX UTILITY  **********/
.u-z-index-0 {
  z-index: 0 !important;
}
.u-z-index-1 {
  z-index: 100 !important;
}
.u-z-index-2 {
  z-index: 200 !important;
}
.u-z-index-3 {
  z-index: 300 !important;
}
.u-z-index-4 {
  z-index: 400 !important;
}
.u-z-index-5 {
  z-index: 500 !important;
}
.u-z-index-6 {
  z-index: 600 !important;
}
.u-z-index-7 {
  z-index: 700 !important;
}
.u-z-index-8 {
  z-index: 800 !important;
}
.u-z-index-9 {
  z-index: 900 !important;
}
.u-z-index-10 {
  z-index: 1000 !important;
}
/**********  LINE CLAMP UTILITY  **********/
.u-line-clamp-ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}
.u-line-clamp-1 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 1 !important;
  -webkit-box-orient: vertical !important;
}
.u-line-clamp-2 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
}
.u-line-clamp-3 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
}
.u-line-clamp-4 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
}
/**********  WORD WRAP UTILITY  **********/
.u-wrap {
  white-space: normal !important;
}
.u-nowrap {
  white-space: nowrap !important;
}
/**********  OVERFLOW UTILITY  **********/
.u-overflow-hidden {
  overflow: hidden !important;
}
.u-overflow-auto {
  overflow: auto !important;
}
.u-overflow-scroll {
  overflow: scroll !important;
}
/**********  CURSOR UTILITY  **********/
.u-cursor-pointer {
  cursor: pointer !important;
}
.u-cursor-default {
  cursor: default !important;
}
.u-cursor-move {
  cursor: move !important;
}
.u-cursor-grab {
  cursor: grab !important;
}
.u-cursor-grabbing {
  cursor: grabbing !important;
}
/**********  BORDER UTILITY  **********/
.u-border-primary {
  border-color: #3a82ff !important;
}
.u-border-hover-primary {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-primary:hover {
  border-color: #3a82ff !important;
}
.u-border-primary-dark {
  border-color: #2474ff !important;
}
.u-border-hover-primary-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-primary-dark:hover {
  border-color: #2474ff !important;
}
.u-border-primary-darker {
  border-color: #0b64ff !important;
}
.u-border-hover-primary-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-primary-darker:hover {
  border-color: #0b64ff !important;
}
.u-border-primary-darkest {
  border-color: #005dfe !important;
}
.u-border-hover-primary-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-primary-darkest:hover {
  border-color: #005dfe !important;
}
.u-border-secondary {
  border-color: #172B4D !important;
}
.u-border-hover-secondary {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-secondary:hover {
  border-color: #172B4D !important;
}
.u-border-secondary-dark {
  border-color: #152848 !important;
}
.u-border-hover-secondary-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-secondary-dark:hover {
  border-color: #152848 !important;
}
.u-border-secondary-darker {
  border-color: #142541 !important;
}
.u-border-hover-secondary-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-secondary-darker:hover {
  border-color: #142541 !important;
}
.u-border-secondary-darkest {
  border-color: #13233e !important;
}
.u-border-hover-secondary-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-secondary-darkest:hover {
  border-color: #13233e !important;
}
.u-border-tertiary {
  border-color: #6554C0 !important;
}
.u-border-hover-tertiary {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-tertiary:hover {
  border-color: #6554C0 !important;
}
.u-border-tertiary-dark {
  border-color: #5846bb !important;
}
.u-border-hover-tertiary-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-tertiary-dark:hover {
  border-color: #5846bb !important;
}
.u-border-tertiary-darker {
  border-color: #503fab !important;
}
.u-border-hover-tertiary-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-tertiary-darker:hover {
  border-color: #503fab !important;
}
.u-border-tertiary-darkest {
  border-color: #4c3ca3 !important;
}
.u-border-hover-tertiary-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-tertiary-darkest:hover {
  border-color: #4c3ca3 !important;
}
.u-border-quat {
  border-color: #00B8D9 !important;
}
.u-border-hover-quat {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-quat:hover {
  border-color: #00B8D9 !important;
}
.u-border-quat-dark {
  border-color: #00abca !important;
}
.u-border-hover-quat-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-quat-dark:hover {
  border-color: #00abca !important;
}
.u-border-quat-darker {
  border-color: #009cb8 !important;
}
.u-border-hover-quat-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-quat-darker:hover {
  border-color: #009cb8 !important;
}
.u-border-quat-darkest {
  border-color: #0095b0 !important;
}
.u-border-hover-quat-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-quat-darkest:hover {
  border-color: #0095b0 !important;
}
.u-border-quin {
  border-color: #36B37E !important;
}
.u-border-hover-quin {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-quin:hover {
  border-color: #36B37E !important;
}
.u-border-quin-dark {
  border-color: #32a675 !important;
}
.u-border-hover-quin-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-quin-dark:hover {
  border-color: #32a675 !important;
}
.u-border-quin-darker {
  border-color: #2e986b !important;
}
.u-border-hover-quin-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-quin-darker:hover {
  border-color: #2e986b !important;
}
.u-border-quin-darkest {
  border-color: #2c9166 !important;
}
.u-border-hover-quin-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-quin-darkest:hover {
  border-color: #2c9166 !important;
}
.u-border-sen {
  border-color: #36B37E !important;
}
.u-border-hover-sen {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-sen:hover {
  border-color: #36B37E !important;
}
.u-border-sen-dark {
  border-color: #ff451b !important;
}
.u-border-hover-sen-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-sen-dark:hover {
  border-color: #ff451b !important;
}
.u-border-sen-darker {
  border-color: #ff3103 !important;
}
.u-border-hover-sen-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-sen-darker:hover {
  border-color: #ff3103 !important;
}
.u-border-sen-darkest {
  border-color: #f52d00 !important;
}
.u-border-hover-sen-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-sen-darkest:hover {
  border-color: #f52d00 !important;
}
.u-border-white {
  border-color: #FFFFFF !important;
}
.u-border-hover-white {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-white:hover {
  border-color: #FFFFFF !important;
}
.u-border-black {
  border-color: #000000 !important;
}
.u-border-hover-black {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-black:hover {
  border-color: #000000 !important;
}
.u-border-gray-lightest {
  border-color: #f0f3f5 !important;
}
.u-border-hover-gray-lightest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-gray-lightest:hover {
  border-color: #f0f3f5 !important;
}
.u-border-gray-lighter {
  border-color: #dde3ea !important;
}
.u-border-hover-gray-lighter {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-gray-lighter:hover {
  border-color: #dde3ea !important;
}
.u-border-gray-light {
  border-color: #98acbe !important;
}
.u-border-hover-gray-light {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-gray-light:hover {
  border-color: #98acbe !important;
}
.u-border-gray {
  border-color: #536c84 !important;
}
.u-border-hover-gray {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-gray:hover {
  border-color: #536c84 !important;
}
.u-border-gray-dark {
  border-color: #222c36 !important;
}
.u-border-hover-gray-dark {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-gray-dark:hover {
  border-color: #222c36 !important;
}
.u-border-gray-darker {
  border-color: #141a20 !important;
}
.u-border-hover-gray-darker {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-gray-darker:hover {
  border-color: #141a20 !important;
}
.u-border-gray-darkest {
  border-color: #000000 !important;
}
.u-border-hover-gray-darkest {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-gray-darkest:hover {
  border-color: #000000 !important;
}
.u-border-error {
  border-color: #FF5630 !important;
}
.u-border-hover-error {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-error:hover {
  border-color: #FF5630 !important;
}
.u-border-warning {
  border-color: #FDB813 !important;
}
.u-border-hover-warning {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-warning:hover {
  border-color: #FDB813 !important;
}
.u-border-confirm {
  border-color: #36B37E !important;
}
.u-border-hover-confirm {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-confirm:hover {
  border-color: #36B37E !important;
}
.u-border-success {
  border-color: #20A6FC !important;
}
.u-border-hover-success {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-success:hover {
  border-color: #20A6FC !important;
}
.u-border-border {
  border-color: #dde3ea !important;
}
.u-border-hover-border {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-border:hover {
  border-color: #dde3ea !important;
}
.u-border-link {
  border-color: #152848 !important;
}
.u-border-hover-link {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-border-hover-link:hover {
  border-color: #152848 !important;
}
.u-border-1 {
  border: solid 1px #dde3ea !important;
}
.u-border-2 {
  border: solid 2px #dde3ea !important;
}
.u-border-3 {
  border: solid 3px #dde3ea !important;
}
.u-border-4 {
  border: solid 4px #dde3ea !important;
}
.u-border-5 {
  border: solid 5px #dde3ea !important;
}
.u-border-0 {
  border: solid 0px #dde3ea !important;
}
.u-border-l-1 {
  border-left: solid 1px #dde3ea !important;
}
.u-border-l-2 {
  border-left: solid 2px #dde3ea !important;
}
.u-border-l-3 {
  border-left: solid 3px #dde3ea !important;
}
.u-border-l-4 {
  border-left: solid 4px #dde3ea !important;
}
.u-border-l-5 {
  border-left: solid 5px #dde3ea !important;
}
.u-border-l-0 {
  border-left: solid 0px #dde3ea !important;
}
.u-border-r-1 {
  border-right: solid 1px #dde3ea !important;
}
.u-border-r-2 {
  border-right: solid 2px #dde3ea !important;
}
.u-border-r-3 {
  border-right: solid 3px #dde3ea !important;
}
.u-border-r-4 {
  border-right: solid 4px #dde3ea !important;
}
.u-border-r-5 {
  border-right: solid 5px #dde3ea !important;
}
.u-border-r-0 {
  border-right: solid 0px #dde3ea !important;
}
.u-border-t-1 {
  border-top: solid 1px #dde3ea !important;
}
.u-border-t-2 {
  border-top: solid 2px #dde3ea !important;
}
.u-border-t-3 {
  border-top: solid 3px #dde3ea !important;
}
.u-border-t-4 {
  border-top: solid 4px #dde3ea !important;
}
.u-border-t-5 {
  border-top: solid 5px #dde3ea !important;
}
.u-border-t-0 {
  border-top: solid 0px #dde3ea !important;
}
.u-border-b-1 {
  border-bottom: solid 1px #dde3ea !important;
}
.u-border-b-2 {
  border-bottom: solid 2px #dde3ea !important;
}
.u-border-b-3 {
  border-bottom: solid 3px #dde3ea !important;
}
.u-border-b-4 {
  border-bottom: solid 4px #dde3ea !important;
}
.u-border-b-5 {
  border-bottom: solid 5px #dde3ea !important;
}
.u-border-b-0 {
  border-bottom: solid 0px #dde3ea !important;
}
/**********  BORDER RADIUS UTILITY  **********/
.u-border-radius-0 {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -o-border-radius: 0 !important;
  border-radius: 0 !important;
}
.u-border-radius-1 {
  -webkit-border-radius: 1px !important;
  -moz-border-radius: 1px !important;
  -o-border-radius: 1px !important;
  border-radius: 1px !important;
}
.u-border-radius-2 {
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  -o-border-radius: 2px !important;
  border-radius: 2px !important;
}
.u-border-radius-3 {
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -o-border-radius: 3px !important;
  border-radius: 3px !important;
}
.u-border-radius-4 {
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -o-border-radius: 4px !important;
  border-radius: 4px !important;
}
.u-border-radius-5 {
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -o-border-radius: 5px !important;
  border-radius: 5px !important;
}
.u-border-radius-6 {
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -o-border-radius: 6px !important;
  border-radius: 6px !important;
}
.u-border-radius-7 {
  -webkit-border-radius: 7px !important;
  -moz-border-radius: 7px !important;
  -o-border-radius: 7px !important;
  border-radius: 7px !important;
}
.u-border-radius-8 {
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -o-border-radius: 8px !important;
  border-radius: 8px !important;
}
.u-border-radius-9 {
  -webkit-border-radius: 9px !important;
  -moz-border-radius: 9px !important;
  -o-border-radius: 9px !important;
  border-radius: 9px !important;
}
.u-border-radius-10 {
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -o-border-radius: 10px !important;
  border-radius: 10px !important;
}
.u-circle {
  -webkit-border-radius: 500px !important;
  -moz-border-radius: 500px !important;
  -o-border-radius: 500px !important;
  border-radius: 500px !important;
}
/**********  SELF TRANSLATE UTILITY  **********/
.u-translate-self-x {
  -webkit-transform: translateX(-50%) !important;
  -moz-transform: translateX(-50%) !important;
  -o-transform: translateX(-50%) !important;
  transform: translateX(-50%) !important;
}
.u-translate-self-y {
  -webkit-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  -o-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}
/**********  MARGIN OFFSET UTILITY  **********/
.u-mt-offset-5 {
  margin-top: -5rem !important;
}
.u-mt-offset-4 {
  margin-top: -4rem !important;
}
.u-mt-offset-3 {
  margin-top: -3rem !important;
}
.u-mt-offset-2 {
  margin-top: -2rem !important;
}
.u-mt-offset-1 {
  margin-top: -1rem !important;
}
.u-mb-offset-5 {
  margin-top: 5rem !important;
}
.u-mb-offset-4 {
  margin-top: 4rem !important;
}
.u-mb-offset-3 {
  margin-top: 3rem !important;
}
.u-mb-offset-2 {
  margin-top: 2rem !important;
}
.u-mb-offset-1 {
  margin-top: 1rem !important;
}
/**********  HOVER GROW UTILITY  **********/
.u-hover-grow-1,
.u-hover-grow-2,
.u-hover-grow-3,
.u-hover-grow-4,
.u-hover-grow-5,
.u-hover-grow-6,
.u-hover-grow-7,
.u-hover-grow-8,
.u-hover-grow-9,
.u-hover-grow-10 {
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  -o-transform: scale(1) !important;
  transform: scale(1) !important;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.u-hover-grow-1:hover {
  -webkit-transform: scale(1.025) !important;
  -moz-transform: scale(1.025) !important;
  -o-transform: scale(1.025) !important;
  transform: scale(1.025) !important;
}
.u-hover-grow-2:hover {
  -webkit-transform: scale(1.05) !important;
  -moz-transform: scale(1.05) !important;
  -o-transform: scale(1.05) !important;
  transform: scale(1.05) !important;
}
.u-hover-grow-3:hover {
  -webkit-transform: scale(1.075) !important;
  -moz-transform: scale(1.075) !important;
  -o-transform: scale(1.075) !important;
  transform: scale(1.075) !important;
}
.u-hover-grow-4:hover {
  -webkit-transform: scale(1.1) !important;
  -moz-transform: scale(1.1) !important;
  -o-transform: scale(1.1) !important;
  transform: scale(1.1) !important;
}
.u-hover-grow-5:hover {
  -webkit-transform: scale(1.125) !important;
  -moz-transform: scale(1.125) !important;
  -o-transform: scale(1.125) !important;
  transform: scale(1.125) !important;
}
.u-hover-grow-6:hover {
  -webkit-transform: scale(1.15) !important;
  -moz-transform: scale(1.15) !important;
  -o-transform: scale(1.15) !important;
  transform: scale(1.15) !important;
}
.u-hover-grow-7:hover {
  -webkit-transform: scale(1.175) !important;
  -moz-transform: scale(1.175) !important;
  -o-transform: scale(1.175) !important;
  transform: scale(1.175) !important;
}
.u-hover-grow-8:hover {
  -webkit-transform: scale(1.2) !important;
  -moz-transform: scale(1.2) !important;
  -o-transform: scale(1.2) !important;
  transform: scale(1.2) !important;
}
.u-hover-grow-9:hover {
  -webkit-transform: scale(1.225) !important;
  -moz-transform: scale(1.225) !important;
  -o-transform: scale(1.225) !important;
  transform: scale(1.225) !important;
}
.u-hover-grow-10:hover {
  -webkit-transform: scale(1.25) !important;
  -moz-transform: scale(1.25) !important;
  -o-transform: scale(1.25) !important;
  transform: scale(1.25) !important;
}
/**********  DROP SHADOW UTILITY  **********/
.u-shadow-0 {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
}
.u-shadow-hover-0 {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-shadow-hover-0:hover {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
}
.u-shadow-1 {
  -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.19) !important;
  -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.19) !important;
  -o-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.19) !important;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.19) !important;
}
.u-shadow-hover-1 {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-shadow-hover-1:hover {
  -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.19) !important;
  -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.19) !important;
  -o-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.19) !important;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.19) !important;
}
.u-shadow-2 {
  -webkit-box-shadow: 0 4.5px 7.5px 0 rgba(0, 0, 0, 0.18) !important;
  -moz-box-shadow: 0 4.5px 7.5px 0 rgba(0, 0, 0, 0.18) !important;
  -o-box-shadow: 0 4.5px 7.5px 0 rgba(0, 0, 0, 0.18) !important;
  box-shadow: 0 4.5px 7.5px 0 rgba(0, 0, 0, 0.18) !important;
}
.u-shadow-hover-2 {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-shadow-hover-2:hover {
  -webkit-box-shadow: 0 4.5px 7.5px 0 rgba(0, 0, 0, 0.18) !important;
  -moz-box-shadow: 0 4.5px 7.5px 0 rgba(0, 0, 0, 0.18) !important;
  -o-box-shadow: 0 4.5px 7.5px 0 rgba(0, 0, 0, 0.18) !important;
  box-shadow: 0 4.5px 7.5px 0 rgba(0, 0, 0, 0.18) !important;
}
.u-shadow-3 {
  -webkit-box-shadow: 0 9px 15px 0 rgba(0, 0, 0, 0.17) !important;
  -moz-box-shadow: 0 9px 15px 0 rgba(0, 0, 0, 0.17) !important;
  -o-box-shadow: 0 9px 15px 0 rgba(0, 0, 0, 0.17) !important;
  box-shadow: 0 9px 15px 0 rgba(0, 0, 0, 0.17) !important;
}
.u-shadow-hover-3 {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-shadow-hover-3:hover {
  -webkit-box-shadow: 0 9px 15px 0 rgba(0, 0, 0, 0.17) !important;
  -moz-box-shadow: 0 9px 15px 0 rgba(0, 0, 0, 0.17) !important;
  -o-box-shadow: 0 9px 15px 0 rgba(0, 0, 0, 0.17) !important;
  box-shadow: 0 9px 15px 0 rgba(0, 0, 0, 0.17) !important;
}
.u-shadow-4 {
  -webkit-box-shadow: 0 13.5px 22.5px 0 rgba(0, 0, 0, 0.16) !important;
  -moz-box-shadow: 0 13.5px 22.5px 0 rgba(0, 0, 0, 0.16) !important;
  -o-box-shadow: 0 13.5px 22.5px 0 rgba(0, 0, 0, 0.16) !important;
  box-shadow: 0 13.5px 22.5px 0 rgba(0, 0, 0, 0.16) !important;
}
.u-shadow-hover-4 {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-shadow-hover-4:hover {
  -webkit-box-shadow: 0 13.5px 22.5px 0 rgba(0, 0, 0, 0.16) !important;
  -moz-box-shadow: 0 13.5px 22.5px 0 rgba(0, 0, 0, 0.16) !important;
  -o-box-shadow: 0 13.5px 22.5px 0 rgba(0, 0, 0, 0.16) !important;
  box-shadow: 0 13.5px 22.5px 0 rgba(0, 0, 0, 0.16) !important;
}
.u-shadow-5 {
  -webkit-box-shadow: 0 18px 30px 0 rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0 18px 30px 0 rgba(0, 0, 0, 0.15) !important;
  -o-box-shadow: 0 18px 30px 0 rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 18px 30px 0 rgba(0, 0, 0, 0.15) !important;
}
.u-shadow-hover-5 {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-shadow-hover-5:hover {
  -webkit-box-shadow: 0 18px 30px 0 rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 0 18px 30px 0 rgba(0, 0, 0, 0.15) !important;
  -o-box-shadow: 0 18px 30px 0 rgba(0, 0, 0, 0.15) !important;
  box-shadow: 0 18px 30px 0 rgba(0, 0, 0, 0.15) !important;
}
.u-shadow-6 {
  -webkit-box-shadow: 0 22.5px 37.5px 0 rgba(0, 0, 0, 0.14) !important;
  -moz-box-shadow: 0 22.5px 37.5px 0 rgba(0, 0, 0, 0.14) !important;
  -o-box-shadow: 0 22.5px 37.5px 0 rgba(0, 0, 0, 0.14) !important;
  box-shadow: 0 22.5px 37.5px 0 rgba(0, 0, 0, 0.14) !important;
}
.u-shadow-hover-6 {
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.u-shadow-hover-6:hover {
  -webkit-box-shadow: 0 22.5px 37.5px 0 rgba(0, 0, 0, 0.14) !important;
  -moz-box-shadow: 0 22.5px 37.5px 0 rgba(0, 0, 0, 0.14) !important;
  -o-box-shadow: 0 22.5px 37.5px 0 rgba(0, 0, 0, 0.14) !important;
  box-shadow: 0 22.5px 37.5px 0 rgba(0, 0, 0, 0.14) !important;
}
/*///////////////////////////////////////////////*/
/*                   ANIMATIONS                  */
/*///////////////////////////////////////////////*/
.anim_fade_in_rise {
  -webkit-animation: FADE_IN_RISE 0.5s 1;
  -moz-animation: FADE_IN_RISE 0.5s 1;
  -o-animation: FADE_IN_RISE 0.5s 1;
  animation: FADE_IN_RISE 0.5s 1;
}
.anim_fade_in_rise_quick {
  -webkit-animation: FADE_IN_RISE 0.25s 1;
  -moz-animation: FADE_IN_RISE 0.25s 1;
  -o-animation: FADE_IN_RISE 0.25s 1;
  animation: FADE_IN_RISE 0.25s 1;
}
.anim_fade_in_rise_long {
  -webkit-animation: FADE_IN_RISE 1s 1;
  -moz-animation: FADE_IN_RISE 1s 1;
  -o-animation: FADE_IN_RISE 1s 1;
  animation: FADE_IN_RISE 1s 1;
}
@keyframes FADE_IN_RISE {
  0% {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
    top: 15px;
  }
  100% {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
    top: 0;
  }
}
.anim_fade_in {
  -webkit-animation: FADE_IN 0.5s 1;
  -moz-animation: FADE_IN 0.5s 1;
  -o-animation: FADE_IN 0.5s 1;
  animation: FADE_IN 0.5s 1;
}
.anim_fade_in_quick {
  -webkit-animation: FADE_IN 0.25s 1;
  -moz-animation: FADE_IN 0.25s 1;
  -o-animation: FADE_IN 0.25s 1;
  animation: FADE_IN 0.25s 1;
}
.anim_fade_in_long {
  -webkit-animation: FADE_IN 1s 1;
  -moz-animation: FADE_IN 1s 1;
  -o-animation: FADE_IN 1s 1;
  animation: FADE_IN 1s 1;
}
@keyframes FADE_IN {
  0% {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
  }
  100% {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
  }
}
.anim_glide_left {
  -webkit-animation: GLIDE_LEFT 0.5s 1;
  -moz-animation: GLIDE_LEFT 0.5s 1;
  -o-animation: GLIDE_LEFT 0.5s 1;
  animation: GLIDE_LEFT 0.5s 1;
}
.anim_glide_left_quick {
  -webkit-animation: GLIDE_LEFT 0.25s 1;
  -moz-animation: GLIDE_LEFT 0.25s 1;
  -o-animation: GLIDE_LEFT 0.25s 1;
  animation: GLIDE_LEFT 0.25s 1;
}
.anim_glide_left_long {
  -webkit-animation: GLIDE_LEFT 1s 1;
  -moz-animation: GLIDE_LEFT 1s 1;
  -o-animation: GLIDE_LEFT 1s 1;
  animation: GLIDE_LEFT 1s 1;
}
@keyframes GLIDE_LEFT {
  0% {
    -webkit-opacity: 0;
    -moz-opacity: 0;
    -o-opacity: 0;
    opacity: 0;
    margin-left: 30px;
  }
  100% {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    -o-opacity: 1;
    opacity: 1;
    margin-left: 0px;
  }
}
/*!
 * Font Awesome Pro 5.8.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.fa-xs {
  font-size: 0.75em;
}
.fa-sm {
  font-size: 0.875em;
}
.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-6x {
  font-size: 6em;
}
.fa-7x {
  font-size: 7em;
}
.fa-8x {
  font-size: 8em;
}
.fa-9x {
  font-size: 9em;
}
.fa-10x {
  font-size: 10em;
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}
.fa-border {
  border-radius: 0.1em;
  border: solid 0.08em #eee;
  padding: 0.2em 0.25em 0.15em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}
.fa-spin {
  animation: fa-spin 2s infinite linear;
}
.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}
.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}
.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none;
}
.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}
.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}
.fa-stack-1x {
  line-height: inherit;
}
.fa-stack-2x {
  font-size: 2em;
}
.fa-inverse {
  color: #fff;
}
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e";
}
.fa-abacus:before {
  content: "\f640";
}
.fa-accessible-icon:before {
  content: "\f368";
}
.fa-accusoft:before {
  content: "\f369";
}
.fa-acorn:before {
  content: "\f6ae";
}
.fa-acquisitions-incorporated:before {
  content: "\f6af";
}
.fa-ad:before {
  content: "\f641";
}
.fa-address-book:before {
  content: "\f2b9";
}
.fa-address-card:before {
  content: "\f2bb";
}
.fa-adjust:before {
  content: "\f042";
}
.fa-adn:before {
  content: "\f170";
}
.fa-adobe:before {
  content: "\f778";
}
.fa-adversal:before {
  content: "\f36a";
}
.fa-affiliatetheme:before {
  content: "\f36b";
}
.fa-air-freshener:before {
  content: "\f5d0";
}
.fa-airbnb:before {
  content: "\f834";
}
.fa-alarm-clock:before {
  content: "\f34e";
}
.fa-algolia:before {
  content: "\f36c";
}
.fa-alicorn:before {
  content: "\f6b0";
}
.fa-align-center:before {
  content: "\f037";
}
.fa-align-justify:before {
  content: "\f039";
}
.fa-align-left:before {
  content: "\f036";
}
.fa-align-right:before {
  content: "\f038";
}
.fa-alipay:before {
  content: "\f642";
}
.fa-allergies:before {
  content: "\f461";
}
.fa-amazon:before {
  content: "\f270";
}
.fa-amazon-pay:before {
  content: "\f42c";
}
.fa-ambulance:before {
  content: "\f0f9";
}
.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}
.fa-amilia:before {
  content: "\f36d";
}
.fa-analytics:before {
  content: "\f643";
}
.fa-anchor:before {
  content: "\f13d";
}
.fa-android:before {
  content: "\f17b";
}
.fa-angel:before {
  content: "\f779";
}
.fa-angellist:before {
  content: "\f209";
}
.fa-angle-double-down:before {
  content: "\f103";
}
.fa-angle-double-left:before {
  content: "\f100";
}
.fa-angle-double-right:before {
  content: "\f101";
}
.fa-angle-double-up:before {
  content: "\f102";
}
.fa-angle-down:before {
  content: "\f107";
}
.fa-angle-left:before {
  content: "\f104";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-angle-up:before {
  content: "\f106";
}
.fa-angry:before {
  content: "\f556";
}
.fa-angrycreative:before {
  content: "\f36e";
}
.fa-angular:before {
  content: "\f420";
}
.fa-ankh:before {
  content: "\f644";
}
.fa-app-store:before {
  content: "\f36f";
}
.fa-app-store-ios:before {
  content: "\f370";
}
.fa-apper:before {
  content: "\f371";
}
.fa-apple:before {
  content: "\f179";
}
.fa-apple-alt:before {
  content: "\f5d1";
}
.fa-apple-crate:before {
  content: "\f6b1";
}
.fa-apple-pay:before {
  content: "\f415";
}
.fa-archive:before {
  content: "\f187";
}
.fa-archway:before {
  content: "\f557";
}
.fa-arrow-alt-circle-down:before {
  content: "\f358";
}
.fa-arrow-alt-circle-left:before {
  content: "\f359";
}
.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}
.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}
.fa-arrow-alt-down:before {
  content: "\f354";
}
.fa-arrow-alt-from-bottom:before {
  content: "\f346";
}
.fa-arrow-alt-from-left:before {
  content: "\f347";
}
.fa-arrow-alt-from-right:before {
  content: "\f348";
}
.fa-arrow-alt-from-top:before {
  content: "\f349";
}
.fa-arrow-alt-left:before {
  content: "\f355";
}
.fa-arrow-alt-right:before {
  content: "\f356";
}
.fa-arrow-alt-square-down:before {
  content: "\f350";
}
.fa-arrow-alt-square-left:before {
  content: "\f351";
}
.fa-arrow-alt-square-right:before {
  content: "\f352";
}
.fa-arrow-alt-square-up:before {
  content: "\f353";
}
.fa-arrow-alt-to-bottom:before {
  content: "\f34a";
}
.fa-arrow-alt-to-left:before {
  content: "\f34b";
}
.fa-arrow-alt-to-right:before {
  content: "\f34c";
}
.fa-arrow-alt-to-top:before {
  content: "\f34d";
}
.fa-arrow-alt-up:before {
  content: "\f357";
}
.fa-arrow-circle-down:before {
  content: "\f0ab";
}
.fa-arrow-circle-left:before {
  content: "\f0a8";
}
.fa-arrow-circle-right:before {
  content: "\f0a9";
}
.fa-arrow-circle-up:before {
  content: "\f0aa";
}
.fa-arrow-down:before {
  content: "\f063";
}
.fa-arrow-from-bottom:before {
  content: "\f342";
}
.fa-arrow-from-left:before {
  content: "\f343";
}
.fa-arrow-from-right:before {
  content: "\f344";
}
.fa-arrow-from-top:before {
  content: "\f345";
}
.fa-arrow-left:before {
  content: "\f060";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-arrow-square-down:before {
  content: "\f339";
}
.fa-arrow-square-left:before {
  content: "\f33a";
}
.fa-arrow-square-right:before {
  content: "\f33b";
}
.fa-arrow-square-up:before {
  content: "\f33c";
}
.fa-arrow-to-bottom:before {
  content: "\f33d";
}
.fa-arrow-to-left:before {
  content: "\f33e";
}
.fa-arrow-to-right:before {
  content: "\f340";
}
.fa-arrow-to-top:before {
  content: "\f341";
}
.fa-arrow-up:before {
  content: "\f062";
}
.fa-arrows:before {
  content: "\f047";
}
.fa-arrows-alt:before {
  content: "\f0b2";
}
.fa-arrows-alt-h:before {
  content: "\f337";
}
.fa-arrows-alt-v:before {
  content: "\f338";
}
.fa-arrows-h:before {
  content: "\f07e";
}
.fa-arrows-v:before {
  content: "\f07d";
}
.fa-artstation:before {
  content: "\f77a";
}
.fa-assistive-listening-systems:before {
  content: "\f2a2";
}
.fa-asterisk:before {
  content: "\f069";
}
.fa-asymmetrik:before {
  content: "\f372";
}
.fa-at:before {
  content: "\f1fa";
}
.fa-atlas:before {
  content: "\f558";
}
.fa-atlassian:before {
  content: "\f77b";
}
.fa-atom:before {
  content: "\f5d2";
}
.fa-atom-alt:before {
  content: "\f5d3";
}
.fa-audible:before {
  content: "\f373";
}
.fa-audio-description:before {
  content: "\f29e";
}
.fa-autoprefixer:before {
  content: "\f41c";
}
.fa-avianex:before {
  content: "\f374";
}
.fa-aviato:before {
  content: "\f421";
}
.fa-award:before {
  content: "\f559";
}
.fa-aws:before {
  content: "\f375";
}
.fa-axe:before {
  content: "\f6b2";
}
.fa-axe-battle:before {
  content: "\f6b3";
}
.fa-baby:before {
  content: "\f77c";
}
.fa-baby-carriage:before {
  content: "\f77d";
}
.fa-backpack:before {
  content: "\f5d4";
}
.fa-backspace:before {
  content: "\f55a";
}
.fa-backward:before {
  content: "\f04a";
}
.fa-bacon:before {
  content: "\f7e5";
}
.fa-badge:before {
  content: "\f335";
}
.fa-badge-check:before {
  content: "\f336";
}
.fa-badge-dollar:before {
  content: "\f645";
}
.fa-badge-percent:before {
  content: "\f646";
}
.fa-badger-honey:before {
  content: "\f6b4";
}
.fa-balance-scale:before {
  content: "\f24e";
}
.fa-balance-scale-left:before {
  content: "\f515";
}
.fa-balance-scale-right:before {
  content: "\f516";
}
.fa-ball-pile:before {
  content: "\f77e";
}
.fa-ballot:before {
  content: "\f732";
}
.fa-ballot-check:before {
  content: "\f733";
}
.fa-ban:before {
  content: "\f05e";
}
.fa-band-aid:before {
  content: "\f462";
}
.fa-bandcamp:before {
  content: "\f2d5";
}
.fa-barcode:before {
  content: "\f02a";
}
.fa-barcode-alt:before {
  content: "\f463";
}
.fa-barcode-read:before {
  content: "\f464";
}
.fa-barcode-scan:before {
  content: "\f465";
}
.fa-bars:before {
  content: "\f0c9";
}
.fa-baseball:before {
  content: "\f432";
}
.fa-baseball-ball:before {
  content: "\f433";
}
.fa-basketball-ball:before {
  content: "\f434";
}
.fa-basketball-hoop:before {
  content: "\f435";
}
.fa-bat:before {
  content: "\f6b5";
}
.fa-bath:before {
  content: "\f2cd";
}
.fa-battery-bolt:before {
  content: "\f376";
}
.fa-battery-empty:before {
  content: "\f244";
}
.fa-battery-full:before {
  content: "\f240";
}
.fa-battery-half:before {
  content: "\f242";
}
.fa-battery-quarter:before {
  content: "\f243";
}
.fa-battery-slash:before {
  content: "\f377";
}
.fa-battery-three-quarters:before {
  content: "\f241";
}
.fa-battle-net:before {
  content: "\f835";
}
.fa-bed:before {
  content: "\f236";
}
.fa-beer:before {
  content: "\f0fc";
}
.fa-behance:before {
  content: "\f1b4";
}
.fa-behance-square:before {
  content: "\f1b5";
}
.fa-bell:before {
  content: "\f0f3";
}
.fa-bell-school:before {
  content: "\f5d5";
}
.fa-bell-school-slash:before {
  content: "\f5d6";
}
.fa-bell-slash:before {
  content: "\f1f6";
}
.fa-bells:before {
  content: "\f77f";
}
.fa-bezier-curve:before {
  content: "\f55b";
}
.fa-bible:before {
  content: "\f647";
}
.fa-bicycle:before {
  content: "\f206";
}
.fa-bimobject:before {
  content: "\f378";
}
.fa-binoculars:before {
  content: "\f1e5";
}
.fa-biohazard:before {
  content: "\f780";
}
.fa-birthday-cake:before {
  content: "\f1fd";
}
.fa-bitbucket:before {
  content: "\f171";
}
.fa-bitcoin:before {
  content: "\f379";
}
.fa-bity:before {
  content: "\f37a";
}
.fa-black-tie:before {
  content: "\f27e";
}
.fa-blackberry:before {
  content: "\f37b";
}
.fa-blanket:before {
  content: "\f498";
}
.fa-blender:before {
  content: "\f517";
}
.fa-blender-phone:before {
  content: "\f6b6";
}
.fa-blind:before {
  content: "\f29d";
}
.fa-blog:before {
  content: "\f781";
}
.fa-blogger:before {
  content: "\f37c";
}
.fa-blogger-b:before {
  content: "\f37d";
}
.fa-bluetooth:before {
  content: "\f293";
}
.fa-bluetooth-b:before {
  content: "\f294";
}
.fa-bold:before {
  content: "\f032";
}
.fa-bolt:before {
  content: "\f0e7";
}
.fa-bomb:before {
  content: "\f1e2";
}
.fa-bone:before {
  content: "\f5d7";
}
.fa-bone-break:before {
  content: "\f5d8";
}
.fa-bong:before {
  content: "\f55c";
}
.fa-book:before {
  content: "\f02d";
}
.fa-book-alt:before {
  content: "\f5d9";
}
.fa-book-dead:before {
  content: "\f6b7";
}
.fa-book-heart:before {
  content: "\f499";
}
.fa-book-medical:before {
  content: "\f7e6";
}
.fa-book-open:before {
  content: "\f518";
}
.fa-book-reader:before {
  content: "\f5da";
}
.fa-book-spells:before {
  content: "\f6b8";
}
.fa-book-user:before {
  content: "\f7e7";
}
.fa-bookmark:before {
  content: "\f02e";
}
.fa-books:before {
  content: "\f5db";
}
.fa-books-medical:before {
  content: "\f7e8";
}
.fa-boot:before {
  content: "\f782";
}
.fa-booth-curtain:before {
  content: "\f734";
}
.fa-bootstrap:before {
  content: "\f836";
}
.fa-bow-arrow:before {
  content: "\f6b9";
}
.fa-bowling-ball:before {
  content: "\f436";
}
.fa-bowling-pins:before {
  content: "\f437";
}
.fa-box:before {
  content: "\f466";
}
.fa-box-alt:before {
  content: "\f49a";
}
.fa-box-ballot:before {
  content: "\f735";
}
.fa-box-check:before {
  content: "\f467";
}
.fa-box-fragile:before {
  content: "\f49b";
}
.fa-box-full:before {
  content: "\f49c";
}
.fa-box-heart:before {
  content: "\f49d";
}
.fa-box-open:before {
  content: "\f49e";
}
.fa-box-up:before {
  content: "\f49f";
}
.fa-box-usd:before {
  content: "\f4a0";
}
.fa-boxes:before {
  content: "\f468";
}
.fa-boxes-alt:before {
  content: "\f4a1";
}
.fa-boxing-glove:before {
  content: "\f438";
}
.fa-brackets:before {
  content: "\f7e9";
}
.fa-brackets-curly:before {
  content: "\f7ea";
}
.fa-braille:before {
  content: "\f2a1";
}
.fa-brain:before {
  content: "\f5dc";
}
.fa-bread-loaf:before {
  content: "\f7eb";
}
.fa-bread-slice:before {
  content: "\f7ec";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-briefcase-medical:before {
  content: "\f469";
}
.fa-broadcast-tower:before {
  content: "\f519";
}
.fa-broom:before {
  content: "\f51a";
}
.fa-browser:before {
  content: "\f37e";
}
.fa-brush:before {
  content: "\f55d";
}
.fa-btc:before {
  content: "\f15a";
}
.fa-buffer:before {
  content: "\f837";
}
.fa-bug:before {
  content: "\f188";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-bullhorn:before {
  content: "\f0a1";
}
.fa-bullseye:before {
  content: "\f140";
}
.fa-bullseye-arrow:before {
  content: "\f648";
}
.fa-bullseye-pointer:before {
  content: "\f649";
}
.fa-burn:before {
  content: "\f46a";
}
.fa-buromobelexperte:before {
  content: "\f37f";
}
.fa-burrito:before {
  content: "\f7ed";
}
.fa-bus:before {
  content: "\f207";
}
.fa-bus-alt:before {
  content: "\f55e";
}
.fa-bus-school:before {
  content: "\f5dd";
}
.fa-business-time:before {
  content: "\f64a";
}
.fa-buysellads:before {
  content: "\f20d";
}
.fa-cabinet-filing:before {
  content: "\f64b";
}
.fa-calculator:before {
  content: "\f1ec";
}
.fa-calculator-alt:before {
  content: "\f64c";
}
.fa-calendar:before {
  content: "\f133";
}
.fa-calendar-alt:before {
  content: "\f073";
}
.fa-calendar-check:before {
  content: "\f274";
}
.fa-calendar-day:before {
  content: "\f783";
}
.fa-calendar-edit:before {
  content: "\f333";
}
.fa-calendar-exclamation:before {
  content: "\f334";
}
.fa-calendar-minus:before {
  content: "\f272";
}
.fa-calendar-plus:before {
  content: "\f271";
}
.fa-calendar-star:before {
  content: "\f736";
}
.fa-calendar-times:before {
  content: "\f273";
}
.fa-calendar-week:before {
  content: "\f784";
}
.fa-camera:before {
  content: "\f030";
}
.fa-camera-alt:before {
  content: "\f332";
}
.fa-camera-retro:before {
  content: "\f083";
}
.fa-campfire:before {
  content: "\f6ba";
}
.fa-campground:before {
  content: "\f6bb";
}
.fa-canadian-maple-leaf:before {
  content: "\f785";
}
.fa-candle-holder:before {
  content: "\f6bc";
}
.fa-candy-cane:before {
  content: "\f786";
}
.fa-candy-corn:before {
  content: "\f6bd";
}
.fa-cannabis:before {
  content: "\f55f";
}
.fa-capsules:before {
  content: "\f46b";
}
.fa-car:before {
  content: "\f1b9";
}
.fa-car-alt:before {
  content: "\f5de";
}
.fa-car-battery:before {
  content: "\f5df";
}
.fa-car-bump:before {
  content: "\f5e0";
}
.fa-car-crash:before {
  content: "\f5e1";
}
.fa-car-garage:before {
  content: "\f5e2";
}
.fa-car-mechanic:before {
  content: "\f5e3";
}
.fa-car-side:before {
  content: "\f5e4";
}
.fa-car-tilt:before {
  content: "\f5e5";
}
.fa-car-wash:before {
  content: "\f5e6";
}
.fa-caret-circle-down:before {
  content: "\f32d";
}
.fa-caret-circle-left:before {
  content: "\f32e";
}
.fa-caret-circle-right:before {
  content: "\f330";
}
.fa-caret-circle-up:before {
  content: "\f331";
}
.fa-caret-down:before {
  content: "\f0d7";
}
.fa-caret-left:before {
  content: "\f0d9";
}
.fa-caret-right:before {
  content: "\f0da";
}
.fa-caret-square-down:before {
  content: "\f150";
}
.fa-caret-square-left:before {
  content: "\f191";
}
.fa-caret-square-right:before {
  content: "\f152";
}
.fa-caret-square-up:before {
  content: "\f151";
}
.fa-caret-up:before {
  content: "\f0d8";
}
.fa-carrot:before {
  content: "\f787";
}
.fa-cart-arrow-down:before {
  content: "\f218";
}
.fa-cart-plus:before {
  content: "\f217";
}
.fa-cash-register:before {
  content: "\f788";
}
.fa-cat:before {
  content: "\f6be";
}
.fa-cauldron:before {
  content: "\f6bf";
}
.fa-cc-amazon-pay:before {
  content: "\f42d";
}
.fa-cc-amex:before {
  content: "\f1f3";
}
.fa-cc-apple-pay:before {
  content: "\f416";
}
.fa-cc-diners-club:before {
  content: "\f24c";
}
.fa-cc-discover:before {
  content: "\f1f2";
}
.fa-cc-jcb:before {
  content: "\f24b";
}
.fa-cc-mastercard:before {
  content: "\f1f1";
}
.fa-cc-paypal:before {
  content: "\f1f4";
}
.fa-cc-stripe:before {
  content: "\f1f5";
}
.fa-cc-visa:before {
  content: "\f1f0";
}
.fa-centercode:before {
  content: "\f380";
}
.fa-centos:before {
  content: "\f789";
}
.fa-certificate:before {
  content: "\f0a3";
}
.fa-chair:before {
  content: "\f6c0";
}
.fa-chair-office:before {
  content: "\f6c1";
}
.fa-chalkboard:before {
  content: "\f51b";
}
.fa-chalkboard-teacher:before {
  content: "\f51c";
}
.fa-charging-station:before {
  content: "\f5e7";
}
.fa-chart-area:before {
  content: "\f1fe";
}
.fa-chart-bar:before {
  content: "\f080";
}
.fa-chart-line:before {
  content: "\f201";
}
.fa-chart-line-down:before {
  content: "\f64d";
}
.fa-chart-network:before {
  content: "\f78a";
}
.fa-chart-pie:before {
  content: "\f200";
}
.fa-chart-pie-alt:before {
  content: "\f64e";
}
.fa-chart-scatter:before {
  content: "\f7ee";
}
.fa-check:before {
  content: "\f00c";
}
.fa-check-circle:before {
  content: "\f058";
}
.fa-check-double:before {
  content: "\f560";
}
.fa-check-square:before {
  content: "\f14a";
}
.fa-cheese:before {
  content: "\f7ef";
}
.fa-cheese-swiss:before {
  content: "\f7f0";
}
.fa-cheeseburger:before {
  content: "\f7f1";
}
.fa-chess:before {
  content: "\f439";
}
.fa-chess-bishop:before {
  content: "\f43a";
}
.fa-chess-bishop-alt:before {
  content: "\f43b";
}
.fa-chess-board:before {
  content: "\f43c";
}
.fa-chess-clock:before {
  content: "\f43d";
}
.fa-chess-clock-alt:before {
  content: "\f43e";
}
.fa-chess-king:before {
  content: "\f43f";
}
.fa-chess-king-alt:before {
  content: "\f440";
}
.fa-chess-knight:before {
  content: "\f441";
}
.fa-chess-knight-alt:before {
  content: "\f442";
}
.fa-chess-pawn:before {
  content: "\f443";
}
.fa-chess-pawn-alt:before {
  content: "\f444";
}
.fa-chess-queen:before {
  content: "\f445";
}
.fa-chess-queen-alt:before {
  content: "\f446";
}
.fa-chess-rook:before {
  content: "\f447";
}
.fa-chess-rook-alt:before {
  content: "\f448";
}
.fa-chevron-circle-down:before {
  content: "\f13a";
}
.fa-chevron-circle-left:before {
  content: "\f137";
}
.fa-chevron-circle-right:before {
  content: "\f138";
}
.fa-chevron-circle-up:before {
  content: "\f139";
}
.fa-chevron-double-down:before {
  content: "\f322";
}
.fa-chevron-double-left:before {
  content: "\f323";
}
.fa-chevron-double-right:before {
  content: "\f324";
}
.fa-chevron-double-up:before {
  content: "\f325";
}
.fa-chevron-down:before {
  content: "\f078";
}
.fa-chevron-left:before {
  content: "\f053";
}
.fa-chevron-right:before {
  content: "\f054";
}
.fa-chevron-square-down:before {
  content: "\f329";
}
.fa-chevron-square-left:before {
  content: "\f32a";
}
.fa-chevron-square-right:before {
  content: "\f32b";
}
.fa-chevron-square-up:before {
  content: "\f32c";
}
.fa-chevron-up:before {
  content: "\f077";
}
.fa-child:before {
  content: "\f1ae";
}
.fa-chimney:before {
  content: "\f78b";
}
.fa-chrome:before {
  content: "\f268";
}
.fa-chromecast:before {
  content: "\f838";
}
.fa-church:before {
  content: "\f51d";
}
.fa-circle:before {
  content: "\f111";
}
.fa-circle-notch:before {
  content: "\f1ce";
}
.fa-city:before {
  content: "\f64f";
}
.fa-claw-marks:before {
  content: "\f6c2";
}
.fa-clinic-medical:before {
  content: "\f7f2";
}
.fa-clipboard:before {
  content: "\f328";
}
.fa-clipboard-check:before {
  content: "\f46c";
}
.fa-clipboard-list:before {
  content: "\f46d";
}
.fa-clipboard-list-check:before {
  content: "\f737";
}
.fa-clipboard-prescription:before {
  content: "\f5e8";
}
.fa-clipboard-user:before {
  content: "\f7f3";
}
.fa-clock:before {
  content: "\f017";
}
.fa-clone:before {
  content: "\f24d";
}
.fa-closed-captioning:before {
  content: "\f20a";
}
.fa-cloud:before {
  content: "\f0c2";
}
.fa-cloud-download:before {
  content: "\f0ed";
}
.fa-cloud-download-alt:before {
  content: "\f381";
}
.fa-cloud-drizzle:before {
  content: "\f738";
}
.fa-cloud-hail:before {
  content: "\f739";
}
.fa-cloud-hail-mixed:before {
  content: "\f73a";
}
.fa-cloud-meatball:before {
  content: "\f73b";
}
.fa-cloud-moon:before {
  content: "\f6c3";
}
.fa-cloud-moon-rain:before {
  content: "\f73c";
}
.fa-cloud-rain:before {
  content: "\f73d";
}
.fa-cloud-rainbow:before {
  content: "\f73e";
}
.fa-cloud-showers:before {
  content: "\f73f";
}
.fa-cloud-showers-heavy:before {
  content: "\f740";
}
.fa-cloud-sleet:before {
  content: "\f741";
}
.fa-cloud-snow:before {
  content: "\f742";
}
.fa-cloud-sun:before {
  content: "\f6c4";
}
.fa-cloud-sun-rain:before {
  content: "\f743";
}
.fa-cloud-upload:before {
  content: "\f0ee";
}
.fa-cloud-upload-alt:before {
  content: "\f382";
}
.fa-clouds:before {
  content: "\f744";
}
.fa-clouds-moon:before {
  content: "\f745";
}
.fa-clouds-sun:before {
  content: "\f746";
}
.fa-cloudscale:before {
  content: "\f383";
}
.fa-cloudsmith:before {
  content: "\f384";
}
.fa-cloudversify:before {
  content: "\f385";
}
.fa-club:before {
  content: "\f327";
}
.fa-cocktail:before {
  content: "\f561";
}
.fa-code:before {
  content: "\f121";
}
.fa-code-branch:before {
  content: "\f126";
}
.fa-code-commit:before {
  content: "\f386";
}
.fa-code-merge:before {
  content: "\f387";
}
.fa-codepen:before {
  content: "\f1cb";
}
.fa-codiepie:before {
  content: "\f284";
}
.fa-coffee:before {
  content: "\f0f4";
}
.fa-coffee-togo:before {
  content: "\f6c5";
}
.fa-coffin:before {
  content: "\f6c6";
}
.fa-cog:before {
  content: "\f013";
}
.fa-cogs:before {
  content: "\f085";
}
.fa-coins:before {
  content: "\f51e";
}
.fa-columns:before {
  content: "\f0db";
}
.fa-comment:before {
  content: "\f075";
}
.fa-comment-alt:before {
  content: "\f27a";
}
.fa-comment-alt-check:before {
  content: "\f4a2";
}
.fa-comment-alt-dollar:before {
  content: "\f650";
}
.fa-comment-alt-dots:before {
  content: "\f4a3";
}
.fa-comment-alt-edit:before {
  content: "\f4a4";
}
.fa-comment-alt-exclamation:before {
  content: "\f4a5";
}
.fa-comment-alt-lines:before {
  content: "\f4a6";
}
.fa-comment-alt-medical:before {
  content: "\f7f4";
}
.fa-comment-alt-minus:before {
  content: "\f4a7";
}
.fa-comment-alt-plus:before {
  content: "\f4a8";
}
.fa-comment-alt-slash:before {
  content: "\f4a9";
}
.fa-comment-alt-smile:before {
  content: "\f4aa";
}
.fa-comment-alt-times:before {
  content: "\f4ab";
}
.fa-comment-check:before {
  content: "\f4ac";
}
.fa-comment-dollar:before {
  content: "\f651";
}
.fa-comment-dots:before {
  content: "\f4ad";
}
.fa-comment-edit:before {
  content: "\f4ae";
}
.fa-comment-exclamation:before {
  content: "\f4af";
}
.fa-comment-lines:before {
  content: "\f4b0";
}
.fa-comment-medical:before {
  content: "\f7f5";
}
.fa-comment-minus:before {
  content: "\f4b1";
}
.fa-comment-plus:before {
  content: "\f4b2";
}
.fa-comment-slash:before {
  content: "\f4b3";
}
.fa-comment-smile:before {
  content: "\f4b4";
}
.fa-comment-times:before {
  content: "\f4b5";
}
.fa-comments:before {
  content: "\f086";
}
.fa-comments-alt:before {
  content: "\f4b6";
}
.fa-comments-alt-dollar:before {
  content: "\f652";
}
.fa-comments-dollar:before {
  content: "\f653";
}
.fa-compact-disc:before {
  content: "\f51f";
}
.fa-compass:before {
  content: "\f14e";
}
.fa-compass-slash:before {
  content: "\f5e9";
}
.fa-compress:before {
  content: "\f066";
}
.fa-compress-alt:before {
  content: "\f422";
}
.fa-compress-arrows-alt:before {
  content: "\f78c";
}
.fa-compress-wide:before {
  content: "\f326";
}
.fa-concierge-bell:before {
  content: "\f562";
}
.fa-confluence:before {
  content: "\f78d";
}
.fa-connectdevelop:before {
  content: "\f20e";
}
.fa-container-storage:before {
  content: "\f4b7";
}
.fa-contao:before {
  content: "\f26d";
}
.fa-conveyor-belt:before {
  content: "\f46e";
}
.fa-conveyor-belt-alt:before {
  content: "\f46f";
}
.fa-cookie:before {
  content: "\f563";
}
.fa-cookie-bite:before {
  content: "\f564";
}
.fa-copy:before {
  content: "\f0c5";
}
.fa-copyright:before {
  content: "\f1f9";
}
.fa-corn:before {
  content: "\f6c7";
}
.fa-couch:before {
  content: "\f4b8";
}
.fa-cow:before {
  content: "\f6c8";
}
.fa-cpanel:before {
  content: "\f388";
}
.fa-creative-commons:before {
  content: "\f25e";
}
.fa-creative-commons-by:before {
  content: "\f4e7";
}
.fa-creative-commons-nc:before {
  content: "\f4e8";
}
.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}
.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}
.fa-creative-commons-nd:before {
  content: "\f4eb";
}
.fa-creative-commons-pd:before {
  content: "\f4ec";
}
.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}
.fa-creative-commons-remix:before {
  content: "\f4ee";
}
.fa-creative-commons-sa:before {
  content: "\f4ef";
}
.fa-creative-commons-sampling:before {
  content: "\f4f0";
}
.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}
.fa-creative-commons-share:before {
  content: "\f4f2";
}
.fa-creative-commons-zero:before {
  content: "\f4f3";
}
.fa-credit-card:before {
  content: "\f09d";
}
.fa-credit-card-blank:before {
  content: "\f389";
}
.fa-credit-card-front:before {
  content: "\f38a";
}
.fa-cricket:before {
  content: "\f449";
}
.fa-critical-role:before {
  content: "\f6c9";
}
.fa-croissant:before {
  content: "\f7f6";
}
.fa-crop:before {
  content: "\f125";
}
.fa-crop-alt:before {
  content: "\f565";
}
.fa-cross:before {
  content: "\f654";
}
.fa-crosshairs:before {
  content: "\f05b";
}
.fa-crow:before {
  content: "\f520";
}
.fa-crown:before {
  content: "\f521";
}
.fa-crutch:before {
  content: "\f7f7";
}
.fa-crutches:before {
  content: "\f7f8";
}
.fa-css3:before {
  content: "\f13c";
}
.fa-css3-alt:before {
  content: "\f38b";
}
.fa-cube:before {
  content: "\f1b2";
}
.fa-cubes:before {
  content: "\f1b3";
}
.fa-curling:before {
  content: "\f44a";
}
.fa-cut:before {
  content: "\f0c4";
}
.fa-cuttlefish:before {
  content: "\f38c";
}
.fa-d-and-d:before {
  content: "\f38d";
}
.fa-d-and-d-beyond:before {
  content: "\f6ca";
}
.fa-dagger:before {
  content: "\f6cb";
}
.fa-dashcube:before {
  content: "\f210";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-deaf:before {
  content: "\f2a4";
}
.fa-debug:before {
  content: "\f7f9";
}
.fa-deer:before {
  content: "\f78e";
}
.fa-deer-rudolph:before {
  content: "\f78f";
}
.fa-delicious:before {
  content: "\f1a5";
}
.fa-democrat:before {
  content: "\f747";
}
.fa-deploydog:before {
  content: "\f38e";
}
.fa-deskpro:before {
  content: "\f38f";
}
.fa-desktop:before {
  content: "\f108";
}
.fa-desktop-alt:before {
  content: "\f390";
}
.fa-dev:before {
  content: "\f6cc";
}
.fa-deviantart:before {
  content: "\f1bd";
}
.fa-dewpoint:before {
  content: "\f748";
}
.fa-dharmachakra:before {
  content: "\f655";
}
.fa-dhl:before {
  content: "\f790";
}
.fa-diagnoses:before {
  content: "\f470";
}
.fa-diamond:before {
  content: "\f219";
}
.fa-diaspora:before {
  content: "\f791";
}
.fa-dice:before {
  content: "\f522";
}
.fa-dice-d10:before {
  content: "\f6cd";
}
.fa-dice-d12:before {
  content: "\f6ce";
}
.fa-dice-d20:before {
  content: "\f6cf";
}
.fa-dice-d4:before {
  content: "\f6d0";
}
.fa-dice-d6:before {
  content: "\f6d1";
}
.fa-dice-d8:before {
  content: "\f6d2";
}
.fa-dice-five:before {
  content: "\f523";
}
.fa-dice-four:before {
  content: "\f524";
}
.fa-dice-one:before {
  content: "\f525";
}
.fa-dice-six:before {
  content: "\f526";
}
.fa-dice-three:before {
  content: "\f527";
}
.fa-dice-two:before {
  content: "\f528";
}
.fa-digg:before {
  content: "\f1a6";
}
.fa-digital-ocean:before {
  content: "\f391";
}
.fa-digital-tachograph:before {
  content: "\f566";
}
.fa-diploma:before {
  content: "\f5ea";
}
.fa-directions:before {
  content: "\f5eb";
}
.fa-discord:before {
  content: "\f392";
}
.fa-discourse:before {
  content: "\f393";
}
.fa-disease:before {
  content: "\f7fa";
}
.fa-divide:before {
  content: "\f529";
}
.fa-dizzy:before {
  content: "\f567";
}
.fa-dna:before {
  content: "\f471";
}
.fa-do-not-enter:before {
  content: "\f5ec";
}
.fa-dochub:before {
  content: "\f394";
}
.fa-docker:before {
  content: "\f395";
}
.fa-dog:before {
  content: "\f6d3";
}
.fa-dog-leashed:before {
  content: "\f6d4";
}
.fa-dollar-sign:before {
  content: "\f155";
}
.fa-dolly:before {
  content: "\f472";
}
.fa-dolly-empty:before {
  content: "\f473";
}
.fa-dolly-flatbed:before {
  content: "\f474";
}
.fa-dolly-flatbed-alt:before {
  content: "\f475";
}
.fa-dolly-flatbed-empty:before {
  content: "\f476";
}
.fa-donate:before {
  content: "\f4b9";
}
.fa-door-closed:before {
  content: "\f52a";
}
.fa-door-open:before {
  content: "\f52b";
}
.fa-dot-circle:before {
  content: "\f192";
}
.fa-dove:before {
  content: "\f4ba";
}
.fa-download:before {
  content: "\f019";
}
.fa-draft2digital:before {
  content: "\f396";
}
.fa-drafting-compass:before {
  content: "\f568";
}
.fa-dragon:before {
  content: "\f6d5";
}
.fa-draw-circle:before {
  content: "\f5ed";
}
.fa-draw-polygon:before {
  content: "\f5ee";
}
.fa-draw-square:before {
  content: "\f5ef";
}
.fa-dreidel:before {
  content: "\f792";
}
.fa-dribbble:before {
  content: "\f17d";
}
.fa-dribbble-square:before {
  content: "\f397";
}
.fa-dropbox:before {
  content: "\f16b";
}
.fa-drum:before {
  content: "\f569";
}
.fa-drum-steelpan:before {
  content: "\f56a";
}
.fa-drumstick:before {
  content: "\f6d6";
}
.fa-drumstick-bite:before {
  content: "\f6d7";
}
.fa-drupal:before {
  content: "\f1a9";
}
.fa-duck:before {
  content: "\f6d8";
}
.fa-dumbbell:before {
  content: "\f44b";
}
.fa-dumpster:before {
  content: "\f793";
}
.fa-dumpster-fire:before {
  content: "\f794";
}
.fa-dungeon:before {
  content: "\f6d9";
}
.fa-dyalog:before {
  content: "\f399";
}
.fa-ear:before {
  content: "\f5f0";
}
.fa-ear-muffs:before {
  content: "\f795";
}
.fa-earlybirds:before {
  content: "\f39a";
}
.fa-ebay:before {
  content: "\f4f4";
}
.fa-eclipse:before {
  content: "\f749";
}
.fa-eclipse-alt:before {
  content: "\f74a";
}
.fa-edge:before {
  content: "\f282";
}
.fa-edit:before {
  content: "\f044";
}
.fa-egg:before {
  content: "\f7fb";
}
.fa-egg-fried:before {
  content: "\f7fc";
}
.fa-eject:before {
  content: "\f052";
}
.fa-elementor:before {
  content: "\f430";
}
.fa-elephant:before {
  content: "\f6da";
}
.fa-ellipsis-h:before {
  content: "\f141";
}
.fa-ellipsis-h-alt:before {
  content: "\f39b";
}
.fa-ellipsis-v:before {
  content: "\f142";
}
.fa-ellipsis-v-alt:before {
  content: "\f39c";
}
.fa-ello:before {
  content: "\f5f1";
}
.fa-ember:before {
  content: "\f423";
}
.fa-empire:before {
  content: "\f1d1";
}
.fa-empty-set:before {
  content: "\f656";
}
.fa-engine-warning:before {
  content: "\f5f2";
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-envelope-open:before {
  content: "\f2b6";
}
.fa-envelope-open-dollar:before {
  content: "\f657";
}
.fa-envelope-open-text:before {
  content: "\f658";
}
.fa-envelope-square:before {
  content: "\f199";
}
.fa-envira:before {
  content: "\f299";
}
.fa-equals:before {
  content: "\f52c";
}
.fa-eraser:before {
  content: "\f12d";
}
.fa-erlang:before {
  content: "\f39d";
}
.fa-ethereum:before {
  content: "\f42e";
}
.fa-ethernet:before {
  content: "\f796";
}
.fa-etsy:before {
  content: "\f2d7";
}
.fa-euro-sign:before {
  content: "\f153";
}
.fa-evernote:before {
  content: "\f839";
}
.fa-exchange:before {
  content: "\f0ec";
}
.fa-exchange-alt:before {
  content: "\f362";
}
.fa-exclamation:before {
  content: "\f12a";
}
.fa-exclamation-circle:before {
  content: "\f06a";
}
.fa-exclamation-square:before {
  content: "\f321";
}
.fa-exclamation-triangle:before {
  content: "\f071";
}
.fa-expand:before {
  content: "\f065";
}
.fa-expand-alt:before {
  content: "\f424";
}
.fa-expand-arrows:before {
  content: "\f31d";
}
.fa-expand-arrows-alt:before {
  content: "\f31e";
}
.fa-expand-wide:before {
  content: "\f320";
}
.fa-expeditedssl:before {
  content: "\f23e";
}
.fa-external-link:before {
  content: "\f08e";
}
.fa-external-link-alt:before {
  content: "\f35d";
}
.fa-external-link-square:before {
  content: "\f14c";
}
.fa-external-link-square-alt:before {
  content: "\f360";
}
.fa-eye:before {
  content: "\f06e";
}
.fa-eye-dropper:before {
  content: "\f1fb";
}
.fa-eye-evil:before {
  content: "\f6db";
}
.fa-eye-slash:before {
  content: "\f070";
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-facebook-messenger:before {
  content: "\f39f";
}
.fa-facebook-square:before {
  content: "\f082";
}
.fa-fantasy-flight-games:before {
  content: "\f6dc";
}
.fa-fast-backward:before {
  content: "\f049";
}
.fa-fast-forward:before {
  content: "\f050";
}
.fa-fax:before {
  content: "\f1ac";
}
.fa-feather:before {
  content: "\f52d";
}
.fa-feather-alt:before {
  content: "\f56b";
}
.fa-fedex:before {
  content: "\f797";
}
.fa-fedora:before {
  content: "\f798";
}
.fa-female:before {
  content: "\f182";
}
.fa-field-hockey:before {
  content: "\f44c";
}
.fa-fighter-jet:before {
  content: "\f0fb";
}
.fa-figma:before {
  content: "\f799";
}
.fa-file:before {
  content: "\f15b";
}
.fa-file-alt:before {
  content: "\f15c";
}
.fa-file-archive:before {
  content: "\f1c6";
}
.fa-file-audio:before {
  content: "\f1c7";
}
.fa-file-certificate:before {
  content: "\f5f3";
}
.fa-file-chart-line:before {
  content: "\f659";
}
.fa-file-chart-pie:before {
  content: "\f65a";
}
.fa-file-check:before {
  content: "\f316";
}
.fa-file-code:before {
  content: "\f1c9";
}
.fa-file-contract:before {
  content: "\f56c";
}
.fa-file-csv:before {
  content: "\f6dd";
}
.fa-file-download:before {
  content: "\f56d";
}
.fa-file-edit:before {
  content: "\f31c";
}
.fa-file-excel:before {
  content: "\f1c3";
}
.fa-file-exclamation:before {
  content: "\f31a";
}
.fa-file-export:before {
  content: "\f56e";
}
.fa-file-image:before {
  content: "\f1c5";
}
.fa-file-import:before {
  content: "\f56f";
}
.fa-file-invoice:before {
  content: "\f570";
}
.fa-file-invoice-dollar:before {
  content: "\f571";
}
.fa-file-medical:before {
  content: "\f477";
}
.fa-file-medical-alt:before {
  content: "\f478";
}
.fa-file-minus:before {
  content: "\f318";
}
.fa-file-pdf:before {
  content: "\f1c1";
}
.fa-file-plus:before {
  content: "\f319";
}
.fa-file-powerpoint:before {
  content: "\f1c4";
}
.fa-file-prescription:before {
  content: "\f572";
}
.fa-file-signature:before {
  content: "\f573";
}
.fa-file-spreadsheet:before {
  content: "\f65b";
}
.fa-file-times:before {
  content: "\f317";
}
.fa-file-upload:before {
  content: "\f574";
}
.fa-file-user:before {
  content: "\f65c";
}
.fa-file-video:before {
  content: "\f1c8";
}
.fa-file-word:before {
  content: "\f1c2";
}
.fa-files-medical:before {
  content: "\f7fd";
}
.fa-fill:before {
  content: "\f575";
}
.fa-fill-drip:before {
  content: "\f576";
}
.fa-film:before {
  content: "\f008";
}
.fa-film-alt:before {
  content: "\f3a0";
}
.fa-filter:before {
  content: "\f0b0";
}
.fa-fingerprint:before {
  content: "\f577";
}
.fa-fire:before {
  content: "\f06d";
}
.fa-fire-alt:before {
  content: "\f7e4";
}
.fa-fire-extinguisher:before {
  content: "\f134";
}
.fa-fire-smoke:before {
  content: "\f74b";
}
.fa-firefox:before {
  content: "\f269";
}
.fa-fireplace:before {
  content: "\f79a";
}
.fa-first-aid:before {
  content: "\f479";
}
.fa-first-order:before {
  content: "\f2b0";
}
.fa-first-order-alt:before {
  content: "\f50a";
}
.fa-firstdraft:before {
  content: "\f3a1";
}
.fa-fish:before {
  content: "\f578";
}
.fa-fish-cooked:before {
  content: "\f7fe";
}
.fa-fist-raised:before {
  content: "\f6de";
}
.fa-flag:before {
  content: "\f024";
}
.fa-flag-alt:before {
  content: "\f74c";
}
.fa-flag-checkered:before {
  content: "\f11e";
}
.fa-flag-usa:before {
  content: "\f74d";
}
.fa-flame:before {
  content: "\f6df";
}
.fa-flask:before {
  content: "\f0c3";
}
.fa-flask-poison:before {
  content: "\f6e0";
}
.fa-flask-potion:before {
  content: "\f6e1";
}
.fa-flickr:before {
  content: "\f16e";
}
.fa-flipboard:before {
  content: "\f44d";
}
.fa-flower:before {
  content: "\f7ff";
}
.fa-flower-daffodil:before {
  content: "\f800";
}
.fa-flower-tulip:before {
  content: "\f801";
}
.fa-flushed:before {
  content: "\f579";
}
.fa-fly:before {
  content: "\f417";
}
.fa-fog:before {
  content: "\f74e";
}
.fa-folder:before {
  content: "\f07b";
}
.fa-folder-minus:before {
  content: "\f65d";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-folder-plus:before {
  content: "\f65e";
}
.fa-folder-times:before {
  content: "\f65f";
}
.fa-folder-tree:before {
  content: "\f802";
}
.fa-folders:before {
  content: "\f660";
}
.fa-font:before {
  content: "\f031";
}
.fa-font-awesome:before {
  content: "\f2b4";
}
.fa-font-awesome-alt:before {
  content: "\f35c";
}
.fa-font-awesome-flag:before {
  content: "\f425";
}
.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}
.fa-fonticons:before {
  content: "\f280";
}
.fa-fonticons-fi:before {
  content: "\f3a2";
}
.fa-football-ball:before {
  content: "\f44e";
}
.fa-football-helmet:before {
  content: "\f44f";
}
.fa-forklift:before {
  content: "\f47a";
}
.fa-fort-awesome:before {
  content: "\f286";
}
.fa-fort-awesome-alt:before {
  content: "\f3a3";
}
.fa-forumbee:before {
  content: "\f211";
}
.fa-forward:before {
  content: "\f04e";
}
.fa-foursquare:before {
  content: "\f180";
}
.fa-fragile:before {
  content: "\f4bb";
}
.fa-free-code-camp:before {
  content: "\f2c5";
}
.fa-freebsd:before {
  content: "\f3a4";
}
.fa-french-fries:before {
  content: "\f803";
}
.fa-frog:before {
  content: "\f52e";
}
.fa-frosty-head:before {
  content: "\f79b";
}
.fa-frown:before {
  content: "\f119";
}
.fa-frown-open:before {
  content: "\f57a";
}
.fa-fulcrum:before {
  content: "\f50b";
}
.fa-function:before {
  content: "\f661";
}
.fa-funnel-dollar:before {
  content: "\f662";
}
.fa-futbol:before {
  content: "\f1e3";
}
.fa-galactic-republic:before {
  content: "\f50c";
}
.fa-galactic-senate:before {
  content: "\f50d";
}
.fa-gamepad:before {
  content: "\f11b";
}
.fa-gas-pump:before {
  content: "\f52f";
}
.fa-gas-pump-slash:before {
  content: "\f5f4";
}
.fa-gavel:before {
  content: "\f0e3";
}
.fa-gem:before {
  content: "\f3a5";
}
.fa-genderless:before {
  content: "\f22d";
}
.fa-get-pocket:before {
  content: "\f265";
}
.fa-gg:before {
  content: "\f260";
}
.fa-gg-circle:before {
  content: "\f261";
}
.fa-ghost:before {
  content: "\f6e2";
}
.fa-gift:before {
  content: "\f06b";
}
.fa-gift-card:before {
  content: "\f663";
}
.fa-gifts:before {
  content: "\f79c";
}
.fa-gingerbread-man:before {
  content: "\f79d";
}
.fa-git:before {
  content: "\f1d3";
}
.fa-git-square:before {
  content: "\f1d2";
}
.fa-github:before {
  content: "\f09b";
}
.fa-github-alt:before {
  content: "\f113";
}
.fa-github-square:before {
  content: "\f092";
}
.fa-gitkraken:before {
  content: "\f3a6";
}
.fa-gitlab:before {
  content: "\f296";
}
.fa-gitter:before {
  content: "\f426";
}
.fa-glass:before {
  content: "\f804";
}
.fa-glass-champagne:before {
  content: "\f79e";
}
.fa-glass-cheers:before {
  content: "\f79f";
}
.fa-glass-martini:before {
  content: "\f000";
}
.fa-glass-martini-alt:before {
  content: "\f57b";
}
.fa-glass-whiskey:before {
  content: "\f7a0";
}
.fa-glass-whiskey-rocks:before {
  content: "\f7a1";
}
.fa-glasses:before {
  content: "\f530";
}
.fa-glasses-alt:before {
  content: "\f5f5";
}
.fa-glide:before {
  content: "\f2a5";
}
.fa-glide-g:before {
  content: "\f2a6";
}
.fa-globe:before {
  content: "\f0ac";
}
.fa-globe-africa:before {
  content: "\f57c";
}
.fa-globe-americas:before {
  content: "\f57d";
}
.fa-globe-asia:before {
  content: "\f57e";
}
.fa-globe-europe:before {
  content: "\f7a2";
}
.fa-globe-snow:before {
  content: "\f7a3";
}
.fa-globe-stand:before {
  content: "\f5f6";
}
.fa-gofore:before {
  content: "\f3a7";
}
.fa-golf-ball:before {
  content: "\f450";
}
.fa-golf-club:before {
  content: "\f451";
}
.fa-goodreads:before {
  content: "\f3a8";
}
.fa-goodreads-g:before {
  content: "\f3a9";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-google-drive:before {
  content: "\f3aa";
}
.fa-google-play:before {
  content: "\f3ab";
}
.fa-google-plus:before {
  content: "\f2b3";
}
.fa-google-plus-g:before {
  content: "\f0d5";
}
.fa-google-plus-square:before {
  content: "\f0d4";
}
.fa-google-wallet:before {
  content: "\f1ee";
}
.fa-gopuram:before {
  content: "\f664";
}
.fa-graduation-cap:before {
  content: "\f19d";
}
.fa-gratipay:before {
  content: "\f184";
}
.fa-grav:before {
  content: "\f2d6";
}
.fa-greater-than:before {
  content: "\f531";
}
.fa-greater-than-equal:before {
  content: "\f532";
}
.fa-grimace:before {
  content: "\f57f";
}
.fa-grin:before {
  content: "\f580";
}
.fa-grin-alt:before {
  content: "\f581";
}
.fa-grin-beam:before {
  content: "\f582";
}
.fa-grin-beam-sweat:before {
  content: "\f583";
}
.fa-grin-hearts:before {
  content: "\f584";
}
.fa-grin-squint:before {
  content: "\f585";
}
.fa-grin-squint-tears:before {
  content: "\f586";
}
.fa-grin-stars:before {
  content: "\f587";
}
.fa-grin-tears:before {
  content: "\f588";
}
.fa-grin-tongue:before {
  content: "\f589";
}
.fa-grin-tongue-squint:before {
  content: "\f58a";
}
.fa-grin-tongue-wink:before {
  content: "\f58b";
}
.fa-grin-wink:before {
  content: "\f58c";
}
.fa-grip-horizontal:before {
  content: "\f58d";
}
.fa-grip-lines:before {
  content: "\f7a4";
}
.fa-grip-lines-vertical:before {
  content: "\f7a5";
}
.fa-grip-vertical:before {
  content: "\f58e";
}
.fa-gripfire:before {
  content: "\f3ac";
}
.fa-grunt:before {
  content: "\f3ad";
}
.fa-guitar:before {
  content: "\f7a6";
}
.fa-gulp:before {
  content: "\f3ae";
}
.fa-h-square:before {
  content: "\f0fd";
}
.fa-h1:before {
  content: "\f313";
}
.fa-h2:before {
  content: "\f314";
}
.fa-h3:before {
  content: "\f315";
}
.fa-hacker-news:before {
  content: "\f1d4";
}
.fa-hacker-news-square:before {
  content: "\f3af";
}
.fa-hackerrank:before {
  content: "\f5f7";
}
.fa-hamburger:before {
  content: "\f805";
}
.fa-hammer:before {
  content: "\f6e3";
}
.fa-hammer-war:before {
  content: "\f6e4";
}
.fa-hamsa:before {
  content: "\f665";
}
.fa-hand-heart:before {
  content: "\f4bc";
}
.fa-hand-holding:before {
  content: "\f4bd";
}
.fa-hand-holding-box:before {
  content: "\f47b";
}
.fa-hand-holding-heart:before {
  content: "\f4be";
}
.fa-hand-holding-magic:before {
  content: "\f6e5";
}
.fa-hand-holding-seedling:before {
  content: "\f4bf";
}
.fa-hand-holding-usd:before {
  content: "\f4c0";
}
.fa-hand-holding-water:before {
  content: "\f4c1";
}
.fa-hand-lizard:before {
  content: "\f258";
}
.fa-hand-middle-finger:before {
  content: "\f806";
}
.fa-hand-paper:before {
  content: "\f256";
}
.fa-hand-peace:before {
  content: "\f25b";
}
.fa-hand-point-down:before {
  content: "\f0a7";
}
.fa-hand-point-left:before {
  content: "\f0a5";
}
.fa-hand-point-right:before {
  content: "\f0a4";
}
.fa-hand-point-up:before {
  content: "\f0a6";
}
.fa-hand-pointer:before {
  content: "\f25a";
}
.fa-hand-receiving:before {
  content: "\f47c";
}
.fa-hand-rock:before {
  content: "\f255";
}
.fa-hand-scissors:before {
  content: "\f257";
}
.fa-hand-spock:before {
  content: "\f259";
}
.fa-hands:before {
  content: "\f4c2";
}
.fa-hands-heart:before {
  content: "\f4c3";
}
.fa-hands-helping:before {
  content: "\f4c4";
}
.fa-hands-usd:before {
  content: "\f4c5";
}
.fa-handshake:before {
  content: "\f2b5";
}
.fa-handshake-alt:before {
  content: "\f4c6";
}
.fa-hanukiah:before {
  content: "\f6e6";
}
.fa-hard-hat:before {
  content: "\f807";
}
.fa-hashtag:before {
  content: "\f292";
}
.fa-hat-santa:before {
  content: "\f7a7";
}
.fa-hat-winter:before {
  content: "\f7a8";
}
.fa-hat-witch:before {
  content: "\f6e7";
}
.fa-hat-wizard:before {
  content: "\f6e8";
}
.fa-haykal:before {
  content: "\f666";
}
.fa-hdd:before {
  content: "\f0a0";
}
.fa-head-side:before {
  content: "\f6e9";
}
.fa-head-side-brain:before {
  content: "\f808";
}
.fa-head-side-medical:before {
  content: "\f809";
}
.fa-head-vr:before {
  content: "\f6ea";
}
.fa-heading:before {
  content: "\f1dc";
}
.fa-headphones:before {
  content: "\f025";
}
.fa-headphones-alt:before {
  content: "\f58f";
}
.fa-headset:before {
  content: "\f590";
}
.fa-heart:before {
  content: "\f004";
}
.fa-heart-broken:before {
  content: "\f7a9";
}
.fa-heart-circle:before {
  content: "\f4c7";
}
.fa-heart-rate:before {
  content: "\f5f8";
}
.fa-heart-square:before {
  content: "\f4c8";
}
.fa-heartbeat:before {
  content: "\f21e";
}
.fa-helicopter:before {
  content: "\f533";
}
.fa-helmet-battle:before {
  content: "\f6eb";
}
.fa-hexagon:before {
  content: "\f312";
}
.fa-highlighter:before {
  content: "\f591";
}
.fa-hiking:before {
  content: "\f6ec";
}
.fa-hippo:before {
  content: "\f6ed";
}
.fa-hips:before {
  content: "\f452";
}
.fa-hire-a-helper:before {
  content: "\f3b0";
}
.fa-history:before {
  content: "\f1da";
}
.fa-hockey-mask:before {
  content: "\f6ee";
}
.fa-hockey-puck:before {
  content: "\f453";
}
.fa-hockey-sticks:before {
  content: "\f454";
}
.fa-holly-berry:before {
  content: "\f7aa";
}
.fa-home:before {
  content: "\f015";
}
.fa-home-alt:before {
  content: "\f80a";
}
.fa-home-heart:before {
  content: "\f4c9";
}
.fa-home-lg:before {
  content: "\f80b";
}
.fa-home-lg-alt:before {
  content: "\f80c";
}
.fa-hood-cloak:before {
  content: "\f6ef";
}
.fa-hooli:before {
  content: "\f427";
}
.fa-hornbill:before {
  content: "\f592";
}
.fa-horse:before {
  content: "\f6f0";
}
.fa-horse-head:before {
  content: "\f7ab";
}
.fa-hospital:before {
  content: "\f0f8";
}
.fa-hospital-alt:before {
  content: "\f47d";
}
.fa-hospital-symbol:before {
  content: "\f47e";
}
.fa-hospital-user:before {
  content: "\f80d";
}
.fa-hospitals:before {
  content: "\f80e";
}
.fa-hot-tub:before {
  content: "\f593";
}
.fa-hotdog:before {
  content: "\f80f";
}
.fa-hotel:before {
  content: "\f594";
}
.fa-hotjar:before {
  content: "\f3b1";
}
.fa-hourglass:before {
  content: "\f254";
}
.fa-hourglass-end:before {
  content: "\f253";
}
.fa-hourglass-half:before {
  content: "\f252";
}
.fa-hourglass-start:before {
  content: "\f251";
}
.fa-house-damage:before {
  content: "\f6f1";
}
.fa-house-flood:before {
  content: "\f74f";
}
.fa-houzz:before {
  content: "\f27c";
}
.fa-hryvnia:before {
  content: "\f6f2";
}
.fa-html5:before {
  content: "\f13b";
}
.fa-hubspot:before {
  content: "\f3b2";
}
.fa-humidity:before {
  content: "\f750";
}
.fa-hurricane:before {
  content: "\f751";
}
.fa-i-cursor:before {
  content: "\f246";
}
.fa-ice-cream:before {
  content: "\f810";
}
.fa-ice-skate:before {
  content: "\f7ac";
}
.fa-icicles:before {
  content: "\f7ad";
}
.fa-id-badge:before {
  content: "\f2c1";
}
.fa-id-card:before {
  content: "\f2c2";
}
.fa-id-card-alt:before {
  content: "\f47f";
}
.fa-igloo:before {
  content: "\f7ae";
}
.fa-image:before {
  content: "\f03e";
}
.fa-images:before {
  content: "\f302";
}
.fa-imdb:before {
  content: "\f2d8";
}
.fa-inbox:before {
  content: "\f01c";
}
.fa-inbox-in:before {
  content: "\f310";
}
.fa-inbox-out:before {
  content: "\f311";
}
.fa-indent:before {
  content: "\f03c";
}
.fa-industry:before {
  content: "\f275";
}
.fa-industry-alt:before {
  content: "\f3b3";
}
.fa-infinity:before {
  content: "\f534";
}
.fa-info:before {
  content: "\f129";
}
.fa-info-circle:before {
  content: "\f05a";
}
.fa-info-square:before {
  content: "\f30f";
}
.fa-inhaler:before {
  content: "\f5f9";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-integral:before {
  content: "\f667";
}
.fa-intercom:before {
  content: "\f7af";
}
.fa-internet-explorer:before {
  content: "\f26b";
}
.fa-intersection:before {
  content: "\f668";
}
.fa-inventory:before {
  content: "\f480";
}
.fa-invision:before {
  content: "\f7b0";
}
.fa-ioxhost:before {
  content: "\f208";
}
.fa-island-tropical:before {
  content: "\f811";
}
.fa-italic:before {
  content: "\f033";
}
.fa-itch-io:before {
  content: "\f83a";
}
.fa-itunes:before {
  content: "\f3b4";
}
.fa-itunes-note:before {
  content: "\f3b5";
}
.fa-jack-o-lantern:before {
  content: "\f30e";
}
.fa-java:before {
  content: "\f4e4";
}
.fa-jedi:before {
  content: "\f669";
}
.fa-jedi-order:before {
  content: "\f50e";
}
.fa-jenkins:before {
  content: "\f3b6";
}
.fa-jira:before {
  content: "\f7b1";
}
.fa-joget:before {
  content: "\f3b7";
}
.fa-joint:before {
  content: "\f595";
}
.fa-joomla:before {
  content: "\f1aa";
}
.fa-journal-whills:before {
  content: "\f66a";
}
.fa-js:before {
  content: "\f3b8";
}
.fa-js-square:before {
  content: "\f3b9";
}
.fa-jsfiddle:before {
  content: "\f1cc";
}
.fa-kaaba:before {
  content: "\f66b";
}
.fa-kaggle:before {
  content: "\f5fa";
}
.fa-key:before {
  content: "\f084";
}
.fa-key-skeleton:before {
  content: "\f6f3";
}
.fa-keybase:before {
  content: "\f4f5";
}
.fa-keyboard:before {
  content: "\f11c";
}
.fa-keycdn:before {
  content: "\f3ba";
}
.fa-keynote:before {
  content: "\f66c";
}
.fa-khanda:before {
  content: "\f66d";
}
.fa-kickstarter:before {
  content: "\f3bb";
}
.fa-kickstarter-k:before {
  content: "\f3bc";
}
.fa-kidneys:before {
  content: "\f5fb";
}
.fa-kiss:before {
  content: "\f596";
}
.fa-kiss-beam:before {
  content: "\f597";
}
.fa-kiss-wink-heart:before {
  content: "\f598";
}
.fa-kite:before {
  content: "\f6f4";
}
.fa-kiwi-bird:before {
  content: "\f535";
}
.fa-knife-kitchen:before {
  content: "\f6f5";
}
.fa-korvue:before {
  content: "\f42f";
}
.fa-lambda:before {
  content: "\f66e";
}
.fa-lamp:before {
  content: "\f4ca";
}
.fa-landmark:before {
  content: "\f66f";
}
.fa-landmark-alt:before {
  content: "\f752";
}
.fa-language:before {
  content: "\f1ab";
}
.fa-laptop:before {
  content: "\f109";
}
.fa-laptop-code:before {
  content: "\f5fc";
}
.fa-laptop-medical:before {
  content: "\f812";
}
.fa-laravel:before {
  content: "\f3bd";
}
.fa-lastfm:before {
  content: "\f202";
}
.fa-lastfm-square:before {
  content: "\f203";
}
.fa-laugh:before {
  content: "\f599";
}
.fa-laugh-beam:before {
  content: "\f59a";
}
.fa-laugh-squint:before {
  content: "\f59b";
}
.fa-laugh-wink:before {
  content: "\f59c";
}
.fa-layer-group:before {
  content: "\f5fd";
}
.fa-layer-minus:before {
  content: "\f5fe";
}
.fa-layer-plus:before {
  content: "\f5ff";
}
.fa-leaf:before {
  content: "\f06c";
}
.fa-leaf-heart:before {
  content: "\f4cb";
}
.fa-leaf-maple:before {
  content: "\f6f6";
}
.fa-leaf-oak:before {
  content: "\f6f7";
}
.fa-leanpub:before {
  content: "\f212";
}
.fa-lemon:before {
  content: "\f094";
}
.fa-less:before {
  content: "\f41d";
}
.fa-less-than:before {
  content: "\f536";
}
.fa-less-than-equal:before {
  content: "\f537";
}
.fa-level-down:before {
  content: "\f149";
}
.fa-level-down-alt:before {
  content: "\f3be";
}
.fa-level-up:before {
  content: "\f148";
}
.fa-level-up-alt:before {
  content: "\f3bf";
}
.fa-life-ring:before {
  content: "\f1cd";
}
.fa-lightbulb:before {
  content: "\f0eb";
}
.fa-lightbulb-dollar:before {
  content: "\f670";
}
.fa-lightbulb-exclamation:before {
  content: "\f671";
}
.fa-lightbulb-on:before {
  content: "\f672";
}
.fa-lightbulb-slash:before {
  content: "\f673";
}
.fa-lights-holiday:before {
  content: "\f7b2";
}
.fa-line:before {
  content: "\f3c0";
}
.fa-link:before {
  content: "\f0c1";
}
.fa-linkedin:before {
  content: "\f08c";
}
.fa-linkedin-in:before {
  content: "\f0e1";
}
.fa-linode:before {
  content: "\f2b8";
}
.fa-linux:before {
  content: "\f17c";
}
.fa-lips:before {
  content: "\f600";
}
.fa-lira-sign:before {
  content: "\f195";
}
.fa-list:before {
  content: "\f03a";
}
.fa-list-alt:before {
  content: "\f022";
}
.fa-list-ol:before {
  content: "\f0cb";
}
.fa-list-ul:before {
  content: "\f0ca";
}
.fa-location:before {
  content: "\f601";
}
.fa-location-arrow:before {
  content: "\f124";
}
.fa-location-circle:before {
  content: "\f602";
}
.fa-location-slash:before {
  content: "\f603";
}
.fa-lock:before {
  content: "\f023";
}
.fa-lock-alt:before {
  content: "\f30d";
}
.fa-lock-open:before {
  content: "\f3c1";
}
.fa-lock-open-alt:before {
  content: "\f3c2";
}
.fa-long-arrow-alt-down:before {
  content: "\f309";
}
.fa-long-arrow-alt-left:before {
  content: "\f30a";
}
.fa-long-arrow-alt-right:before {
  content: "\f30b";
}
.fa-long-arrow-alt-up:before {
  content: "\f30c";
}
.fa-long-arrow-down:before {
  content: "\f175";
}
.fa-long-arrow-left:before {
  content: "\f177";
}
.fa-long-arrow-right:before {
  content: "\f178";
}
.fa-long-arrow-up:before {
  content: "\f176";
}
.fa-loveseat:before {
  content: "\f4cc";
}
.fa-low-vision:before {
  content: "\f2a8";
}
.fa-luchador:before {
  content: "\f455";
}
.fa-luggage-cart:before {
  content: "\f59d";
}
.fa-lungs:before {
  content: "\f604";
}
.fa-lyft:before {
  content: "\f3c3";
}
.fa-mace:before {
  content: "\f6f8";
}
.fa-magento:before {
  content: "\f3c4";
}
.fa-magic:before {
  content: "\f0d0";
}
.fa-magnet:before {
  content: "\f076";
}
.fa-mail-bulk:before {
  content: "\f674";
}
.fa-mailbox:before {
  content: "\f813";
}
.fa-mailchimp:before {
  content: "\f59e";
}
.fa-male:before {
  content: "\f183";
}
.fa-mandalorian:before {
  content: "\f50f";
}
.fa-mandolin:before {
  content: "\f6f9";
}
.fa-map:before {
  content: "\f279";
}
.fa-map-marked:before {
  content: "\f59f";
}
.fa-map-marked-alt:before {
  content: "\f5a0";
}
.fa-map-marker:before {
  content: "\f041";
}
.fa-map-marker-alt:before {
  content: "\f3c5";
}
.fa-map-marker-alt-slash:before {
  content: "\f605";
}
.fa-map-marker-check:before {
  content: "\f606";
}
.fa-map-marker-edit:before {
  content: "\f607";
}
.fa-map-marker-exclamation:before {
  content: "\f608";
}
.fa-map-marker-minus:before {
  content: "\f609";
}
.fa-map-marker-plus:before {
  content: "\f60a";
}
.fa-map-marker-question:before {
  content: "\f60b";
}
.fa-map-marker-slash:before {
  content: "\f60c";
}
.fa-map-marker-smile:before {
  content: "\f60d";
}
.fa-map-marker-times:before {
  content: "\f60e";
}
.fa-map-pin:before {
  content: "\f276";
}
.fa-map-signs:before {
  content: "\f277";
}
.fa-markdown:before {
  content: "\f60f";
}
.fa-marker:before {
  content: "\f5a1";
}
.fa-mars:before {
  content: "\f222";
}
.fa-mars-double:before {
  content: "\f227";
}
.fa-mars-stroke:before {
  content: "\f229";
}
.fa-mars-stroke-h:before {
  content: "\f22b";
}
.fa-mars-stroke-v:before {
  content: "\f22a";
}
.fa-mask:before {
  content: "\f6fa";
}
.fa-mastodon:before {
  content: "\f4f6";
}
.fa-maxcdn:before {
  content: "\f136";
}
.fa-meat:before {
  content: "\f814";
}
.fa-medal:before {
  content: "\f5a2";
}
.fa-medapps:before {
  content: "\f3c6";
}
.fa-medium:before {
  content: "\f23a";
}
.fa-medium-m:before {
  content: "\f3c7";
}
.fa-medkit:before {
  content: "\f0fa";
}
.fa-medrt:before {
  content: "\f3c8";
}
.fa-meetup:before {
  content: "\f2e0";
}
.fa-megaphone:before {
  content: "\f675";
}
.fa-megaport:before {
  content: "\f5a3";
}
.fa-meh:before {
  content: "\f11a";
}
.fa-meh-blank:before {
  content: "\f5a4";
}
.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}
.fa-memory:before {
  content: "\f538";
}
.fa-mendeley:before {
  content: "\f7b3";
}
.fa-menorah:before {
  content: "\f676";
}
.fa-mercury:before {
  content: "\f223";
}
.fa-meteor:before {
  content: "\f753";
}
.fa-microchip:before {
  content: "\f2db";
}
.fa-microphone:before {
  content: "\f130";
}
.fa-microphone-alt:before {
  content: "\f3c9";
}
.fa-microphone-alt-slash:before {
  content: "\f539";
}
.fa-microphone-slash:before {
  content: "\f131";
}
.fa-microscope:before {
  content: "\f610";
}
.fa-microsoft:before {
  content: "\f3ca";
}
.fa-mind-share:before {
  content: "\f677";
}
.fa-minus:before {
  content: "\f068";
}
.fa-minus-circle:before {
  content: "\f056";
}
.fa-minus-hexagon:before {
  content: "\f307";
}
.fa-minus-octagon:before {
  content: "\f308";
}
.fa-minus-square:before {
  content: "\f146";
}
.fa-mistletoe:before {
  content: "\f7b4";
}
.fa-mitten:before {
  content: "\f7b5";
}
.fa-mix:before {
  content: "\f3cb";
}
.fa-mixcloud:before {
  content: "\f289";
}
.fa-mizuni:before {
  content: "\f3cc";
}
.fa-mobile:before {
  content: "\f10b";
}
.fa-mobile-alt:before {
  content: "\f3cd";
}
.fa-mobile-android:before {
  content: "\f3ce";
}
.fa-mobile-android-alt:before {
  content: "\f3cf";
}
.fa-modx:before {
  content: "\f285";
}
.fa-monero:before {
  content: "\f3d0";
}
.fa-money-bill:before {
  content: "\f0d6";
}
.fa-money-bill-alt:before {
  content: "\f3d1";
}
.fa-money-bill-wave:before {
  content: "\f53a";
}
.fa-money-bill-wave-alt:before {
  content: "\f53b";
}
.fa-money-check:before {
  content: "\f53c";
}
.fa-money-check-alt:before {
  content: "\f53d";
}
.fa-monitor-heart-rate:before {
  content: "\f611";
}
.fa-monkey:before {
  content: "\f6fb";
}
.fa-monument:before {
  content: "\f5a6";
}
.fa-moon:before {
  content: "\f186";
}
.fa-moon-cloud:before {
  content: "\f754";
}
.fa-moon-stars:before {
  content: "\f755";
}
.fa-mortar-pestle:before {
  content: "\f5a7";
}
.fa-mosque:before {
  content: "\f678";
}
.fa-motorcycle:before {
  content: "\f21c";
}
.fa-mountain:before {
  content: "\f6fc";
}
.fa-mountains:before {
  content: "\f6fd";
}
.fa-mouse-pointer:before {
  content: "\f245";
}
.fa-mug-hot:before {
  content: "\f7b6";
}
.fa-mug-marshmallows:before {
  content: "\f7b7";
}
.fa-music:before {
  content: "\f001";
}
.fa-napster:before {
  content: "\f3d2";
}
.fa-narwhal:before {
  content: "\f6fe";
}
.fa-neos:before {
  content: "\f612";
}
.fa-network-wired:before {
  content: "\f6ff";
}
.fa-neuter:before {
  content: "\f22c";
}
.fa-newspaper:before {
  content: "\f1ea";
}
.fa-nimblr:before {
  content: "\f5a8";
}
.fa-nintendo-switch:before {
  content: "\f418";
}
.fa-node:before {
  content: "\f419";
}
.fa-node-js:before {
  content: "\f3d3";
}
.fa-not-equal:before {
  content: "\f53e";
}
.fa-notes-medical:before {
  content: "\f481";
}
.fa-npm:before {
  content: "\f3d4";
}
.fa-ns8:before {
  content: "\f3d5";
}
.fa-nutritionix:before {
  content: "\f3d6";
}
.fa-object-group:before {
  content: "\f247";
}
.fa-object-ungroup:before {
  content: "\f248";
}
.fa-octagon:before {
  content: "\f306";
}
.fa-odnoklassniki:before {
  content: "\f263";
}
.fa-odnoklassniki-square:before {
  content: "\f264";
}
.fa-oil-can:before {
  content: "\f613";
}
.fa-oil-temp:before {
  content: "\f614";
}
.fa-old-republic:before {
  content: "\f510";
}
.fa-om:before {
  content: "\f679";
}
.fa-omega:before {
  content: "\f67a";
}
.fa-opencart:before {
  content: "\f23d";
}
.fa-openid:before {
  content: "\f19b";
}
.fa-opera:before {
  content: "\f26a";
}
.fa-optin-monster:before {
  content: "\f23c";
}
.fa-ornament:before {
  content: "\f7b8";
}
.fa-osi:before {
  content: "\f41a";
}
.fa-otter:before {
  content: "\f700";
}
.fa-outdent:before {
  content: "\f03b";
}
.fa-page4:before {
  content: "\f3d7";
}
.fa-pagelines:before {
  content: "\f18c";
}
.fa-pager:before {
  content: "\f815";
}
.fa-paint-brush:before {
  content: "\f1fc";
}
.fa-paint-brush-alt:before {
  content: "\f5a9";
}
.fa-paint-roller:before {
  content: "\f5aa";
}
.fa-palette:before {
  content: "\f53f";
}
.fa-palfed:before {
  content: "\f3d8";
}
.fa-pallet:before {
  content: "\f482";
}
.fa-pallet-alt:before {
  content: "\f483";
}
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-paperclip:before {
  content: "\f0c6";
}
.fa-parachute-box:before {
  content: "\f4cd";
}
.fa-paragraph:before {
  content: "\f1dd";
}
.fa-parking:before {
  content: "\f540";
}
.fa-parking-circle:before {
  content: "\f615";
}
.fa-parking-circle-slash:before {
  content: "\f616";
}
.fa-parking-slash:before {
  content: "\f617";
}
.fa-passport:before {
  content: "\f5ab";
}
.fa-pastafarianism:before {
  content: "\f67b";
}
.fa-paste:before {
  content: "\f0ea";
}
.fa-patreon:before {
  content: "\f3d9";
}
.fa-pause:before {
  content: "\f04c";
}
.fa-pause-circle:before {
  content: "\f28b";
}
.fa-paw:before {
  content: "\f1b0";
}
.fa-paw-alt:before {
  content: "\f701";
}
.fa-paw-claws:before {
  content: "\f702";
}
.fa-paypal:before {
  content: "\f1ed";
}
.fa-peace:before {
  content: "\f67c";
}
.fa-pegasus:before {
  content: "\f703";
}
.fa-pen:before {
  content: "\f304";
}
.fa-pen-alt:before {
  content: "\f305";
}
.fa-pen-fancy:before {
  content: "\f5ac";
}
.fa-pen-nib:before {
  content: "\f5ad";
}
.fa-pen-square:before {
  content: "\f14b";
}
.fa-pencil:before {
  content: "\f040";
}
.fa-pencil-alt:before {
  content: "\f303";
}
.fa-pencil-paintbrush:before {
  content: "\f618";
}
.fa-pencil-ruler:before {
  content: "\f5ae";
}
.fa-pennant:before {
  content: "\f456";
}
.fa-penny-arcade:before {
  content: "\f704";
}
.fa-people-carry:before {
  content: "\f4ce";
}
.fa-pepper-hot:before {
  content: "\f816";
}
.fa-percent:before {
  content: "\f295";
}
.fa-percentage:before {
  content: "\f541";
}
.fa-periscope:before {
  content: "\f3da";
}
.fa-person-booth:before {
  content: "\f756";
}
.fa-person-carry:before {
  content: "\f4cf";
}
.fa-person-dolly:before {
  content: "\f4d0";
}
.fa-person-dolly-empty:before {
  content: "\f4d1";
}
.fa-person-sign:before {
  content: "\f757";
}
.fa-phabricator:before {
  content: "\f3db";
}
.fa-phoenix-framework:before {
  content: "\f3dc";
}
.fa-phoenix-squadron:before {
  content: "\f511";
}
.fa-phone:before {
  content: "\f095";
}
.fa-phone-office:before {
  content: "\f67d";
}
.fa-phone-plus:before {
  content: "\f4d2";
}
.fa-phone-slash:before {
  content: "\f3dd";
}
.fa-phone-square:before {
  content: "\f098";
}
.fa-phone-volume:before {
  content: "\f2a0";
}
.fa-php:before {
  content: "\f457";
}
.fa-pi:before {
  content: "\f67e";
}
.fa-pie:before {
  content: "\f705";
}
.fa-pied-piper:before {
  content: "\f2ae";
}
.fa-pied-piper-alt:before {
  content: "\f1a8";
}
.fa-pied-piper-hat:before {
  content: "\f4e5";
}
.fa-pied-piper-pp:before {
  content: "\f1a7";
}
.fa-pig:before {
  content: "\f706";
}
.fa-piggy-bank:before {
  content: "\f4d3";
}
.fa-pills:before {
  content: "\f484";
}
.fa-pinterest:before {
  content: "\f0d2";
}
.fa-pinterest-p:before {
  content: "\f231";
}
.fa-pinterest-square:before {
  content: "\f0d3";
}
.fa-pizza:before {
  content: "\f817";
}
.fa-pizza-slice:before {
  content: "\f818";
}
.fa-place-of-worship:before {
  content: "\f67f";
}
.fa-plane:before {
  content: "\f072";
}
.fa-plane-alt:before {
  content: "\f3de";
}
.fa-plane-arrival:before {
  content: "\f5af";
}
.fa-plane-departure:before {
  content: "\f5b0";
}
.fa-play:before {
  content: "\f04b";
}
.fa-play-circle:before {
  content: "\f144";
}
.fa-playstation:before {
  content: "\f3df";
}
.fa-plug:before {
  content: "\f1e6";
}
.fa-plus:before {
  content: "\f067";
}
.fa-plus-circle:before {
  content: "\f055";
}
.fa-plus-hexagon:before {
  content: "\f300";
}
.fa-plus-octagon:before {
  content: "\f301";
}
.fa-plus-square:before {
  content: "\f0fe";
}
.fa-podcast:before {
  content: "\f2ce";
}
.fa-podium:before {
  content: "\f680";
}
.fa-podium-star:before {
  content: "\f758";
}
.fa-poll:before {
  content: "\f681";
}
.fa-poll-h:before {
  content: "\f682";
}
.fa-poll-people:before {
  content: "\f759";
}
.fa-poo:before {
  content: "\f2fe";
}
.fa-poo-storm:before {
  content: "\f75a";
}
.fa-poop:before {
  content: "\f619";
}
.fa-popcorn:before {
  content: "\f819";
}
.fa-portrait:before {
  content: "\f3e0";
}
.fa-pound-sign:before {
  content: "\f154";
}
.fa-power-off:before {
  content: "\f011";
}
.fa-pray:before {
  content: "\f683";
}
.fa-praying-hands:before {
  content: "\f684";
}
.fa-prescription:before {
  content: "\f5b1";
}
.fa-prescription-bottle:before {
  content: "\f485";
}
.fa-prescription-bottle-alt:before {
  content: "\f486";
}
.fa-presentation:before {
  content: "\f685";
}
.fa-print:before {
  content: "\f02f";
}
.fa-print-search:before {
  content: "\f81a";
}
.fa-print-slash:before {
  content: "\f686";
}
.fa-procedures:before {
  content: "\f487";
}
.fa-product-hunt:before {
  content: "\f288";
}
.fa-project-diagram:before {
  content: "\f542";
}
.fa-pumpkin:before {
  content: "\f707";
}
.fa-pushed:before {
  content: "\f3e1";
}
.fa-puzzle-piece:before {
  content: "\f12e";
}
.fa-python:before {
  content: "\f3e2";
}
.fa-qq:before {
  content: "\f1d6";
}
.fa-qrcode:before {
  content: "\f029";
}
.fa-question:before {
  content: "\f128";
}
.fa-question-circle:before {
  content: "\f059";
}
.fa-question-square:before {
  content: "\f2fd";
}
.fa-quidditch:before {
  content: "\f458";
}
.fa-quinscape:before {
  content: "\f459";
}
.fa-quora:before {
  content: "\f2c4";
}
.fa-quote-left:before {
  content: "\f10d";
}
.fa-quote-right:before {
  content: "\f10e";
}
.fa-quran:before {
  content: "\f687";
}
.fa-r-project:before {
  content: "\f4f7";
}
.fa-rabbit:before {
  content: "\f708";
}
.fa-rabbit-fast:before {
  content: "\f709";
}
.fa-racquet:before {
  content: "\f45a";
}
.fa-radiation:before {
  content: "\f7b9";
}
.fa-radiation-alt:before {
  content: "\f7ba";
}
.fa-rainbow:before {
  content: "\f75b";
}
.fa-raindrops:before {
  content: "\f75c";
}
.fa-ram:before {
  content: "\f70a";
}
.fa-ramp-loading:before {
  content: "\f4d4";
}
.fa-random:before {
  content: "\f074";
}
.fa-raspberry-pi:before {
  content: "\f7bb";
}
.fa-ravelry:before {
  content: "\f2d9";
}
.fa-react:before {
  content: "\f41b";
}
.fa-reacteurope:before {
  content: "\f75d";
}
.fa-readme:before {
  content: "\f4d5";
}
.fa-rebel:before {
  content: "\f1d0";
}
.fa-receipt:before {
  content: "\f543";
}
.fa-rectangle-landscape:before {
  content: "\f2fa";
}
.fa-rectangle-portrait:before {
  content: "\f2fb";
}
.fa-rectangle-wide:before {
  content: "\f2fc";
}
.fa-recycle:before {
  content: "\f1b8";
}
.fa-red-river:before {
  content: "\f3e3";
}
.fa-reddit:before {
  content: "\f1a1";
}
.fa-reddit-alien:before {
  content: "\f281";
}
.fa-reddit-square:before {
  content: "\f1a2";
}
.fa-redhat:before {
  content: "\f7bc";
}
.fa-redo:before {
  content: "\f01e";
}
.fa-redo-alt:before {
  content: "\f2f9";
}
.fa-registered:before {
  content: "\f25d";
}
.fa-renren:before {
  content: "\f18b";
}
.fa-repeat:before {
  content: "\f363";
}
.fa-repeat-1:before {
  content: "\f365";
}
.fa-repeat-1-alt:before {
  content: "\f366";
}
.fa-repeat-alt:before {
  content: "\f364";
}
.fa-reply:before {
  content: "\f3e5";
}
.fa-reply-all:before {
  content: "\f122";
}
.fa-replyd:before {
  content: "\f3e6";
}
.fa-republican:before {
  content: "\f75e";
}
.fa-researchgate:before {
  content: "\f4f8";
}
.fa-resolving:before {
  content: "\f3e7";
}
.fa-restroom:before {
  content: "\f7bd";
}
.fa-retweet:before {
  content: "\f079";
}
.fa-retweet-alt:before {
  content: "\f361";
}
.fa-rev:before {
  content: "\f5b2";
}
.fa-ribbon:before {
  content: "\f4d6";
}
.fa-ring:before {
  content: "\f70b";
}
.fa-rings-wedding:before {
  content: "\f81b";
}
.fa-road:before {
  content: "\f018";
}
.fa-robot:before {
  content: "\f544";
}
.fa-rocket:before {
  content: "\f135";
}
.fa-rocketchat:before {
  content: "\f3e8";
}
.fa-rockrms:before {
  content: "\f3e9";
}
.fa-route:before {
  content: "\f4d7";
}
.fa-route-highway:before {
  content: "\f61a";
}
.fa-route-interstate:before {
  content: "\f61b";
}
.fa-rss:before {
  content: "\f09e";
}
.fa-rss-square:before {
  content: "\f143";
}
.fa-ruble-sign:before {
  content: "\f158";
}
.fa-ruler:before {
  content: "\f545";
}
.fa-ruler-combined:before {
  content: "\f546";
}
.fa-ruler-horizontal:before {
  content: "\f547";
}
.fa-ruler-triangle:before {
  content: "\f61c";
}
.fa-ruler-vertical:before {
  content: "\f548";
}
.fa-running:before {
  content: "\f70c";
}
.fa-rupee-sign:before {
  content: "\f156";
}
.fa-rv:before {
  content: "\f7be";
}
.fa-sack:before {
  content: "\f81c";
}
.fa-sack-dollar:before {
  content: "\f81d";
}
.fa-sad-cry:before {
  content: "\f5b3";
}
.fa-sad-tear:before {
  content: "\f5b4";
}
.fa-safari:before {
  content: "\f267";
}
.fa-salad:before {
  content: "\f81e";
}
.fa-salesforce:before {
  content: "\f83b";
}
.fa-sandwich:before {
  content: "\f81f";
}
.fa-sass:before {
  content: "\f41e";
}
.fa-satellite:before {
  content: "\f7bf";
}
.fa-satellite-dish:before {
  content: "\f7c0";
}
.fa-sausage:before {
  content: "\f820";
}
.fa-save:before {
  content: "\f0c7";
}
.fa-scalpel:before {
  content: "\f61d";
}
.fa-scalpel-path:before {
  content: "\f61e";
}
.fa-scanner:before {
  content: "\f488";
}
.fa-scanner-keyboard:before {
  content: "\f489";
}
.fa-scanner-touchscreen:before {
  content: "\f48a";
}
.fa-scarecrow:before {
  content: "\f70d";
}
.fa-scarf:before {
  content: "\f7c1";
}
.fa-schlix:before {
  content: "\f3ea";
}
.fa-school:before {
  content: "\f549";
}
.fa-screwdriver:before {
  content: "\f54a";
}
.fa-scribd:before {
  content: "\f28a";
}
.fa-scroll:before {
  content: "\f70e";
}
.fa-scroll-old:before {
  content: "\f70f";
}
.fa-scrubber:before {
  content: "\f2f8";
}
.fa-scythe:before {
  content: "\f710";
}
.fa-sd-card:before {
  content: "\f7c2";
}
.fa-search:before {
  content: "\f002";
}
.fa-search-dollar:before {
  content: "\f688";
}
.fa-search-location:before {
  content: "\f689";
}
.fa-search-minus:before {
  content: "\f010";
}
.fa-search-plus:before {
  content: "\f00e";
}
.fa-searchengin:before {
  content: "\f3eb";
}
.fa-seedling:before {
  content: "\f4d8";
}
.fa-sellcast:before {
  content: "\f2da";
}
.fa-sellsy:before {
  content: "\f213";
}
.fa-server:before {
  content: "\f233";
}
.fa-servicestack:before {
  content: "\f3ec";
}
.fa-shapes:before {
  content: "\f61f";
}
.fa-share:before {
  content: "\f064";
}
.fa-share-all:before {
  content: "\f367";
}
.fa-share-alt:before {
  content: "\f1e0";
}
.fa-share-alt-square:before {
  content: "\f1e1";
}
.fa-share-square:before {
  content: "\f14d";
}
.fa-sheep:before {
  content: "\f711";
}
.fa-shekel-sign:before {
  content: "\f20b";
}
.fa-shield:before {
  content: "\f132";
}
.fa-shield-alt:before {
  content: "\f3ed";
}
.fa-shield-check:before {
  content: "\f2f7";
}
.fa-shield-cross:before {
  content: "\f712";
}
.fa-ship:before {
  content: "\f21a";
}
.fa-shipping-fast:before {
  content: "\f48b";
}
.fa-shipping-timed:before {
  content: "\f48c";
}
.fa-shirtsinbulk:before {
  content: "\f214";
}
.fa-shish-kebab:before {
  content: "\f821";
}
.fa-shoe-prints:before {
  content: "\f54b";
}
.fa-shopping-bag:before {
  content: "\f290";
}
.fa-shopping-basket:before {
  content: "\f291";
}
.fa-shopping-cart:before {
  content: "\f07a";
}
.fa-shopware:before {
  content: "\f5b5";
}
.fa-shovel:before {
  content: "\f713";
}
.fa-shovel-snow:before {
  content: "\f7c3";
}
.fa-shower:before {
  content: "\f2cc";
}
.fa-shredder:before {
  content: "\f68a";
}
.fa-shuttle-van:before {
  content: "\f5b6";
}
.fa-shuttlecock:before {
  content: "\f45b";
}
.fa-sickle:before {
  content: "\f822";
}
.fa-sigma:before {
  content: "\f68b";
}
.fa-sign:before {
  content: "\f4d9";
}
.fa-sign-in:before {
  content: "\f090";
}
.fa-sign-in-alt:before {
  content: "\f2f6";
}
.fa-sign-language:before {
  content: "\f2a7";
}
.fa-sign-out:before {
  content: "\f08b";
}
.fa-sign-out-alt:before {
  content: "\f2f5";
}
.fa-signal:before {
  content: "\f012";
}
.fa-signal-1:before {
  content: "\f68c";
}
.fa-signal-2:before {
  content: "\f68d";
}
.fa-signal-3:before {
  content: "\f68e";
}
.fa-signal-4:before {
  content: "\f68f";
}
.fa-signal-alt:before {
  content: "\f690";
}
.fa-signal-alt-1:before {
  content: "\f691";
}
.fa-signal-alt-2:before {
  content: "\f692";
}
.fa-signal-alt-3:before {
  content: "\f693";
}
.fa-signal-alt-slash:before {
  content: "\f694";
}
.fa-signal-slash:before {
  content: "\f695";
}
.fa-signature:before {
  content: "\f5b7";
}
.fa-sim-card:before {
  content: "\f7c4";
}
.fa-simplybuilt:before {
  content: "\f215";
}
.fa-sistrix:before {
  content: "\f3ee";
}
.fa-sitemap:before {
  content: "\f0e8";
}
.fa-sith:before {
  content: "\f512";
}
.fa-skating:before {
  content: "\f7c5";
}
.fa-skeleton:before {
  content: "\f620";
}
.fa-sketch:before {
  content: "\f7c6";
}
.fa-ski-jump:before {
  content: "\f7c7";
}
.fa-ski-lift:before {
  content: "\f7c8";
}
.fa-skiing:before {
  content: "\f7c9";
}
.fa-skiing-nordic:before {
  content: "\f7ca";
}
.fa-skull:before {
  content: "\f54c";
}
.fa-skull-crossbones:before {
  content: "\f714";
}
.fa-skyatlas:before {
  content: "\f216";
}
.fa-skype:before {
  content: "\f17e";
}
.fa-slack:before {
  content: "\f198";
}
.fa-slack-hash:before {
  content: "\f3ef";
}
.fa-slash:before {
  content: "\f715";
}
.fa-sledding:before {
  content: "\f7cb";
}
.fa-sleigh:before {
  content: "\f7cc";
}
.fa-sliders-h:before {
  content: "\f1de";
}
.fa-sliders-h-square:before {
  content: "\f3f0";
}
.fa-sliders-v:before {
  content: "\f3f1";
}
.fa-sliders-v-square:before {
  content: "\f3f2";
}
.fa-slideshare:before {
  content: "\f1e7";
}
.fa-smile:before {
  content: "\f118";
}
.fa-smile-beam:before {
  content: "\f5b8";
}
.fa-smile-plus:before {
  content: "\f5b9";
}
.fa-smile-wink:before {
  content: "\f4da";
}
.fa-smog:before {
  content: "\f75f";
}
.fa-smoke:before {
  content: "\f760";
}
.fa-smoking:before {
  content: "\f48d";
}
.fa-smoking-ban:before {
  content: "\f54d";
}
.fa-sms:before {
  content: "\f7cd";
}
.fa-snake:before {
  content: "\f716";
}
.fa-snapchat:before {
  content: "\f2ab";
}
.fa-snapchat-ghost:before {
  content: "\f2ac";
}
.fa-snapchat-square:before {
  content: "\f2ad";
}
.fa-snow-blowing:before {
  content: "\f761";
}
.fa-snowboarding:before {
  content: "\f7ce";
}
.fa-snowflake:before {
  content: "\f2dc";
}
.fa-snowflakes:before {
  content: "\f7cf";
}
.fa-snowman:before {
  content: "\f7d0";
}
.fa-snowmobile:before {
  content: "\f7d1";
}
.fa-snowplow:before {
  content: "\f7d2";
}
.fa-socks:before {
  content: "\f696";
}
.fa-solar-panel:before {
  content: "\f5ba";
}
.fa-sort:before {
  content: "\f0dc";
}
.fa-sort-alpha-down:before {
  content: "\f15d";
}
.fa-sort-alpha-up:before {
  content: "\f15e";
}
.fa-sort-amount-down:before {
  content: "\f160";
}
.fa-sort-amount-up:before {
  content: "\f161";
}
.fa-sort-down:before {
  content: "\f0dd";
}
.fa-sort-numeric-down:before {
  content: "\f162";
}
.fa-sort-numeric-up:before {
  content: "\f163";
}
.fa-sort-up:before {
  content: "\f0de";
}
.fa-soundcloud:before {
  content: "\f1be";
}
.fa-soup:before {
  content: "\f823";
}
.fa-sourcetree:before {
  content: "\f7d3";
}
.fa-spa:before {
  content: "\f5bb";
}
.fa-space-shuttle:before {
  content: "\f197";
}
.fa-spade:before {
  content: "\f2f4";
}
.fa-speakap:before {
  content: "\f3f3";
}
.fa-speaker-deck:before {
  content: "\f83c";
}
.fa-spider:before {
  content: "\f717";
}
.fa-spider-black-widow:before {
  content: "\f718";
}
.fa-spider-web:before {
  content: "\f719";
}
.fa-spinner:before {
  content: "\f110";
}
.fa-spinner-third:before {
  content: "\f3f4";
}
.fa-splotch:before {
  content: "\f5bc";
}
.fa-spotify:before {
  content: "\f1bc";
}
.fa-spray-can:before {
  content: "\f5bd";
}
.fa-square:before {
  content: "\f0c8";
}
.fa-square-full:before {
  content: "\f45c";
}
.fa-square-root:before {
  content: "\f697";
}
.fa-square-root-alt:before {
  content: "\f698";
}
.fa-squarespace:before {
  content: "\f5be";
}
.fa-squirrel:before {
  content: "\f71a";
}
.fa-stack-exchange:before {
  content: "\f18d";
}
.fa-stack-overflow:before {
  content: "\f16c";
}
.fa-staff:before {
  content: "\f71b";
}
.fa-stamp:before {
  content: "\f5bf";
}
.fa-star:before {
  content: "\f005";
}
.fa-star-and-crescent:before {
  content: "\f699";
}
.fa-star-christmas:before {
  content: "\f7d4";
}
.fa-star-exclamation:before {
  content: "\f2f3";
}
.fa-star-half:before {
  content: "\f089";
}
.fa-star-half-alt:before {
  content: "\f5c0";
}
.fa-star-of-david:before {
  content: "\f69a";
}
.fa-star-of-life:before {
  content: "\f621";
}
.fa-stars:before {
  content: "\f762";
}
.fa-staylinked:before {
  content: "\f3f5";
}
.fa-steak:before {
  content: "\f824";
}
.fa-steam:before {
  content: "\f1b6";
}
.fa-steam-square:before {
  content: "\f1b7";
}
.fa-steam-symbol:before {
  content: "\f3f6";
}
.fa-steering-wheel:before {
  content: "\f622";
}
.fa-step-backward:before {
  content: "\f048";
}
.fa-step-forward:before {
  content: "\f051";
}
.fa-stethoscope:before {
  content: "\f0f1";
}
.fa-sticker-mule:before {
  content: "\f3f7";
}
.fa-sticky-note:before {
  content: "\f249";
}
.fa-stocking:before {
  content: "\f7d5";
}
.fa-stomach:before {
  content: "\f623";
}
.fa-stop:before {
  content: "\f04d";
}
.fa-stop-circle:before {
  content: "\f28d";
}
.fa-stopwatch:before {
  content: "\f2f2";
}
.fa-store:before {
  content: "\f54e";
}
.fa-store-alt:before {
  content: "\f54f";
}
.fa-strava:before {
  content: "\f428";
}
.fa-stream:before {
  content: "\f550";
}
.fa-street-view:before {
  content: "\f21d";
}
.fa-stretcher:before {
  content: "\f825";
}
.fa-strikethrough:before {
  content: "\f0cc";
}
.fa-stripe:before {
  content: "\f429";
}
.fa-stripe-s:before {
  content: "\f42a";
}
.fa-stroopwafel:before {
  content: "\f551";
}
.fa-studiovinari:before {
  content: "\f3f8";
}
.fa-stumbleupon:before {
  content: "\f1a4";
}
.fa-stumbleupon-circle:before {
  content: "\f1a3";
}
.fa-subscript:before {
  content: "\f12c";
}
.fa-subway:before {
  content: "\f239";
}
.fa-suitcase:before {
  content: "\f0f2";
}
.fa-suitcase-rolling:before {
  content: "\f5c1";
}
.fa-sun:before {
  content: "\f185";
}
.fa-sun-cloud:before {
  content: "\f763";
}
.fa-sun-dust:before {
  content: "\f764";
}
.fa-sun-haze:before {
  content: "\f765";
}
.fa-sunrise:before {
  content: "\f766";
}
.fa-sunset:before {
  content: "\f767";
}
.fa-superpowers:before {
  content: "\f2dd";
}
.fa-superscript:before {
  content: "\f12b";
}
.fa-supple:before {
  content: "\f3f9";
}
.fa-surprise:before {
  content: "\f5c2";
}
.fa-suse:before {
  content: "\f7d6";
}
.fa-swatchbook:before {
  content: "\f5c3";
}
.fa-swimmer:before {
  content: "\f5c4";
}
.fa-swimming-pool:before {
  content: "\f5c5";
}
.fa-sword:before {
  content: "\f71c";
}
.fa-swords:before {
  content: "\f71d";
}
.fa-symfony:before {
  content: "\f83d";
}
.fa-synagogue:before {
  content: "\f69b";
}
.fa-sync:before {
  content: "\f021";
}
.fa-sync-alt:before {
  content: "\f2f1";
}
.fa-syringe:before {
  content: "\f48e";
}
.fa-table:before {
  content: "\f0ce";
}
.fa-table-tennis:before {
  content: "\f45d";
}
.fa-tablet:before {
  content: "\f10a";
}
.fa-tablet-alt:before {
  content: "\f3fa";
}
.fa-tablet-android:before {
  content: "\f3fb";
}
.fa-tablet-android-alt:before {
  content: "\f3fc";
}
.fa-tablet-rugged:before {
  content: "\f48f";
}
.fa-tablets:before {
  content: "\f490";
}
.fa-tachometer:before {
  content: "\f0e4";
}
.fa-tachometer-alt:before {
  content: "\f3fd";
}
.fa-tachometer-alt-average:before {
  content: "\f624";
}
.fa-tachometer-alt-fast:before {
  content: "\f625";
}
.fa-tachometer-alt-fastest:before {
  content: "\f626";
}
.fa-tachometer-alt-slow:before {
  content: "\f627";
}
.fa-tachometer-alt-slowest:before {
  content: "\f628";
}
.fa-tachometer-average:before {
  content: "\f629";
}
.fa-tachometer-fast:before {
  content: "\f62a";
}
.fa-tachometer-fastest:before {
  content: "\f62b";
}
.fa-tachometer-slow:before {
  content: "\f62c";
}
.fa-tachometer-slowest:before {
  content: "\f62d";
}
.fa-taco:before {
  content: "\f826";
}
.fa-tag:before {
  content: "\f02b";
}
.fa-tags:before {
  content: "\f02c";
}
.fa-tally:before {
  content: "\f69c";
}
.fa-tanakh:before {
  content: "\f827";
}
.fa-tape:before {
  content: "\f4db";
}
.fa-tasks:before {
  content: "\f0ae";
}
.fa-tasks-alt:before {
  content: "\f828";
}
.fa-taxi:before {
  content: "\f1ba";
}
.fa-teamspeak:before {
  content: "\f4f9";
}
.fa-teeth:before {
  content: "\f62e";
}
.fa-teeth-open:before {
  content: "\f62f";
}
.fa-telegram:before {
  content: "\f2c6";
}
.fa-telegram-plane:before {
  content: "\f3fe";
}
.fa-temperature-frigid:before {
  content: "\f768";
}
.fa-temperature-high:before {
  content: "\f769";
}
.fa-temperature-hot:before {
  content: "\f76a";
}
.fa-temperature-low:before {
  content: "\f76b";
}
.fa-tencent-weibo:before {
  content: "\f1d5";
}
.fa-tenge:before {
  content: "\f7d7";
}
.fa-tennis-ball:before {
  content: "\f45e";
}
.fa-terminal:before {
  content: "\f120";
}
.fa-text-height:before {
  content: "\f034";
}
.fa-text-width:before {
  content: "\f035";
}
.fa-th:before {
  content: "\f00a";
}
.fa-th-large:before {
  content: "\f009";
}
.fa-th-list:before {
  content: "\f00b";
}
.fa-the-red-yeti:before {
  content: "\f69d";
}
.fa-theater-masks:before {
  content: "\f630";
}
.fa-themeco:before {
  content: "\f5c6";
}
.fa-themeisle:before {
  content: "\f2b2";
}
.fa-thermometer:before {
  content: "\f491";
}
.fa-thermometer-empty:before {
  content: "\f2cb";
}
.fa-thermometer-full:before {
  content: "\f2c7";
}
.fa-thermometer-half:before {
  content: "\f2c9";
}
.fa-thermometer-quarter:before {
  content: "\f2ca";
}
.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}
.fa-theta:before {
  content: "\f69e";
}
.fa-think-peaks:before {
  content: "\f731";
}
.fa-thumbs-down:before {
  content: "\f165";
}
.fa-thumbs-up:before {
  content: "\f164";
}
.fa-thumbtack:before {
  content: "\f08d";
}
.fa-thunderstorm:before {
  content: "\f76c";
}
.fa-thunderstorm-moon:before {
  content: "\f76d";
}
.fa-thunderstorm-sun:before {
  content: "\f76e";
}
.fa-ticket:before {
  content: "\f145";
}
.fa-ticket-alt:before {
  content: "\f3ff";
}
.fa-tilde:before {
  content: "\f69f";
}
.fa-times:before {
  content: "\f00d";
}
.fa-times-circle:before {
  content: "\f057";
}
.fa-times-hexagon:before {
  content: "\f2ee";
}
.fa-times-octagon:before {
  content: "\f2f0";
}
.fa-times-square:before {
  content: "\f2d3";
}
.fa-tint:before {
  content: "\f043";
}
.fa-tint-slash:before {
  content: "\f5c7";
}
.fa-tire:before {
  content: "\f631";
}
.fa-tire-flat:before {
  content: "\f632";
}
.fa-tire-pressure-warning:before {
  content: "\f633";
}
.fa-tire-rugged:before {
  content: "\f634";
}
.fa-tired:before {
  content: "\f5c8";
}
.fa-toggle-off:before {
  content: "\f204";
}
.fa-toggle-on:before {
  content: "\f205";
}
.fa-toilet:before {
  content: "\f7d8";
}
.fa-toilet-paper:before {
  content: "\f71e";
}
.fa-toilet-paper-alt:before {
  content: "\f71f";
}
.fa-tombstone:before {
  content: "\f720";
}
.fa-tombstone-alt:before {
  content: "\f721";
}
.fa-toolbox:before {
  content: "\f552";
}
.fa-tools:before {
  content: "\f7d9";
}
.fa-tooth:before {
  content: "\f5c9";
}
.fa-toothbrush:before {
  content: "\f635";
}
.fa-torah:before {
  content: "\f6a0";
}
.fa-torii-gate:before {
  content: "\f6a1";
}
.fa-tornado:before {
  content: "\f76f";
}
.fa-tractor:before {
  content: "\f722";
}
.fa-trade-federation:before {
  content: "\f513";
}
.fa-trademark:before {
  content: "\f25c";
}
.fa-traffic-cone:before {
  content: "\f636";
}
.fa-traffic-light:before {
  content: "\f637";
}
.fa-traffic-light-go:before {
  content: "\f638";
}
.fa-traffic-light-slow:before {
  content: "\f639";
}
.fa-traffic-light-stop:before {
  content: "\f63a";
}
.fa-train:before {
  content: "\f238";
}
.fa-tram:before {
  content: "\f7da";
}
.fa-transgender:before {
  content: "\f224";
}
.fa-transgender-alt:before {
  content: "\f225";
}
.fa-trash:before {
  content: "\f1f8";
}
.fa-trash-alt:before {
  content: "\f2ed";
}
.fa-trash-restore:before {
  content: "\f829";
}
.fa-trash-restore-alt:before {
  content: "\f82a";
}
.fa-treasure-chest:before {
  content: "\f723";
}
.fa-tree:before {
  content: "\f1bb";
}
.fa-tree-alt:before {
  content: "\f400";
}
.fa-tree-christmas:before {
  content: "\f7db";
}
.fa-tree-decorated:before {
  content: "\f7dc";
}
.fa-tree-large:before {
  content: "\f7dd";
}
.fa-tree-palm:before {
  content: "\f82b";
}
.fa-trees:before {
  content: "\f724";
}
.fa-trello:before {
  content: "\f181";
}
.fa-triangle:before {
  content: "\f2ec";
}
.fa-tripadvisor:before {
  content: "\f262";
}
.fa-trophy:before {
  content: "\f091";
}
.fa-trophy-alt:before {
  content: "\f2eb";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-truck-container:before {
  content: "\f4dc";
}
.fa-truck-couch:before {
  content: "\f4dd";
}
.fa-truck-loading:before {
  content: "\f4de";
}
.fa-truck-monster:before {
  content: "\f63b";
}
.fa-truck-moving:before {
  content: "\f4df";
}
.fa-truck-pickup:before {
  content: "\f63c";
}
.fa-truck-plow:before {
  content: "\f7de";
}
.fa-truck-ramp:before {
  content: "\f4e0";
}
.fa-tshirt:before {
  content: "\f553";
}
.fa-tty:before {
  content: "\f1e4";
}
.fa-tumblr:before {
  content: "\f173";
}
.fa-tumblr-square:before {
  content: "\f174";
}
.fa-turkey:before {
  content: "\f725";
}
.fa-turtle:before {
  content: "\f726";
}
.fa-tv:before {
  content: "\f26c";
}
.fa-tv-retro:before {
  content: "\f401";
}
.fa-twitch:before {
  content: "\f1e8";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-twitter-square:before {
  content: "\f081";
}
.fa-typo3:before {
  content: "\f42b";
}
.fa-uber:before {
  content: "\f402";
}
.fa-ubuntu:before {
  content: "\f7df";
}
.fa-uikit:before {
  content: "\f403";
}
.fa-umbrella:before {
  content: "\f0e9";
}
.fa-umbrella-beach:before {
  content: "\f5ca";
}
.fa-underline:before {
  content: "\f0cd";
}
.fa-undo:before {
  content: "\f0e2";
}
.fa-undo-alt:before {
  content: "\f2ea";
}
.fa-unicorn:before {
  content: "\f727";
}
.fa-union:before {
  content: "\f6a2";
}
.fa-uniregistry:before {
  content: "\f404";
}
.fa-universal-access:before {
  content: "\f29a";
}
.fa-university:before {
  content: "\f19c";
}
.fa-unlink:before {
  content: "\f127";
}
.fa-unlock:before {
  content: "\f09c";
}
.fa-unlock-alt:before {
  content: "\f13e";
}
.fa-untappd:before {
  content: "\f405";
}
.fa-upload:before {
  content: "\f093";
}
.fa-ups:before {
  content: "\f7e0";
}
.fa-usb:before {
  content: "\f287";
}
.fa-usd-circle:before {
  content: "\f2e8";
}
.fa-usd-square:before {
  content: "\f2e9";
}
.fa-user:before {
  content: "\f007";
}
.fa-user-alt:before {
  content: "\f406";
}
.fa-user-alt-slash:before {
  content: "\f4fa";
}
.fa-user-astronaut:before {
  content: "\f4fb";
}
.fa-user-chart:before {
  content: "\f6a3";
}
.fa-user-check:before {
  content: "\f4fc";
}
.fa-user-circle:before {
  content: "\f2bd";
}
.fa-user-clock:before {
  content: "\f4fd";
}
.fa-user-cog:before {
  content: "\f4fe";
}
.fa-user-crown:before {
  content: "\f6a4";
}
.fa-user-edit:before {
  content: "\f4ff";
}
.fa-user-friends:before {
  content: "\f500";
}
.fa-user-graduate:before {
  content: "\f501";
}
.fa-user-hard-hat:before {
  content: "\f82c";
}
.fa-user-headset:before {
  content: "\f82d";
}
.fa-user-injured:before {
  content: "\f728";
}
.fa-user-lock:before {
  content: "\f502";
}
.fa-user-md:before {
  content: "\f0f0";
}
.fa-user-md-chat:before {
  content: "\f82e";
}
.fa-user-minus:before {
  content: "\f503";
}
.fa-user-ninja:before {
  content: "\f504";
}
.fa-user-nurse:before {
  content: "\f82f";
}
.fa-user-plus:before {
  content: "\f234";
}
.fa-user-secret:before {
  content: "\f21b";
}
.fa-user-shield:before {
  content: "\f505";
}
.fa-user-slash:before {
  content: "\f506";
}
.fa-user-tag:before {
  content: "\f507";
}
.fa-user-tie:before {
  content: "\f508";
}
.fa-user-times:before {
  content: "\f235";
}
.fa-users:before {
  content: "\f0c0";
}
.fa-users-class:before {
  content: "\f63d";
}
.fa-users-cog:before {
  content: "\f509";
}
.fa-users-crown:before {
  content: "\f6a5";
}
.fa-users-medical:before {
  content: "\f830";
}
.fa-usps:before {
  content: "\f7e1";
}
.fa-ussunnah:before {
  content: "\f407";
}
.fa-utensil-fork:before {
  content: "\f2e3";
}
.fa-utensil-knife:before {
  content: "\f2e4";
}
.fa-utensil-spoon:before {
  content: "\f2e5";
}
.fa-utensils:before {
  content: "\f2e7";
}
.fa-utensils-alt:before {
  content: "\f2e6";
}
.fa-vaadin:before {
  content: "\f408";
}
.fa-value-absolute:before {
  content: "\f6a6";
}
.fa-vector-square:before {
  content: "\f5cb";
}
.fa-venus:before {
  content: "\f221";
}
.fa-venus-double:before {
  content: "\f226";
}
.fa-venus-mars:before {
  content: "\f228";
}
.fa-viacoin:before {
  content: "\f237";
}
.fa-viadeo:before {
  content: "\f2a9";
}
.fa-viadeo-square:before {
  content: "\f2aa";
}
.fa-vial:before {
  content: "\f492";
}
.fa-vials:before {
  content: "\f493";
}
.fa-viber:before {
  content: "\f409";
}
.fa-video:before {
  content: "\f03d";
}
.fa-video-plus:before {
  content: "\f4e1";
}
.fa-video-slash:before {
  content: "\f4e2";
}
.fa-vihara:before {
  content: "\f6a7";
}
.fa-vimeo:before {
  content: "\f40a";
}
.fa-vimeo-square:before {
  content: "\f194";
}
.fa-vimeo-v:before {
  content: "\f27d";
}
.fa-vine:before {
  content: "\f1ca";
}
.fa-vk:before {
  content: "\f189";
}
.fa-vnv:before {
  content: "\f40b";
}
.fa-volcano:before {
  content: "\f770";
}
.fa-volleyball-ball:before {
  content: "\f45f";
}
.fa-volume:before {
  content: "\f6a8";
}
.fa-volume-down:before {
  content: "\f027";
}
.fa-volume-mute:before {
  content: "\f6a9";
}
.fa-volume-off:before {
  content: "\f026";
}
.fa-volume-slash:before {
  content: "\f2e2";
}
.fa-volume-up:before {
  content: "\f028";
}
.fa-vote-nay:before {
  content: "\f771";
}
.fa-vote-yea:before {
  content: "\f772";
}
.fa-vr-cardboard:before {
  content: "\f729";
}
.fa-vuejs:before {
  content: "\f41f";
}
.fa-walker:before {
  content: "\f831";
}
.fa-walking:before {
  content: "\f554";
}
.fa-wallet:before {
  content: "\f555";
}
.fa-wand:before {
  content: "\f72a";
}
.fa-wand-magic:before {
  content: "\f72b";
}
.fa-warehouse:before {
  content: "\f494";
}
.fa-warehouse-alt:before {
  content: "\f495";
}
.fa-watch:before {
  content: "\f2e1";
}
.fa-watch-fitness:before {
  content: "\f63e";
}
.fa-water:before {
  content: "\f773";
}
.fa-water-lower:before {
  content: "\f774";
}
.fa-water-rise:before {
  content: "\f775";
}
.fa-wave-square:before {
  content: "\f83e";
}
.fa-waze:before {
  content: "\f83f";
}
.fa-webcam:before {
  content: "\f832";
}
.fa-webcam-slash:before {
  content: "\f833";
}
.fa-weebly:before {
  content: "\f5cc";
}
.fa-weibo:before {
  content: "\f18a";
}
.fa-weight:before {
  content: "\f496";
}
.fa-weight-hanging:before {
  content: "\f5cd";
}
.fa-weixin:before {
  content: "\f1d7";
}
.fa-whale:before {
  content: "\f72c";
}
.fa-whatsapp:before {
  content: "\f232";
}
.fa-whatsapp-square:before {
  content: "\f40c";
}
.fa-wheat:before {
  content: "\f72d";
}
.fa-wheelchair:before {
  content: "\f193";
}
.fa-whistle:before {
  content: "\f460";
}
.fa-whmcs:before {
  content: "\f40d";
}
.fa-wifi:before {
  content: "\f1eb";
}
.fa-wifi-1:before {
  content: "\f6aa";
}
.fa-wifi-2:before {
  content: "\f6ab";
}
.fa-wifi-slash:before {
  content: "\f6ac";
}
.fa-wikipedia-w:before {
  content: "\f266";
}
.fa-wind:before {
  content: "\f72e";
}
.fa-wind-warning:before {
  content: "\f776";
}
.fa-window:before {
  content: "\f40e";
}
.fa-window-alt:before {
  content: "\f40f";
}
.fa-window-close:before {
  content: "\f410";
}
.fa-window-maximize:before {
  content: "\f2d0";
}
.fa-window-minimize:before {
  content: "\f2d1";
}
.fa-window-restore:before {
  content: "\f2d2";
}
.fa-windows:before {
  content: "\f17a";
}
.fa-windsock:before {
  content: "\f777";
}
.fa-wine-bottle:before {
  content: "\f72f";
}
.fa-wine-glass:before {
  content: "\f4e3";
}
.fa-wine-glass-alt:before {
  content: "\f5ce";
}
.fa-wix:before {
  content: "\f5cf";
}
.fa-wizards-of-the-coast:before {
  content: "\f730";
}
.fa-wolf-pack-battalion:before {
  content: "\f514";
}
.fa-won-sign:before {
  content: "\f159";
}
.fa-wordpress:before {
  content: "\f19a";
}
.fa-wordpress-simple:before {
  content: "\f411";
}
.fa-wpbeginner:before {
  content: "\f297";
}
.fa-wpexplorer:before {
  content: "\f2de";
}
.fa-wpforms:before {
  content: "\f298";
}
.fa-wpressr:before {
  content: "\f3e4";
}
.fa-wreath:before {
  content: "\f7e2";
}
.fa-wrench:before {
  content: "\f0ad";
}
.fa-x-ray:before {
  content: "\f497";
}
.fa-xbox:before {
  content: "\f412";
}
.fa-xing:before {
  content: "\f168";
}
.fa-xing-square:before {
  content: "\f169";
}
.fa-y-combinator:before {
  content: "\f23b";
}
.fa-yahoo:before {
  content: "\f19e";
}
.fa-yammer:before {
  content: "\f840";
}
.fa-yandex:before {
  content: "\f413";
}
.fa-yandex-international:before {
  content: "\f414";
}
.fa-yarn:before {
  content: "\f7e3";
}
.fa-yelp:before {
  content: "\f1e9";
}
.fa-yen-sign:before {
  content: "\f157";
}
.fa-yin-yang:before {
  content: "\f6ad";
}
.fa-yoast:before {
  content: "\f2b1";
}
.fa-youtube:before {
  content: "\f167";
}
.fa-youtube-square:before {
  content: "\f431";
}
.fa-zhihu:before {
  content: "\f63f";
}
.sr-only {
  white-space: nowrap;
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
/*!
 * Font Awesome Pro 5.8.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url('../../fonts/fa-light-300.eot');
  src: url('../../fonts/fa-light-300.eot?#iefix') format('embedded-opentype'), url('../../fonts/fa-light-300.woff2') format('woff2'), url('../../fonts/fa-light-300.woff') format('woff'), url('../../fonts/fa-light-300.ttf') format('truetype'), url('../../fonts/fa-light-300.svg#fontawesome') format('svg');
}
.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}
/*!
 * Font Awesome Pro 5.8.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url('../../fonts/fa-regular-400.eot');
  src: url('../../fonts/fa-regular-400.eot?#iefix') format('embedded-opentype'), url('../../fonts/fa-regular-400.woff2') format('woff2'), url('../../fonts/fa-regular-400.woff') format('woff'), url('../../fonts/fa-regular-400.ttf') format('truetype'), url('../../fonts/fa-regular-400.svg#fontawesome') format('svg');
}
.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}
/*!
 * Font Awesome Pro 5.8.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url('../../fonts/fa-solid-900.eot');
  src: url('../../fonts/fa-solid-900.eot?#iefix') format('embedded-opentype'), url('../../fonts/fa-solid-900.woff2') format('woff2'), url('../../fonts/fa-solid-900.woff') format('woff'), url('../../fonts/fa-solid-900.ttf') format('truetype'), url('../../fonts/fa-solid-900.svg#fontawesome') format('svg');
}
.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}
/*!
 * Font Awesome Pro 5.8.1 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url('../../fonts/fa-brands-400.eot');
  src: url('../../fonts/fa-brands-400.eot?#iefix') format('embedded-opentype'), url('../../fonts/fa-brands-400.woff2') format('woff2'), url('../../fonts/fa-brands-400.woff') format('woff'), url('../../fonts/fa-brands-400.ttf') format('truetype'), url('../../fonts/fa-brands-400.svg#fontawesome') format('svg');
}
.fab {
  font-family: 'Font Awesome 5 Brands';
}
html,
body {
  overflow: hidden;
}
p {
  font-size: 0.9rem;
}
.small {
  font-size: 70%;
}
.container {
  max-width: 900px;
}
.u-bg-none {
  background: none !important;
}
.u-outline-0 {
  outline: none !important;
}
.u-color-transparent {
  color: transparent;
}
.u-active-shrink-1,
.u-active-shrink-2,
.u-active-shrink-3,
.u-active-shrink-4,
.u-active-shrink-5,
.u-active-shrink-6,
.u-active-shrink-7,
.u-active-shrink-8,
.u-active-shrink-9,
.u-active-shrink-10 {
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  -o-transform: scale(1) !important;
  transform: scale(1) !important;
  -webkit-transition: all 0.15s ease-in-out;
  -moz-transition: all 0.15s ease-in-out;
  -o-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
}
.u-active-shrink-1:active {
  -webkit-transform: scale(0.975) !important;
  -moz-transform: scale(0.975) !important;
  -o-transform: scale(0.975) !important;
  transform: scale(0.975) !important;
}
.u-active-shrink-2:active {
  -webkit-transform: scale(0.95) !important;
  -moz-transform: scale(0.95) !important;
  -o-transform: scale(0.95) !important;
  transform: scale(0.95) !important;
}
.u-active-shrink-3:active {
  -webkit-transform: scale(0.925) !important;
  -moz-transform: scale(0.925) !important;
  -o-transform: scale(0.925) !important;
  transform: scale(0.925) !important;
}
.u-active-shrink-4:active {
  -webkit-transform: scale(0.9) !important;
  -moz-transform: scale(0.9) !important;
  -o-transform: scale(0.9) !important;
  transform: scale(0.9) !important;
}
.u-active-shrink-5:active {
  -webkit-transform: scale(0.875) !important;
  -moz-transform: scale(0.875) !important;
  -o-transform: scale(0.875) !important;
  transform: scale(0.875) !important;
}
.u-active-shrink-6:active {
  -webkit-transform: scale(0.85) !important;
  -moz-transform: scale(0.85) !important;
  -o-transform: scale(0.85) !important;
  transform: scale(0.85) !important;
}
.u-active-shrink-7:active {
  -webkit-transform: scale(0.825) !important;
  -moz-transform: scale(0.825) !important;
  -o-transform: scale(0.825) !important;
  transform: scale(0.825) !important;
}
.u-active-shrink-8:active {
  -webkit-transform: scale(0.8) !important;
  -moz-transform: scale(0.8) !important;
  -o-transform: scale(0.8) !important;
  transform: scale(0.8) !important;
}
.u-active-shrink-9:active {
  -webkit-transform: scale(0.775) !important;
  -moz-transform: scale(0.775) !important;
  -o-transform: scale(0.775) !important;
  transform: scale(0.775) !important;
}
.u-active-shrink-10:active {
  -webkit-transform: scale(0.75) !important;
  -moz-transform: scale(0.75) !important;
  -o-transform: scale(0.75) !important;
  transform: scale(0.75) !important;
}
.PageHeader {
  position: relative;
  z-index: 1;
  border-bottom: 1px solid;
  transition-property: border-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.PageBody {
  position: relative;
  z-index: 0;
}
.PageFooter {
  position: relative;
  z-index: 1;
  border-top: 1px solid;
  transition-property: border-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.light_mode .PageFooter {
  border-color: #f0f3f5;
}
.light_mode .PageHeader {
  border-color: #f0f3f5;
}
.dark_mode .PageFooter {
  border-color: #1a2229;
}
.dark_mode .PageHeader {
  border-color: #1a2229;
}
.avatar-presence {
  display: inline-block !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  font-size: 6px !important;
  line-height: 6px !important;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
  transition-property: background, box-shadow, color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.avatar-wrap {
  display: inline-block !important;
  position: relative;
}
.avatar-wrap .avatar {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: 50%;
  transition-property: background, border-color, color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.avatar-wrap.avatar-default .avatar {
  border: none;
}
.avatar-wrap.active .avatar {
  border: 2px solid #3a82ff;
}
.avatar-wrap .avatar-presence {
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transform: translateX(30%) translateY(30%);
  -moz-transform: translateX(30%) translateY(30%);
  -o-transform: translateX(30%) translateY(30%);
  transform: translateX(30%) translateY(30%);
}
.avatar-wrap.avatar-xxlarge,
.avatar-wrap.avatar-xxlarge .avatar {
  height: 84px;
  max-height: 84px;
  min-height: 84px;
  width: 84px;
  max-width: 84px;
  min-width: 84px;
}
.avatar-wrap.avatar-xxlarge .avatar {
  border-width: 4px;
}
.avatar-wrap.avatar-xxlarge .avatar > .fa {
  margin-top: 16px;
  font-size: 66px !important;
}
.avatar-wrap.avatar-xxlarge .avatar-presence {
  font-size: 12.6px !important;
  line-height: 12.6px !important;
  -webkit-border-radius: 12.6px;
  -moz-border-radius: 12.6px;
  -o-border-radius: 12.6px;
  border-radius: 12.6px;
}
.avatar-wrap.avatar-xlarge,
.avatar-wrap.avatar-xlarge .avatar {
  height: 52.5px;
  max-height: 52.5px;
  min-height: 52.5px;
  width: 52.5px;
  max-width: 52.5px;
  min-width: 52.5px;
}
.avatar-wrap.avatar-xlarge .avatar {
  border-width: 2.5px;
}
.avatar-wrap.avatar-xlarge .avatar > .fa {
  margin-top: 10px;
  font-size: 41.25px !important;
}
.avatar-wrap.avatar-xlarge .avatar-presence {
  font-size: 7.875px !important;
  line-height: 7.875px !important;
  -webkit-border-radius: 7.875px;
  -moz-border-radius: 7.875px;
  -o-border-radius: 7.875px;
  border-radius: 7.875px;
}
.avatar-wrap,
.avatar-wrap.avatar-large,
.avatar-wrap .avatar,
.avatar-wrap.avatar-large .avatar {
  height: 42px;
  max-height: 42px;
  min-height: 42px;
  width: 42px;
  max-width: 42px;
  min-width: 42px;
}
.avatar-wrap .avatar,
.avatar-wrap.avatar-large .avatar {
  border-width: 2px;
}
.avatar-wrap .avatar > .fa,
.avatar-wrap.avatar-large .avatar > .fa {
  margin-top: 8px;
  font-size: 33px !important;
}
.avatar-wrap .avatar-presence,
.avatar-wrap.avatar-large .avatar-presence {
  font-size: 6.3px !important;
  line-height: 6.3px !important;
  -webkit-border-radius: 6.3px;
  -moz-border-radius: 6.3px;
  -o-border-radius: 6.3px;
  border-radius: 6.3px;
}
.avatar-wrap.avatar-medium,
.avatar-wrap.avatar-medium .avatar {
  height: 34.44px;
  max-height: 34.44px;
  min-height: 34.44px;
  width: 34.44px;
  max-width: 34.44px;
  min-width: 34.44px;
}
.avatar-wrap.avatar-medium .avatar {
  border-width: 1.64px;
}
.avatar-wrap.avatar-medium .avatar > .fa {
  margin-top: 6.56px;
  font-size: 27.06px !important;
}
.avatar-wrap.avatar-medium .avatar-presence {
  font-size: 5.166px !important;
  line-height: 5.166px !important;
  -webkit-border-radius: 5.166px;
  -moz-border-radius: 5.166px;
  -o-border-radius: 5.166px;
  border-radius: 5.166px;
}
.avatar-wrap.avatar-small,
.avatar-wrap.avatar-small .avatar {
  height: 26.88px;
  max-height: 26.88px;
  min-height: 26.88px;
  width: 26.88px;
  max-width: 26.88px;
  min-width: 26.88px;
}
.avatar-wrap.avatar-small .avatar {
  border-width: 1.28px;
}
.avatar-wrap.avatar-small .avatar > .fa {
  margin-top: 5.12px;
  font-size: 21.12px !important;
}
.avatar-wrap.avatar-small .avatar-presence {
  font-size: 4.032px !important;
  line-height: 4.032px !important;
  -webkit-border-radius: 4.032px;
  -moz-border-radius: 4.032px;
  -o-border-radius: 4.032px;
  border-radius: 4.032px;
}
.avatar-wrap.avatar-xsmall,
.avatar-wrap.avatar-xsmall .avatar {
  height: 18.9px;
  max-height: 18.9px;
  min-height: 18.9px;
  width: 18.9px;
  max-width: 18.9px;
  min-width: 18.9px;
}
.avatar-wrap.avatar-xsmall .avatar {
  border-width: 0.9px;
}
.avatar-wrap.avatar-xsmall .avatar > .fa {
  margin-top: 3.6px;
  font-size: 14.85px !important;
}
.avatar-wrap.avatar-xsmall .avatar-presence {
  font-size: 2.835px !important;
  line-height: 2.835px !important;
  -webkit-border-radius: 2.835px;
  -moz-border-radius: 2.835px;
  -o-border-radius: 2.835px;
  border-radius: 2.835px;
}
.light_mode .avatar-presence {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0 0 0 2px #FFFFFF;
  -moz-box-shadow: 0 0 0 2px #FFFFFF;
  -o-box-shadow: 0 0 0 2px #FFFFFF;
  box-shadow: 0 0 0 2px #FFFFFF;
}
.light_mode .avatar-wrap .avatar {
  color: #FFFFFF !important;
  background-color: #c4cfda;
  border-color: #c4cfda;
}
.dark_mode .avatar-presence {
  background-color: #141a20;
  -webkit-box-shadow: 0 0 0 2px #141a20;
  -moz-box-shadow: 0 0 0 2px #141a20;
  -o-box-shadow: 0 0 0 2px #141a20;
  box-shadow: 0 0 0 2px #141a20;
}
.dark_mode .avatar-wrap .avatar {
  color: #536c84 !important;
  background-color: #222c36;
  border-color: #000000;
}
.list-group {
  background: none;
  border: none;
  margin: 0 -0.75rem;
}
.list-group .list-group-item {
  background: none;
  border: none;
  outline: none;
  padding: 0.2rem 0.75rem;
  text-align: left;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 400;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  margin: 0 !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  transition-property: background, color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
  min-height: 45px;
}
.list-group .list-group-item .list-group-item-before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: transform 0.15s ease-in-out;
  -moz-transition: transform 0.15s ease-in-out;
  -o-transition: transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out;
}
.list-group .list-group-item:active .list-group-item-before {
  -webkit-transform: scale(0.93);
  -moz-transform: scale(0.93);
  -o-transform: scale(0.93);
  transform: scale(0.93);
}
.dropdown-menu {
  transition-property: background, border-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.dropdown-menu .dropdown-item {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  font-size: 85%;
  font-weight: 700;
  background: none;
  border: none;
  outline: none;
  padding: 0.2rem 0.75rem;
  text-align: left;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 400;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  margin: 0 !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  transition-property: background, color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.dropdown-menu .dropdown-item .list-group-item-before {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transition: transform 0.15s ease-in-out;
  -moz-transition: transform 0.15s ease-in-out;
  -o-transition: transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out;
}
.dropdown-menu .dropdown-item:active .list-group-item-before {
  -webkit-transform: scale(0.93);
  -moz-transform: scale(0.93);
  -o-transform: scale(0.93);
  transform: scale(0.93);
}
.cozy_mode .list-group .list-group-item {
  min-height: auto;
}
.light_mode .list-group .list-group-item {
  color: #222c36;
}
.light_mode .list-group .list-group-item:hover,
.light_mode .list-group .list-group-item:focus {
  color: #222c36;
  background: #e6ebf0;
}
.light_mode .list-group .list-group-item:not(.clean):active,
.light_mode .list-group .list-group-item:not(.clean).active {
  color: #3a82ff;
  background: rgba(58, 130, 255, 0.1);
}
.light_mode .dropdown-menu {
  background: #FFFFFF;
  border-color: #dde3ea;
}
.light_mode .dropdown-menu .dropdown-item {
  color: #222c36;
}
.light_mode .dropdown-menu .dropdown-item:hover,
.light_mode .dropdown-menu .dropdown-item:focus {
  color: #222c36;
  background: #e6ebf0;
}
.light_mode .dropdown-menu .dropdown-item:not(.clean):active,
.light_mode .dropdown-menu .dropdown-item:not(.clean).active {
  color: #3a82ff;
  background: rgba(58, 130, 255, 0.1);
}
.dark_mode .list-group .list-group-item {
  color: #98acbe;
}
.dark_mode .list-group .list-group-item:hover,
.dark_mode .list-group .list-group-item:focus {
  color: #98acbe;
  background: rgba(0, 0, 0, 0.15);
}
.dark_mode .list-group .list-group-item:not(.clean):active,
.dark_mode .list-group .list-group-item:not(.clean).active {
  color: #3a82ff;
  background: rgba(58, 130, 255, 0.1);
}
.dark_mode .dropdown-menu {
  background: #10151a;
  border-color: #0c1013;
}
.dark_mode .dropdown-menu .dropdown-item {
  color: #98acbe;
}
.dark_mode .dropdown-menu .dropdown-item:hover,
.dark_mode .dropdown-menu .dropdown-item:focus {
  color: #98acbe;
  background: rgba(0, 0, 0, 0.15);
}
.dark_mode .dropdown-menu .dropdown-item:not(.clean):active,
.dark_mode .dropdown-menu .dropdown-item:not(.clean).active {
  color: #3a82ff;
  background: rgba(58, 130, 255, 0.1);
}
.Collapsible {
  margin: 0 -0.75rem;
}
.Collapsible .Collapsible__trigger {
  padding: 0 0.75rem;
  display: block;
}
.Collapsible .Collapsible__contentOuter {
  padding: 0 0.75rem;
}
.form-control {
  border: 2px solid;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  transition-property: background, border-color, color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.form-control:focus {
  border: 2px solid;
}
textarea {
  resize: none;
  overflow: hidden;
}
.light_mode .form-control {
  background: #dde3ea;
  color: #141a20;
  border-color: transparent;
}
.light_mode .form-control:focus {
  background: #FFFFFF;
  color: #141a20;
  border-color: #3a82ff;
}
.dark_mode .form-control {
  background: rgba(240, 243, 245, 0.08);
  color: #dde3ea;
  border-color: transparent;
}
.dark_mode .form-control:focus {
  background: #2e3b49;
  color: #dde3ea;
  border-color: #3a82ff;
}
.Conversation {
  margin-left: -1rem;
  margin-right: -1rem;
}
.Conversation .Conversation__Thread .Conversation__Message {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  margin-bottom: 0.25rem !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  transition-property: background;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.Conversation .Conversation__Thread .Conversation__Message .Conversation__Message__HeaderWrap {
  margin-bottom: 0.25rem !important;
}
.Conversation .Conversation__Thread .Conversation__Message .Conversation__Message__HeaderWrap .Conversation__Message__Time {
  -webkit-opacity: 0.3;
  -moz-opacity: 0.3;
  -o-opacity: 0.3;
  opacity: 0.3;
}
.Conversation .Conversation__Thread .Conversation__Message .Conversation__Message__BodyWrap {
  margin-bottom: 0.5rem !important;
}
.Conversation .Conversation__Thread .Conversation__Message .Conversation__Message__BodyWrap .Conversation__Message__Body * {
  font-size: 14px;
}
.Conversation .Conversation__Thread .Conversation__Message .Conversation__Message__BodyWrap .Conversation__Message__Body p {
  margin-bottom: 0.25rem !important;
}
.Conversation .Conversation__Thread .Conversation__Message .Conversation__Message__BodyWrap .Conversation__Message__Body a {
  cursor: pointer;
  font-weight: 500;
}
.Conversation .Conversation__Thread .Conversation__Message .Conversation__Message__Controls {
  -webkit-opacity: 0;
  -moz-opacity: 0;
  -o-opacity: 0;
  opacity: 0;
  -webkit-transition: opacity 0.15s ease-in-out;
  -moz-transition: opacity 0.15s ease-in-out;
  -o-transition: opacity 0.15s ease-in-out;
  transition: opacity 0.15s ease-in-out;
}
.Conversation .Conversation__Thread .Conversation__Message .Conversation__Message__Controls button + button {
  margin-left: 0.25rem !important;
}
.Conversation .Conversation__Thread .Conversation__Message:hover .Conversation__Message__Controls,
.Conversation .Conversation__Thread .Conversation__Message:focus .Conversation__Message__Controls {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  -o-opacity: 1;
  opacity: 1;
}
.Conversation .Conversation__Thread .Conversation__Thread__DayDivider {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}
.Conversation .Conversation__Thread .Conversation__Thread__DayDivider hr {
  transition-property: border-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.Conversation .Conversation__Thread .Conversation__Thread__DayDivider .badge {
  transition-property: background, color, border-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
  font-weight: 500;
  border: 1px solid transparent;
}
.cozy_mode .Conversation .Conversation__Thread .Conversation__Message {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.cozy_mode .Conversation .Conversation__Thread .Conversation__Message .Conversation__Message__HeaderWrap {
  margin-bottom: 0 !important;
}
.cozy_mode .Conversation .Conversation__Thread .Conversation__Message .Conversation__Message__BodyWrap {
  margin-bottom: 0 !important;
}
.light_mode .Conversation .Conversation__Thread .Conversation__Message .Conversation__Message__Title {
  color: #141a20;
}
.light_mode .Conversation .Conversation__Thread .Conversation__Thread__DayDivider hr {
  border-color: #dde3ea;
}
.light_mode .Conversation .Conversation__Thread .Conversation__Thread__DayDivider .badge {
  background: rgba(58, 130, 255, 0.1);
  border-color: rgba(58, 130, 255, 0.1);
  color: #3a82ff;
}
.dark_mode .Conversation .Conversation__Thread .Conversation__Message .Conversation__Message__Title {
  color: #536c84;
}
.dark_mode .Conversation .Conversation__Thread .Conversation__Thread__DayDivider hr {
  border-color: #1a2229;
}
.dark_mode .Conversation .Conversation__Thread .Conversation__Thread__DayDivider .badge {
  background: rgba(58, 130, 255, 0.1);
  border-color: rgba(58, 130, 255, 0.1);
  color: #639cff;
}
.Unfurl {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  border-left: 3px solid;
  width: 100%;
  max-width: 550px;
  margin-top: 0.5rem !important;
  padding: 1rem !important;
  transition-property: background, border-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.Unfurl * {
  font-size: 12px;
}
.Unfurl .Unfurl__YouTubeWrap,
.Unfurl .Unfurl__LinkPreviewImg,
.Unfurl .Unfurl__FileIcon {
  transition-property: background, border-color, color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.Unfurl .Unfurl__LinkPreviewImg {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid;
}
.Unfurl .Unfurl__FileIcon {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid;
}
.Unfurl .Unfurl__FileIcon,
.Unfurl .Unfurl__FileIcon * {
  font-size: 26px;
}
.Unfurl .Unfurl__YouTubeWrap {
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  overflow: hidden;
}
.Unfurl .Unfurl__YouTubeWrap:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 56.25%;
}
.Unfurl .Unfurl__YouTubeWrap > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.cozy_mode .Unfurl {
  max-width: 450px;
  margin-top: 0.25rem !important;
  padding: 0.5rem !important;
}
.light_mode .Unfurl {
  background: #f0f3f5;
  border-color: #e0e6ec;
}
.light_mode .Unfurl .Unfurl__YouTubeWrap,
.light_mode .Unfurl .Unfurl__LinkPreviewImg {
  background: #e0e6ec;
  border-color: #d1d9e2;
}
.light_mode .Unfurl .Unfurl__FileIcon {
  color: #3a82ff;
  background: rgba(58, 130, 255, 0.1);
  border-color: rgba(58, 130, 255, 0.15);
}
.dark_mode .Unfurl {
  background: #1e272f;
  border-color: #2c3945;
}
.dark_mode .Unfurl .Unfurl__YouTubeWrap,
.dark_mode .Unfurl .Unfurl__LinkPreviewImg {
  background: #2c3945;
  border-color: #374858;
}
.dark_mode .Unfurl .Unfurl__FileIcon {
  color: #3a82ff;
  background: rgba(58, 130, 255, 0.1);
  border-color: rgba(58, 130, 255, 0.15);
}
.scrollbar-container .ps__rail-x {
  display: none;
}
.scrollbar-container .ps__rail-y {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
.scrollbar-container .ps__rail-y .ps__thumb-y {
  width: 4px;
  transition-property: background, width;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.scrollbar-container .ps__rail-y:hover {
  width: 14px;
}
.scrollbar-container .ps__rail-y:hover .ps__thumb-y {
  width: 10px;
}
.light_mode .scrollbar-container .ps__rail-y {
  background: #f0f3f5;
}
.light_mode .scrollbar-container .ps__rail-y:hover {
  background: #e6ebf0;
}
.light_mode .scrollbar-container .ps__rail-y .ps__thumb-y {
  background: #98acbe;
}
.dark_mode .scrollbar-container .ps__rail-y {
  background: rgba(0, 0, 0, 0);
}
.dark_mode .scrollbar-container .ps__rail-y:hover {
  background: rgba(0, 0, 0, 0.15);
}
.dark_mode .scrollbar-container .ps__rail-y .ps__thumb-y {
  background: #000000;
}
.ChatList .ChatListHeader {
  border-bottom: 1px solid;
  transition-property: background, border-color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.light_mode .ChatList .ChatListHeader {
  background: #e3e9ee;
  border-color: #e3e9ee;
}
.dark_mode .ChatList .ChatListHeader {
  background: #192028;
  border-color: #192028;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: #141a20;
  transition-property: color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
a,
a:not([href]):not([tabindex]) {
  color: #3a82ff;
  transition-property: color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
a:hover,
a:not([href]):not([tabindex]):hover,
a:focus,
a:not([href]):not([tabindex]):focus {
  color: #6da2ff;
}
.light_mode body,
.light_mode h1,
.light_mode h2,
.light_mode h3,
.light_mode h4,
.light_mode h5,
.light_mode h6,
.light_mode p {
  color: #141a20;
}
.dark_mode body,
.dark_mode h1,
.dark_mode h2,
.dark_mode h3,
.dark_mode h4,
.dark_mode h5,
.dark_mode h6,
.dark_mode p {
  color: #dde3ea;
}
.ComposerWrap .Composer {
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  border: 2px solid;
  transition-property: background, border-color, color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.ComposerWrap .Composer .ComposerIconAttachment {
  padding: 9px;
  cursor: pointer;
}
.ComposerWrap .Composer .ComposerIconAttachment,
.ComposerWrap .Composer .ComposerIconAttachment .fa-stack-2x,
.ComposerWrap .Composer .ComposerIconAttachment .fa-stack-1x {
  transition-property: color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.ComposerWrap .Composer .form-control {
  background: none !important;
  border: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
  padding-left: 0;
  padding-right: 0;
}
.ComposerWrap .ComposerComposingText {
  height: 2rem;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  font-size: 80%;
  font-weight: 400;
  font-size: 70%;
  transition-property: color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.light_mode .ComposerWrap .Composer {
  background: #dde3ea;
  border-color: transparent;
}
.light_mode .ComposerWrap .Composer .ComposerIconAttachment,
.light_mode .ComposerWrap .Composer .ComposerIconAttachment .fa-stack-2x {
  color: #98acbe;
}
.light_mode .ComposerWrap .Composer .ComposerIconAttachment .fa-stack-1x {
  color: #dde3ea;
}
.light_mode .ComposerWrap .Composer .ComposerIconAttachment:hover,
.light_mode .ComposerWrap .Composer .ComposerIconAttachment:hover .fa-stack-2x {
  color: #3a82ff !important;
}
.light_mode .ComposerWrap .Composer .ComposerIconAttachment:hover .fa-stack-1x {
  color: #FFFFFF !important;
}
.light_mode .ComposerWrap .Composer:focus-within {
  background: #FFFFFF;
  border-color: #3a82ff;
}
.light_mode .ComposerWrap .Composer:focus-within .ComposerIconAttachment,
.light_mode .ComposerWrap .Composer:focus-within .ComposerIconAttachment .fa-stack-2x {
  color: #b7c5d2;
}
.light_mode .ComposerWrap .Composer:focus-within .ComposerIconAttachment .fa-stack-1x {
  color: #FFFFFF;
}
.light_mode .ComposerWrap .ComposerComposingText {
  color: #536c84;
}
.dark_mode .ComposerWrap .Composer {
  background: rgba(240, 243, 245, 0.08);
  border-color: transparent;
}
.dark_mode .ComposerWrap .Composer .ComposerIconAttachment,
.dark_mode .ComposerWrap .Composer .ComposerIconAttachment .fa-stack-2x {
  color: #536c84;
}
.dark_mode .ComposerWrap .Composer .ComposerIconAttachment .fa-stack-1x {
  color: #28343f;
}
.dark_mode .ComposerWrap .Composer .ComposerIconAttachment:hover,
.dark_mode .ComposerWrap .Composer .ComposerIconAttachment:hover .fa-stack-2x {
  color: #3a82ff !important;
}
.dark_mode .ComposerWrap .Composer .ComposerIconAttachment:hover .fa-stack-1x {
  color: #FFFFFF !important;
}
.dark_mode .ComposerWrap .Composer:focus-within {
  background: #2e3b49;
  border-color: #3a82ff;
}
.dark_mode .ComposerWrap .Composer:focus-within .ComposerIconAttachment,
.dark_mode .ComposerWrap .Composer:focus-within .ComposerIconAttachment .fa-stack-2x {
  color: #536c84;
}
.dark_mode .ComposerWrap .Composer:focus-within .ComposerIconAttachment .fa-stack-1x {
  color: #28343f;
}
.dark_mode .ComposerWrap .ComposerComposingText {
  color: #98acbe;
}
.SwitchToggleWrap label {
  margin: 0 !important;
}
.SwitchToggleWrap .SwitchToggle {
  display: none;
}
.SwitchToggleWrap .SwitchToggle + .SwitchToggle__btn {
  outline: 0;
  display: block;
  width: 2.4em;
  height: 1.33333333em;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding: 2px;
  transition-property: background;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
  -webkit-border-radius: 2.4em;
  -moz-border-radius: 2.4em;
  -o-border-radius: 2.4em;
  border-radius: 2.4em;
}
.SwitchToggleWrap .SwitchToggle + .SwitchToggle__btn:after,
.SwitchToggleWrap .SwitchToggle + .SwitchToggle__btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.SwitchToggleWrap .SwitchToggle + .SwitchToggle__btn:after {
  border-radius: 50%;
  left: 0;
  transition-property: background, left;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.SwitchToggleWrap .SwitchToggle + .SwitchToggle__btn:before {
  display: none;
}
.SwitchToggleWrap .SwitchToggle:checked + .SwitchToggle__btn:after {
  left: 50%;
}
.light_mode .SwitchToggleWrap .SwitchToggle + .SwitchToggle__btn {
  background: rgba(83, 108, 132, 0.2);
}
.light_mode .SwitchToggleWrap .SwitchToggle + .SwitchToggle__btn:after {
  background: #FFFFFF;
}
.light_mode .SwitchToggleWrap .SwitchToggle:checked + .SwitchToggle__btn {
  background: #3a82ff;
}
.dark_mode .SwitchToggleWrap .SwitchToggle + .SwitchToggle__btn {
  background: rgba(83, 108, 132, 0.3);
}
.dark_mode .SwitchToggleWrap .SwitchToggle + .SwitchToggle__btn:after {
  background: #FFFFFF;
}
.dark_mode .SwitchToggleWrap .SwitchToggle:checked + .SwitchToggle__btn {
  background: #3a82ff;
}
.LeftNav .LeftNavIcon,
.LeftNav .LeftNavLogo {
  transition-property: color;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.LeftNav .LeftNavSettingsDropDownMenu .dropdown-menu {
  bottom: 0 !important;
  top: initial !important;
  -webkit-transform: translate3d(65px, -15px, 0px) !important;
  -moz-transform: translate3d(65px, -15px, 0px) !important;
  -o-transform: translate3d(65px, -15px, 0px) !important;
  transform: translate3d(65px, -15px, 0px) !important;
}
.light_mode .LeftNav .LeftNavIcon {
  color: #FFFFFF;
}
.light_mode .LeftNav .LeftNavLogo {
  color: rgba(255, 255, 255, 0.4);
}
.dark_mode .LeftNav .LeftNavIcon {
  color: #98acbe;
}
.dark_mode .LeftNav .LeftNavLogo {
  color: #3a82ff;
}
.cozy_mode_d-flex,
.cozy_mode_d-inline-flex,
.cozy_mode_d-block,
.cozy_mode_d-inline-block,
.cozy_mode_d-inline {
  display: none !important;
}
.cozy_mode .cozy_mode_hide {
  display: none !important;
}
.cozy_mode .cozy_mode_d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}
.cozy_mode .cozy_mode_d-inline-flex {
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
.cozy_mode .cozy_mode_d-block {
  display: block !important;
}
.cozy_mode .cozy_mode_d-inline-block {
  display: inline-block !important;
}
.cozy_mode .cozy_mode_d-inline {
  display: inline !important;
}
.Wrapper {
  background: #FFFFFF;
  left: 0;
  top: 0;
  transition-property: background;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.light_mode .Wrapper {
  background: #FFFFFF;
}
.dark_mode .Wrapper {
  background: #222c36;
}
.GlobalNav {
  background: #3a82ff;
  transition-property: background;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.light_mode .GlobalNav {
  background: #3a82ff;
}
.dark_mode .GlobalNav {
  background: #10151a;
}
.PageNav {
  background: #f0f3f5;
  transition-property: background;
  transition-duration: 0.15s;
  transition-timing-function: ease-in-out;
}
.light_mode .PageNav {
  background: #f0f3f5;
}
.dark_mode .PageNav {
  background: #1e272f;
}
